import { CSSResultArray, TemplateResult, customElement, html, property, query, css, unsafeCSS } from 'lit-element';
import { hostStyles } from '../../../host.styles';
import { BaseElement } from '../../base/BaseElement';
import { IconBaseElement } from '@zeiss/zui-icons';
import { event } from '../../../decorators/event.decorator';
import { propagateValueToSlotContentByPredicate } from '../../../utils/component.utils';

import style from './menubar-item.scss';
const menuBarItemStyles = css`
  ${unsafeCSS(style)}
`;

type Variant = 'primary' | 'secondary';
type Emphasis = 'default' | 'active' | 'active-primary';
// this is kept for being compatible with old API design
type MenuItemActionEvent = MouseEvent & { value: string };

/**
 * The menu bar item, is an item of the menu bar which holds one icon and can be clicked. It dispatches in this case an
 * custom event with its own value as event value.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-menubar-item value="1">
 * 		<zui-icon-holy-placeholder></zui-icon-holy-placeholder>
 * </zui-menubar-item>
 * ```
 *
 * @fires {CustomEvent} menubar-item-selected - The CustomEvent which gets dispatched when the menu bar item gets clicked. It has the value of the menu bar item as value in its detail.
 * @fires {CustomEvent} menubarItemSelected - (Deprecated) The CustomEvent which gets dispatched when the menu bar item gets clicked. It has the value of the menu bar item as value in its detail.
 *
 * @slot default - This is the default slot and can be populated with any ZUi icon
 */
@customElement('zui-menubar-item')
export class MenubarItem extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, menuBarItemStyles];
  }

  // this is kept for being compatible with old API design
  /**
   * @deprecated
   * @private
   */
  menuItemClickEvent: MenuItemActionEvent;

  /**
   * Sets the Color variant of the Menu Item
   */
  @property({ reflect: true, type: String })
  variant: Variant = 'primary';

  /**
   * Value of the Menu Item
   */
  @property({ reflect: true, type: String })
  value: string;

  /**
   * Disabled State of the Menu Item
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * Sets the emphasis of the Menu Item.
   */
  @property({ reflect: true, type: String })
  emphasis: Emphasis = 'default';

  /**
   * @param detail detail
   * @param detail.value value
   *
   * @private
   */
  @event({
    eventName: 'menubar-item-selected',
  })
  emitMenubarItemSelectedEvent(detail: { value: string }): void {
    // TODO: remove in version 2.0
    this.dispatchEvent(
      new CustomEvent('menubarItemSelected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );

    this.dispatchEvent(
      new CustomEvent('menubar-item-selected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  @query('slot:not([name])')
  private _defaultSlot: HTMLSlotElement;

  private _handleClick(clickEvent): void {
    // do not emit if disabled
    if (this.disabled) {
      clickEvent.preventDefault();
      clickEvent.stopPropagation();
      clickEvent.stopImmediatePropagation();
      return;
    }

    this.emitMenubarItemSelectedEvent({ value: this.value });

    // this is kept for being compatible with old API design
    clickEvent.value = this.value;
  }

  // todo: this should be checked as it is not working
  private _handleKeypress(keyEvent): void {
    // old IE/Edge don't support `event.code` and instead `event.key` has to be used.
    // see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
    if (keyEvent.code === undefined) {
      // some old IE and Firefox are using `Spacebar` instead of `" "` when space is pressed.
      if (keyEvent.key === 'Spacebar' || keyEvent.key === ' ' || keyEvent.key === 'Enter') {
        this.click();
      }
    } else if (keyEvent.code === 'Space' || keyEvent.code === 'Enter') {
      this.click();
    }
  }

  private _onDefaultSlotChanged(): void {
    // set size of icon in the default slot to 'm'.
    propagateValueToSlotContentByPredicate(
      this._defaultSlot,
      (slotElement) => slotElement instanceof IconBaseElement,
      'size',
      'm'
    );
  }

  protected render(): TemplateResult | void {
    return html`<div id="menubar-item" role="button" @click="${this._handleClick}" @keypress="${this._handleKeypress}">
      <slot @slotchange="${this._onDefaultSlotChanged}"></slot>
    </div>`;
  }
}

// this is an alias for the new renamed <zui-menubar-item>; it will be removed
// in the next major release and has to be in this file, due to side-effect of customElement registering
// FIXME: remove in next major release
// eslint-disable-next-line jsdoc/require-example
/**
 * **Deprecated**. This component is equivalent to `zui-menubar-item` but with a different name.
 * This is here for compatibility reasons but shouldn't be used anymore.
 * Instead use `zui-menubar-item`.
 *
 * This component will be removed in the future.
 *
 * @deprecated
 * @private
 */
@customElement('zui-menu-bar-item')
export class MenuBarItem extends MenubarItem {
  connectedCallback(): void {
    super.connectedCallback();
    console.warn(
      'The usage of <zui-menu-bar-item> has been deprecated and it will be removed in the next major release! It has been renamed to <zui-menubar-item>'
    );
  }
}
