import { CSSResultArray, TemplateResult, customElement, html, css, unsafeCSS, property } from 'lit-element';
import { query } from 'lit-element/lib/decorators';
import { event } from '../../decorators/event.decorator';
import { RealBaseElement } from '../base/BaseElement';
import { FormDataHandlingMixin } from '../../mixins/form-participation/form-data-handling.mixin';
import { FormEnabledElement } from '../../mixins/form-participation/form-participation.types';
import { booleanStringConverter } from '../../utils/component.utils';
import { hostStyles } from '../../host.styles';

import style from './toggle-switch.component.scss';

const toggleSwitchStyles = css`
  ${unsafeCSS(style)}
`;

type Emphasis = 'default' | 'primary';
type Size = 's' | 'l';

/**
 * Toggle switch.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13558%3A188260)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=23651%3A480653)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-toggle-switch name="demoToggle" value="true"></zui-toggle-switch>
 * ```
 *
 * HTML (translucent toggle switch):
 *
 * ```html
 * <zui-toggle-switch name="demoToggle" theme="translucent"></zui-toggle-switch>
 * ```
 *
 * @fires {Event} blur - emits when the toggle switch has lost it's focus
 * @fires {Event} change - emits when the toggle switch value has changed
 * @fires {Event} input - emits when the toggle switch value has changed
 * @cssprop --zui-toggle-switch-background-color - sets the background color of the switch, depends on emphasis attribute and interactino state
 * @cssprop --zui-toggle-switch-border-color - color of the switch border, depends on emphasis attribute and interaction state
 * @cssprop --zui-toggle-switch-handle-size - dimensions of the switch handle, depends on the size attribute
 * @cssprop --zui-toggle-switch-handle-spacing - outer spacing of the switch handle
 * @cssprop --zui-toggle-switch-height - sets the vertical size of the handle
 * @cssprop --zui-toggle-switch-width - sets the horizontal size of the handle
 */
@customElement('zui-toggle-switch')
export class ToggleSwitch extends FormDataHandlingMixin(RealBaseElement) implements FormEnabledElement {
  static get styles(): CSSResultArray {
    return [hostStyles, toggleSwitchStyles];
  }

  /**
   * emphasis
   */
  @property({ reflect: true, type: String })
  emphasis: Emphasis = 'default';

  /**
   * toggle switch size
   */
  @property({ reflect: true, type: String })
  size: Size = 's';

  /**
   * whether the toggle switch is on or off
   */
  @property({ reflect: true, type: Boolean, converter: booleanStringConverter })
  value = false;

  /**
   * Emits a blur event
   *
   * @private
   */
  @event({
    eventName: 'blur',
    bubbles: false,
    cancelable: false,
    composed: true,
  })
  emitBlurEvent(): void {
    this.dispatchEvent(
      new Event('blur', {
        bubbles: false,
        cancelable: false,
        composed: true,
      })
    );
  }

  /**
   * Emits a change event
   *
   * @private
   */
  @event({
    eventName: 'change',
    bubbles: true,
    cancelable: false,
    composed: true,
  })
  emitChangeEvent(): void {
    this.dispatchEvent(
      new Event('change', {
        bubbles: true,
        cancelable: false,
        composed: true,
      })
    );
  }

  /**
   * Emits an input event
   *
   * @private
   */
  @event({
    eventName: 'input',
    bubbles: true,
    cancelable: false,
    composed: true,
  })
  emitInputEvent(): void {
    this.dispatchEvent(
      new Event('input', {
        bubbles: true,
        cancelable: false,
        composed: true,
      })
    );
  }

  @query('button')
  private _buttonRef: HTMLButtonElement | null;

  connectedCallback(): void {
    super.connectedCallback();
    // TODO: use focus-delegation
    this.addEventListener('focus', this._delegateFocus);
  }

  disconnectedCallback(): void {
    // TODO: use focus-delegation
    this.removeEventListener('focus', this._delegateFocus);
    super.disconnectedCallback();
  }

  private _delegateFocus(): void {
    this._buttonRef?.focus();
  }

  private _handleBlur = (): void => {
    this.emitBlurEvent();
  };

  private _handleClick = (): void => {
    this.value = !this.value;

    this.emitChangeEvent();
    this.emitInputEvent();
  };

  private _handleKeyDown = (event: KeyboardEvent): void => {
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        break;
    }
  };

  protected render(): TemplateResult {
    return html`
      <button
        zuiFormControl
        ?disabled="${this.disabled}"
        aria-checked="${this.value}"
        aria-disabled="${this.disabled}"
        role="switch"
        type="button"
        @blur="${this._handleBlur}"
        @click="${this._handleClick}"
        @keydown="${this._handleKeyDown}"
      >
      </button>
    `;
  }
}
