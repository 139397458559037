import { css, CSSResult, customElement, html, property, TemplateResult, unsafeCSS } from 'lit-element';
import type { EventWithTarget } from '../../../types';
import { BaseElement } from '../../base/BaseElement';
import styles from './picker-grid.component.scss';
import { getNextPickerGridCell, getPreviousPickerGridCell } from '../utils/picker.utils';
import { event } from '../../../decorators/event.decorator';
import { GridCell } from '../grid-cell/grid-cell.component';
import { HeaderCell } from '../header-cell/header-cell.component';

const pickerGridStyles = css`
  ${unsafeCSS(styles)}
`;

enum ArrowKeys {
  ArrowUp = 'ArrowUp',
  ArrowRight = 'ArrowRight',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
}

/**
 * Picker grid with a header of picker grid header cells and a grid of picker grid cells
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-picker-grid columns="4">
 *   <zui-picker-header-cell slot="pickerGridHeaderCells" text="1"></zui-picker-header-cell>
 *   <zui-picker-header-cell slot="pickerGridHeaderCells" text="2"></zui-picker-header-cell>
 *   <zui-picker-header-cell slot="pickerGridHeaderCells" text="3"></zui-picker-header-cell>
 *   <zui-picker-header-cell slot="pickerGridHeaderCells" text="4"></zui-picker-header-cell>
 *   <zui-picker-grid-cell slot="pickerGridCells" emphasis="subtle" value="1">1</zui-picker-grid-cell>
 *   <zui-picker-grid-cell slot="pickerGridCells" emphasis="selected" value="2">2</zui-picker-grid-cell>
 *   <zui-picker-grid-cell slot="pickerGridCells" disabled emphasis="default" value="3">3</zui-picker-grid-cell>
 *   <zui-picker-grid-cell slot="pickerGridCells" emphasis="default" value="4">4</zui-picker-grid-cell>
 * </zui-picker-grid>
 * ```
 *
 * @slot pickerGridHeaderCells - slot for picker grid header cells
 * @slot pickerGridCells - slot for picker grid cells
 *
 * @fires {CustomEvent} picker-grid-cell-selected - will be dispatched, if a `<zui-picker-grid-cell>` is selected
 * @fires {CustomEvent} pickerGridCellSelected - (Deprecated) will be dispatched, if a `<zui-picker-grid-cell>` is selected
 * @fires {CustomEvent} picker-header-cell-selected - will be dispatched, if a `<zui-picker-header-cell>` is selected
 * @fires {CustomEvent} pickerHeaderCellSelected - (Deprecated) will be dispatched, if a `<zui-picker-header-cell>` is selected
 * @cssprop --zui-picker-grid-cell-height - sets the cell height explicitly
 * @cssprop --zui-picker-grid-cell-width - sets the cell width explicitly
 */
@customElement('zui-picker-grid')
export class PickerGrid extends BaseElement {
  static get styles(): CSSResult[] {
    return [pickerGridStyles];
  }

  /**
   * column count of the picker grid
   */
  @property({ reflect: true, type: Number })
  columns = 7;

  /**
   * @param detail detail
   * @param detail.value value
   * @param detail.selected selected
   *
   * @private
   */
  @event({
    eventName: 'picker-grid-cell-selected',
  })
  emitPickerGridCellSelectedEvent(detail: { value: string; selected: GridCell }): void {
    // TODO: remove in version 2.0
    this.dispatchEvent(
      new CustomEvent('pickerGridCellSelected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );

    this.dispatchEvent(
      new CustomEvent('picker-grid-cell-selected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  /**
   * @param detail detail
   * @param detail.text text
   * @param detail.value value
   * @param detail.selected selected
   *
   * @private
   */
  @event({
    eventName: 'picker-header-cell-selected',
  })
  emitPickerHeaderCellSelectedEvent(detail: { text: string; value: string; selected: HeaderCell }): void {
    // TODO: remove in version 2.0
    this.dispatchEvent(
      new CustomEvent('pickerHeaderCellSelected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );

    this.dispatchEvent(
      new CustomEvent('picker-header-cell-selected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('keydown', this._handleKeyEvent);
    this.addEventListener('click', this._handleClickEvent);
  }

  disconnectedCallback(): void {
    this.removeEventListener('keydown', this._handleKeyEvent);
    this.removeEventListener('click', this._handleClickEvent);
    super.disconnectedCallback();
  }

  private _handleKeyEvent(keyboardEvent: EventWithTarget<HTMLElement, KeyboardEvent>): void {
    let { code } = keyboardEvent;
    const { target, shiftKey } = keyboardEvent;
    // map Tab to Arrow.Right and Shift-Tab to Arrow.Left
    if (code === 'Tab') {
      keyboardEvent.preventDefault();
      code = shiftKey ? ArrowKeys.ArrowLeft : ArrowKeys.ArrowRight;
    }
    let nextInDirection = null;
    switch (code) {
      case ArrowKeys.ArrowUp: {
        nextInDirection = getPreviousPickerGridCell(target, this.columns);
        break;
      }
      case ArrowKeys.ArrowLeft: {
        nextInDirection = getPreviousPickerGridCell(target);
        break;
      }
      case ArrowKeys.ArrowRight: {
        nextInDirection = getNextPickerGridCell(target);
        break;
      }
      case ArrowKeys.ArrowDown: {
        nextInDirection = getNextPickerGridCell(target, this.columns);
        break;
      }
    }
    if (nextInDirection) {
      nextInDirection.focus();
    }
  }

  private _handleClickEvent({ target }): void {
    if (target instanceof GridCell) {
      this.emitPickerGridCellSelectedEvent({ value: target.value, selected: target });
    } else if (target instanceof HeaderCell) {
      this.emitPickerHeaderCellSelectedEvent({ text: target.text, value: target.value, selected: target });
    }
  }

  protected render(): TemplateResult {
    return html`
      <slot name="pickerGridHeaderCells" style="---zui-picker-grid-columns: ${this.columns}"></slot>
      <slot name="pickerGridCells" style="---zui-picker-grid-columns: ${this.columns}"></slot>
    `;
  }
}
