import { css, CSSResultArray, customElement, html, property, TemplateResult, unsafeCSS } from 'lit-element';
import { BaseElement } from '../base/BaseElement';
import { event } from '../../decorators/event.decorator';
import style from './icon-bar.component.scss';
import { InteractiveIcon } from '../interactive-icon/interactive-icon.component';

const iconBarStyles = css`
  ${unsafeCSS(style)}
`;

type Emphasis = InteractiveIcon['emphasis'];

/**
 * Description of iconBar
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-icon-bar emphasis="highlight">
 * 		<zui-icon-holy-placeholder slot="icon1"></zui-icon-holy-placeholder>
 * 		<zui-icon-holy-placeholder slot="icon2"></zui-icon-holy-placeholder>
 * 		<zui-icon-holy-placeholder slot="icon3"></zui-icon-holy-placeholder>
 * </zui-icon-bar>
 * ```
 *
 * @fires close - event which gets fired when the icon bar is closed
 * @slot icon1 - first icon slot.
 * @slot icon2 - second icon slot.
 * @slot icon3 - third icon slot.
 */
@customElement('zui-icon-bar')
export class IconBar extends BaseElement {
  static get styles(): CSSResultArray {
    return [iconBarStyles];
  }

  /**
   * Defines one of three possible emphasis states (default/subtle/highlight)
   */
  @property({ reflect: true })
  emphasis: Emphasis = 'subtle';

  /**
   * If closable is enabled an additional button will be visible that allows the user to close the box.
   */
  @property({ reflect: true, type: Boolean })
  closable = false;

  /**
   * @private
   */
  @event({
    eventName: 'close',
    bubbles: true,
    composed: true,
    cancelable: true,
  })
  emitCloseEvent(): boolean {
    return this.dispatchEvent(
      new CustomEvent('close', {
        bubbles: true,
        composed: true,
        cancelable: true,
      })
    );
  }

  /**
   * Adds an event listener to an icon slot to apply different styling attributes if there are icons or not.
   *
   * @param iconSlotContainer - the container element containing an icon slot
   */
  private _setEventListenerForIconSlots(iconSlotContainer: Element): void {
    const slot = iconSlotContainer.querySelector('slot');
    slot.addEventListener('slotchange', () => {
      if (slot.assignedNodes().length !== 0 && !this._isSlotEmpty(slot)) {
        // used attribute is used in styling to set left-margin
        iconSlotContainer.classList.add('used');
        // sets the size of the element in the slot to m
        this._setIconSize(slot);
      } else {
        iconSlotContainer.classList.remove('used');
      }
    });
  }

  /**
   * Checks if the given slot is empty
   *
   * @param {HTMLSlotElement} slot the slot to check if it is empty
   * @returns boolean is true if there are only slots in the slot or nothing
   */
  private _isSlotEmpty(slot): boolean {
    let slotEmpty = true;
    slot.assignedNodes().forEach((node) => {
      if (node instanceof HTMLElement) {
        if (node instanceof HTMLSlotElement) {
          slotEmpty = this._isSlotEmpty(node);
        } else {
          slotEmpty = false;
        }
      }
    });
    return slotEmpty;
  }

  /**
   * Handler for the click event on the close button, fires a box-closed event.
   */
  private _handleClick(): void {
    this.dispatchEvent(
      new CustomEvent('close', {
        bubbles: true,
        composed: true,
        cancelable: true,
      })
    );
  }

  /**
   * Set size of icon in the icon slot to 'm'.
   *
   * @param {HTMLSlotElement} slot the slot to change the size of the included icon
   */
  private _setIconSize(slot): void {
    slot.assignedNodes().forEach((node) => {
      if (node instanceof HTMLElement) {
        if (node instanceof HTMLSlotElement) {
          this._setIconSize(node);
        }
        // Assumes that it is a zui icon
        // there is no interface for the icons thats why it can't easily tested
        node.setAttribute('size', 'm');
      }
    });
  }

  /**
   * Registers a slotchange listener for each icon slot.
   *
   * @param changedProperties - a map of properties
   */
  protected firstUpdated(changedProperties: Map<keyof this, this[keyof this]>): void {
    if (super.firstUpdated) {
      super.firstUpdated(changedProperties);
    }
    const iconSlots = this.shadowRoot.querySelectorAll('.interactive-icon');
    iconSlots.forEach((iconSlotContainer) => {
      this._setEventListenerForIconSlots(iconSlotContainer);
    });
  }

  protected render(): TemplateResult | void {
    return html` <div id="icon-container">
      <zui-interactive-icon class="interactive-icon" emphasis="${this.emphasis}">
        <slot name="icon1"> </slot>
      </zui-interactive-icon>
      <zui-interactive-icon class="interactive-icon" emphasis="${this.emphasis}">
        <slot name="icon2"> </slot>
      </zui-interactive-icon>
      <zui-interactive-icon class="interactive-icon" emphasis="${this.emphasis}">
        <slot name="icon3"> </slot>
      </zui-interactive-icon>
      ${this.closable
        ? html`<zui-interactive-icon emphasis="${this.emphasis}" @click="${this._handleClick}" id="closebutton">
            <zui-icon-close size="s"></zui-icon-close>
          </zui-interactive-icon>`
        : ''}
    </div>`;
  }
}
