import { CSSResultArray, TemplateResult, customElement, html, css, unsafeCSS, property } from 'lit-element';
import { PropertyValues } from 'lit-element/lib/updating-element';
import { queryAssignedNodes } from 'lit-element/lib/decorators';
import { hostStyles } from '../../../host.styles';
import { BaseElement } from '../../base/BaseElement';
import { EventWithTarget } from '../../../types';
import { event } from '../../../decorators/event.decorator';

import { MenuTabIconItem } from '../menu-tab-icon-item/menu-tab-icon-item.component';
import { MenuTabTextItem } from '../menu-tab-text-item/menu-tab-text-item.component';

import style from './menu-tab-bar.component.scss';

const menuTabBarStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * Menu tab bar.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---3.2?node-id=36634%3A0)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=52930%3A120)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-menu-tab-bar size="m" value="#1">
 *   <zui-menu-tab-text-item text="Tab text item 1" value="#1"></zui-menu-tab-text-item>
 *   <zui-menu-tab-text-item text="Tab text item 2" value="#2"></zui-menu-tab-text-item>
 *   <zui-menu-tab-text-item text="Tab text item 3" value="#3"></zui-menu-tab-text-item>
 * </zui-menu-tab-text-item>
 * ```
 *
 * ```html
 * <zui-menu-tab-bar value="#2">
 *   <zui-menu-tab-icon-item value="#1"><zui-icon-laser-laser-ready></zui-icon-laser-laser-ready></zui-menu-tab-icon-item>
 *   <zui-menu-tab-icon-item value="#2"><zui-icon-laser-laser-ready></zui-icon-laser-laser-ready></zui-menu-tab-icon-item>
 *   <zui-menu-tab-icon-item value="#3"><zui-icon-laser-laser-ready></zui-icon-laser-laser-ready></zui-menu-tab-icon-item>
 * </zui-menu-tab-text-item>
 * ```
 *
 * @slot - default slot for `zui-menu-tab-text-item`'s or `zui-menu-tab-icon-item`'s
 *
 * @fires {CustomEvent<{ value: string }>} menu-tab-bar-selection-changed - emits when an item is selected
 */
@customElement('zui-menu-tab-bar')
export class MenuTabBar extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, menuTabBarStyles];
  }

  /**
   * set size of `zui-menu-tab-text-item`'s; `zui-menu-tab-icon-item`'s always default to size 'm'
   */
  @property({ reflect: true, type: String })
  size: MenuTabTextItem['size'] = 'm';

  /**
   * value of the slected `zui-menu-tab-text-item` or `zui-menu-tab-icon-item`
   */
  @property({ reflect: true, type: String })
  value: string;

  /**
   * Emits a custom menu-tab-bar-selection-changed event when an item is selected
   *
   * @param detail { value: string }
   * @param detail.value current selected
   *
   * @private
   */
  @event({
    eventName: 'menu-tab-bar-selection-changed',
    bubbles: true,
    composed: true,
  })
  emitMenuTabBarSelectionChangedEvent(detail: { value: string }): void {
    this.dispatchEvent(
      new CustomEvent('menu-tab-bar-selection-changed', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  @queryAssignedNodes('', true, 'zui-menu-tab-icon-item')
  private _assignedMenuTabIconItems: MenuTabIconItem[];

  @queryAssignedNodes('', true, 'zui-menu-tab-text-item')
  private _assignedMenuTabTextItems: MenuTabTextItem[];

  private _propagateMenuTabBarSize(): void {
    this._assignedMenuTabTextItems.forEach((menuTabTextItem) => (menuTabTextItem.size = this.size));
  }

  private _propagateMenuTabBarValue(): void {
    if (!this.value) {
      return;
    }

    this._assignedMenuTabIconItems.forEach((menuTabIconItem) => {
      menuTabIconItem.emphasis = menuTabIconItem.value === this.value ? 'selected' : 'default';
    });
    this._assignedMenuTabTextItems.forEach((menuTabTextItem) => {
      menuTabTextItem.emphasis = menuTabTextItem.value === this.value ? 'selected' : 'default';
    });
  }

  private _handleMenuTabItemSelected({ target }: EventWithTarget<MenuTabIconItem | MenuTabTextItem>): void {
    this.value = target.value;

    this.emitMenuTabBarSelectionChangedEvent({ value: target.value });
  }

  private _handleMenuTabItemSlotchange(): void {
    this._propagateMenuTabBarSize();
    this._propagateMenuTabBarValue();
  }

  protected updated(changedProperties: PropertyValues): void {
    if (changedProperties.has('size')) {
      this._propagateMenuTabBarSize();
    }

    if (changedProperties.has('value')) {
      this._propagateMenuTabBarValue();
    }
  }

  protected render(): TemplateResult {
    return html`
      <slot @click="${this._handleMenuTabItemSelected}" @slotchange="${this._handleMenuTabItemSlotchange}"></slot>
    `;
  }
}
