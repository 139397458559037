import type { Placement } from '@popperjs/core/lib/enums';
import type { Instance as PopperInstance, VirtualElement } from '@popperjs/core/lib/popper-lite';
import type { FlipModifier } from '@popperjs/core/lib/modifiers/flip';
import type { OffsetModifier } from '@popperjs/core/lib/modifiers/offset';

import { createPopper } from '@popperjs/core/dist/esm/popper-lite';
import flip from '@popperjs/core/dist/esm/modifiers/flip';
import offset from '@popperjs/core/dist/esm/modifiers/offset';

import { css, customElement, html, LitElement, property, PropertyValues, TemplateResult, unsafeCSS } from 'lit-element';
import { ifDefined } from 'lit-html/directives/if-defined';

import { EventWithTarget } from '../../types';
import { getStringArrayConverter } from '../../utils/component.utils';
import { unregisterPortal, unwrapFirstSlottedElement } from '../../utils/portal.utils';

import { hostStyles } from '../../host.styles';
import style from './overlay.directive.scss';

type Offset = [number | null | undefined, number | null | undefined];
type OffsetHandler = ({ placement: Placement }) => Offset;

export const OVERLAY_DIRECTIVE_STYLES = css`
  ${unsafeCSS(style)}
`;

const OVERLAY_DEFAULT_PLACEMENTS: Placement[] = ['bottom-start'];

/**
 * This directive allows projecting arbitrary html content into an overlay.
 * The projected contents will be positioned relative to the host.
 *
 * @example
 * HTML:
 * ```html
 * <zui-overlay-directive>
 *   <div>
 *     Projected content
 *   </div>
 * </zui-overlay-directive>
 * ```
 *
 * @slot - The default slot content will be projected into the overlay.
 */
@customElement('zui-overlay-directive')
export class OverlayDirective extends LitElement {
  static readonly styles = [hostStyles, OVERLAY_DIRECTIVE_STYLES];

  /**
   * The destination overlay name is passed through.
   */
  @property({ reflect: true, type: String })
  portal?: string;

  /**
   * An optional level to be used if the portal is created dynamically.
   */
  @property({ reflect: true, type: Number })
  level?: number;

  /**
   * Allowed placements of the overlay content relative to the host.
   * Multiple values can be provided as space separated list. The
   * first setting will be applied initially.
   * Defaults to `bottom-start`.
   *
   * @example `placements="bottom-start top-end"`
   * @see https://popper.js.org/docs/v2/constructors/#options
   */
  @property({ reflect: true, converter: getStringArrayConverter<Placement>() })
  placements: Placement[] = OVERLAY_DEFAULT_PLACEMENTS;

  /**
   * Whether to flip content if space is up or not.
   * Possible flipping directions are set via the `placements` attribute.
   */
  @property({ reflect: true, type: Boolean })
  flip = false;

  /**
   * Allows setting a padding to the flip detection.
   */
  @property({ reflect: true, type: Number, attribute: 'flip-padding' })
  flipPadding = 0;

  /**
   * Allows restoring portal contents after they are destroyed.
   */
  @property({ reflect: true, type: Boolean })
  restore = false;

  /**
   * An optional callback function which allows to reveal the positioning reference element.
   *
   * @returns VirtualElement minimal element implementation to derive position
   */
  @property({ reflect: false, attribute: false })
  positionReferenceCallback: () => VirtualElement = () => this;

  /**
   * An optional function which allows to customize the tooltip offset.
   *
   * @returns function offset handler
   */
  @property({ reflect: false, attribute: false })
  offsetHandler: OffsetHandler = () => [0, 0];

  private _popperInstance?: PopperInstance;

  // Forces popper to render again.
  forcePositioning(): void {
    this._popperInstance?.forceUpdate();
  }

  handleSlotChange({ target }: EventWithTarget<HTMLSlotElement>): void {
    this._positionSlotContents(target);
  }

  disconnectedCallback(): void {
    unregisterPortal(this.portal);
    this._endPositioning();
    super.disconnectedCallback();
  }

  // recursively finds slotted contents for positioning
  private _positionSlotContents(slot: HTMLSlotElement): void {
    // check if contents are present
    const element = unwrapFirstSlottedElement(slot);
    if (element !== undefined) {
      this._endPositioning();
      this._startPositioning(element as HTMLElement);
    }
  }

  // creates a new popper instance
  private _startPositioning(target: HTMLElement): void {
    // instantiate popper placement library in lite mode
    this._popperInstance = createPopper(this.positionReferenceCallback(), target);
    // set options to instance
    this._setPositioningOptions();
  }

  // derives and updates options for the popper instance
  private async _setPositioningOptions(): Promise<void> {
    // we need an instance to be prepared
    if (!this._popperInstance) {
      return;
    }

    // instantiate popper placement library in lite mode
    const [placement, ...fallbackPlacements] =
      this.placements.length > 0 ? this.placements : OVERLAY_DEFAULT_PLACEMENTS;

    // customize flip modifier to place menu above or below
    const customFlip: Partial<FlipModifier> = {
      ...flip,
      enabled: this.flip,
      options: { fallbackPlacements, padding: this.flipPadding },
    };

    // prepare custom offsets
    const customOffset: Partial<OffsetModifier> = {
      ...offset,
      options: { offset: this.offsetHandler },
    };

    // prepare modifiers
    const modifiers = [customFlip, customOffset];

    // set the options to the instance
    await this._popperInstance.setOptions({ modifiers, placement });
  }

  // finishes an existing popper instance
  private _endPositioning(): void {
    // destroy library instance
    this._popperInstance?.destroy();
    this._popperInstance = undefined;
  }

  protected updated(_changedProperties: PropertyValues): void {
    super.updated(_changedProperties);
    // update popper options if properties have been modified
    if (
      _changedProperties.has('flip') ||
      _changedProperties.has('offsetHandler') ||
      _changedProperties.has('placements')
    ) {
      this._setPositioningOptions();
    }
  }

  protected render(): TemplateResult {
    return html`
      <zui-portal-directive
        ?restore="${this.restore}"
        level="${ifDefined(this.level)}"
        portal="${ifDefined(this.portal)}"
      >
        <slot @slotchange="${this.handleSlotChange}"></slot>
      </zui-portal-directive>
    `;
  }
}
