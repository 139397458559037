import { Constructor, LitElement } from 'lit-element';
import { SimpleFormParticipated } from '../types';
import { getParentElementBySelector } from '../utils/mixin.utils';

// we need them as closure, for proper removal
let _boundResetCallback;
let _boundSubmitCallback;
let _boundFormDataCallback;
// eslint-disable-next-line @typescript-eslint/naming-convention
export const SimpleFormParticipationMixin = <T extends Constructor<LitElement>>(
  superClass: T
): Constructor<LitElement & SimpleFormParticipated> => {
  class MixedSimpleFormParticipated extends superClass {
    private _hostForm: HTMLFormElement;

    private _initialValue: unknown;

    formResetCallback(): void {
      this['value'] = this._initialValue;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formSubmitCallback(event: Event): void {
      // intentionally blank, because currently there is no logic needed
      return;
    }

    formDataCallback({ formData }: { formData: FormData }): void {
      if (this['name'] !== undefined) {
        formData.append(this['name'], this.getAttribute('value'));
      }
    }

    // on connection to the DOM, find parent form
    connectedCallback(): void {
      super.connectedCallback();
      this._hostForm = getParentElementBySelector(this, 'form') as HTMLFormElement;
      if (this._hostForm) {
        // retain initial value
        this._initialValue = this['value'];
        // bind to onFormData + Reset & Co.
        _boundResetCallback = this.formResetCallback.bind(this);
        _boundSubmitCallback = this.formSubmitCallback.bind(this);
        _boundFormDataCallback = this.formDataCallback.bind(this);
        this._hostForm.addEventListener('reset', _boundResetCallback);
        this._hostForm.addEventListener('submit', _boundSubmitCallback);
        this._hostForm.addEventListener('formdata', _boundFormDataCallback);
      }
    }

    disconnectedCallback(): void {
      // remove event listeners from parent form
      if (this._hostForm) {
        this._hostForm.removeEventListener('reset', _boundResetCallback);
        this._hostForm.removeEventListener('submit', _boundSubmitCallback);
        this._hostForm.removeEventListener('formdata', _boundFormDataCallback);
      }
      super.disconnectedCallback();
    }
  }

  return MixedSimpleFormParticipated as Constructor<LitElement & SimpleFormParticipated> & T;
};
