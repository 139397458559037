import { CSSResultArray, TemplateResult, customElement, html, property, unsafeCSS, css } from 'lit-element';
import { BaseElement } from '../../base/BaseElement';
import { unsafeHTML } from 'lit-html/directives/unsafe-html';
import { EwiState, IconSetName, iconSets } from '../../../icon-sets';

import style from './ewiq-dialog.component.scss';

const ewiqDialogStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * The zui-ewiq-dialog is the current dialog component, providing top border coloring and an additional icon to
 * show the different states. It wraps headline-, description-, question and arbitrary sub components and slots, which can be used to offer
 * additional information, as well as a footer slot to align the button container.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13603%3A181749)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=39755%3A336102)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-ewiq-dialog state="info">
 * 		<div style="background-color:#FF68C7;width:100px;height:100px;opacity:0.1"></div>
 * </zui-ewiq-dialog>
 * ```
 *
 * @slot default - This is the default slot. It's an innerHtml of the zui-ewiq-dialog element
 * @slot headerText - This is slot for the header text. It's an innerHtml of the zui-ewiq-dialog element
 * @slot questionText - This is the slot for the question. It's an innerHtml of the zui-ewiq-dialog element
 * @slot arbitrary - This is the slot for the optional arbitrary content. It's an innerHtml of the zui-ewiq-dialog element
 * @slot footer - This is the slot for the question. It's an innerHtml of the zui-ewiq-dialog element
 */
@customElement('zui-ewiq-dialog')
export class EwiqDialog extends BaseElement {
  static get styles(): CSSResultArray {
    return [ewiqDialogStyles];
  }

  /**
   * IconSet of the ewiq dialog, it determinates which IconSet is used.
   */
  @property({ reflect: true, type: String, attribute: 'icon-set' })
  iconSet: IconSetName = 'common';

  /**
   * State of the ewiq dialog, it determinates which color the top border of this component has.
   * The default value is 'info' which colors the top border blue
   */
  @property({ reflect: true, type: String })
  state: EwiState = 'warning';

  /**
   * This disables the ewiq dialog
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * ARIA role for this element; defaults to 'dialog' if not explicitly set by author
   */
  @property({ reflect: true })
  role = 'dialog';

  /**
   * tabindex for this element; defaults to '0' if not explicitly set by author
   */
  @property({ reflect: true })
  tabindex = '0';

  /**
   * Returns the state mapped icon tag with the needed attributes
   */
  get icon(): TemplateResult {
    const iconName = iconSets[this.iconSet][this.state];
    const innerHtml = `<${iconName} size="xl" id="icon"></${iconName}>`;

    return html`${unsafeHTML(innerHtml)}`;
  }

  protected render(): TemplateResult | void {
    return html`
      <section id="dialog">
        <div id="top-border"></div>
        <zui-scrollable background="hidden" hitarea="enlarged">
          <div id="content">
            <section id="header">
              ${this.icon}
              <slot name="headerText"></slot>
            </section>
            <section id="description">
              <slot></slot>
            </section>
            <section id="question">
              <slot name="questionText"></slot>
            </section>
            <section id="arbitrary">
              <slot name="arbitrary"> </slot>
            </section>
          </div>
        </zui-scrollable>
        <footer id="footer">
          <slot name="footer"></slot>
        </footer>
      </section>
    `;
  }
}
