import {
  css,
  CSSResultArray,
  customElement,
  html,
  internalProperty,
  property,
  query,
  TemplateResult,
  unsafeCSS,
} from 'lit-element';

import type { ValidationCallback as _ValidationCallback, ValidationResult as _ValidationResult } from '../../types';
import { FormElement } from '../base/FormElement';
import { punycode } from '../../utils/punycode.utils';
import { event } from '../../decorators/event.decorator';
import { EventWithTarget } from '../../types';

import '../error-message/error-message.component';
import { nothing } from 'lit-html';
import { ifDefined } from 'lit-html/directives/if-defined';
import style from './text-field.component.scss';

const textFieldStyles = css`
  ${unsafeCSS(style)}
`;

type FormattingCallback = (value: string) => string;
type ValidationCallback = _ValidationCallback;
type ValidationResult = _ValidationResult;
type InputType = 'text' | 'email' | 'number';

/**
 * The TextField is a text input with a bottom line which changes color depending on interaction states.
 * It can be set to readonly which hides the bottom line and prevents users from changing the text.
 *
 * ## Validation
 *
 * It is possible to add a validation function to the validationCallback.
 * The validation function gets the current value as parameter and returns a ValidationResult.
 * A ValidationResult has a boolean isValid flag which is false when the validation was negative and an optional
 * message which replaces the validation-message in the error warning.
 *
 * ## Formatting
 *
 * It is possible to add a formatting function to the formattingCallback.
 * The formatting function gets the current value as parameter and returns a formatted string.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13009%3A2730)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=15127%3A499061)
 *
 * @example
 * Input type = text:
 *
 * ```html
 * <zui-textfield name="firstname" value="Luna" input-type="text"><zui-interactive-icon emphasis="subtle"
 *   slot=interactive-icon><zui-icon-holy-placeholder
 *   size="m"></zui-icon-holy-placeholder></zui-interactive-icon></zui-textfield>
 * ```
 *
 * Input type = email:
 *
 * ```html
 * <zui-textfield name="email" placeholder="Email" input-type="email"></zui-textfield>
 * ```
 *
 * Input type = number:
 *
 * ```html
 * <zui-textfield name="number" value="0" min="-10" max="10" step="2" input-type="number"
 *   align-rigth="true"></zui-textfield>
 * ```
 *
 * Validation callback function example without error message:
 *
 * ```js
 * function validate(value){
 * 		// retuns an object with the boolean variable isValid
 * 		// if isValid is wrong the error message from the validation-message attribute gets shown
 * 		return {
 * 			isValid: !isNaN(value)
 * 		};
 * }
 *
 * const textField = document.querySelector('zui-textfield')
 * textField.validationCallback = validate
 * ```
 *
 * Validation callback function example with error message:
 *
 * ```js
 * function validate(value){
 * 		// retuns an object with the variables isValid (boolean) and message (string)
 * 		// if isValid is wrong the string in the message variable gets shown as error message
 * 		return {
 * 			isValid: !isNaN(value),
 * 			message: "value has to be valid number"
 * 		}
 * }
 *
 * const textField = document.querySelector('zui-textfield')
 * textField.validationCallback = validate
 * ```
 *
 * Formatting callback function example:
 *
 * ```js
 * const numberFormatting = (value: string): string => (value.replace(/(.)(?=(\d{3})+$)/g, '$1.'));
 *
 * const textField = document.querySelector('zui-textfield')
 * textField.formattingCallback = numberFormatting
 * ```
 *
 * @fires change - The change event is fired when the value of the textfield has changed, similar to `<input
 *   type="text">`
 * @fires input - The input event is fired when the value of the textfield has changed, similar to `<input
 *   type="text">`
 * @fires blur - The blur event is fired when the component or arrows are leaved, similar to `<input
 *   type="text">`
 * @slot interactive-icon - This is the slot for the interactive icon.
 */
@customElement('zui-textfield')
export class TextField extends FormElement {
  /**
   * input type of the text field
   */
  @property({ reflect: false, attribute: 'input-type' })
  inputType: InputType = 'text';

  /**
   * The validation callback of the TextField, it allows to set a function which validates the text
   */
  @property({ reflect: false, attribute: false })
  validationCallback: ValidationCallback = undefined;

  /**
   * The min value of the TextField when input type = number
   */
  @property({ reflect: true, type: Number })
  min: number;

  /**
   * The max value of the TextField when input type = number
   */
  @property({ reflect: true, type: Number })
  max: number;

  /**
   * The steps of the TextField when input type = number
   */
  @property({ reflect: true, type: Number })
  step = 1;

  /**
   * The unit of the TextField
   */
  @property({ reflect: true })
  unit = '';

  /**
   * AlignRight sets the alignment of the text in the input to 'right'
   */
  @property({ type: Boolean, reflect: true, attribute: 'align-right' })
  alignRight = false;

  /**
   * Sets the placeholder text for the input
   */
  @property({ type: String, reflect: true })
  placeholder = '';

  /**
   * @private
   */
  @event({
    eventName: 'input',
    bubbles: true,
    cancelable: false,
    composed: false,
  })
  emitInputEvent(): void {
    this.dispatchEvent(
      new InputEvent('input', {
        bubbles: true,
        cancelable: false,
        composed: false,
      })
    );
  }

  /**
   * @private
   */
  @event({
    eventName: 'change',
    // use values from change event of HTML <input type=text>.
    bubbles: true,
    cancelable: false,
    composed: false,
  })
  emitChangeEvent(): void {
    this.dispatchEvent(
      new InputEvent('change', {
        bubbles: true,
        cancelable: false,
        composed: false,
      })
    );
  }

  /**
   * @private
   */
  @event({
    eventName: 'blur',
    // use values from blur event of HTML <input type=text>.
    bubbles: true,
    cancelable: false,
    composed: false,
  })
  emitBlurEvent(): void {
    this.dispatchEvent(
      new FocusEvent('blur', {
        bubbles: true,
        cancelable: false,
        composed: false,
      })
    );
  }

  /**
   * Formatted value
   */
  @internalProperty()
  private _formattedValue = '';

  /**
   * Validation state of the text in the text field
   *
   * @returns {ValidationResult} the validation result
   */
  @internalProperty()
  get validationState(): ValidationResult {
    return this._validationState;
  }

  @query('#raw')
  private readonly _inputElement: HTMLInputElement | null;

  @query('#formatted')
  private readonly _formattedInputElement: HTMLInputElement | null;

  @query('#iconslot')
  private readonly _iconSlot: HTMLSlotElement | null;

  private _validationState: ValidationResult = { isValid: true };

  private _isArrowAsLastClicked = false;

  /**
   * Formatting callback of the text field
   */
  private _formattingCallback: FormattingCallback = undefined;

  /**
   * The formatting callback of the TextField, it allows to set a function which formates the text
   *
   * @returns {FormattingCallback} the formatting callback function
   */
  @property({ reflect: false, attribute: false })
  get formattingCallback(): FormattingCallback {
    return this._formattingCallback;
  }

  /**
   * Sets the formatting callback
   *
   * @param {FormattingCallback} formattingCallback - new formatting callback function
   */
  set formattingCallback(formattingCallback: FormattingCallback) {
    this._formattingCallback = formattingCallback;
    this.updateFormattedValue();
  }

  private _validationMessage = '';

  /**
   * The validation message of the TextField
   *
   * @returns {string} the validation message
   */
  @property({ reflect: true, attribute: 'validation-message' })
  get validationMessage(): string {
    return this._validationMessage;
  }

  set validationMessage(newValue: string) {
    const oldValue = this._validationMessage;
    this._validationMessage = newValue;
    this.requestUpdate('validationMessage', oldValue);
  }

  /**
   * This returns the TextField into the read-only state
   *
   * @returns {boolean} the readonly state
   */
  @property({ reflect: true, type: Boolean })
  get readonly(): boolean {
    return super.readonly;
  }

  /**
   * Controls the readonly state of the input form
   *
   * @param newValue - new value for readonly property
   */
  set readonly(newValue: boolean) {
    const oldValue = this.readonly;
    super.readonly = newValue;
    this.requestUpdate('readonly', oldValue).then(() => {
      this._readonlyHandling();
      this._updateDisableAttribute();
    });
  }

  /**
   * Defines the styles of the base type
   *
   * @returns {CSSResultArray} stores component styles
   */
  static get styles(): CSSResultArray {
    return [textFieldStyles];
  }

  /**
   * Lifecycle callback if the disabled state of the element changes.
   * Triggers update of the disabled attributes of the slotted icons.
   *
   * @param disabled Indicates if the the element is disabled or not
   */
  formDisabledCallback(disabled: boolean): void {
    super.formDisabledCallback(disabled);
    this._updateDisableAttribute();
  }

  /**
   * Updates the formated Value
   */
  updateFormattedValue(): void {
    if (typeof this.formattingCallback === 'function') {
      this._formattedValue = this.value
        ? this.formattingCallback(punycode.toUnicode(this.value))
        : this.formattingCallback(this.value);
    } else {
      this._formattedValue = this.value ? punycode.toUnicode(this.value) : this.value;
    }
  }

  connectedCallback(): void {
    super.connectedCallback();

    // todo: this should be removed when a reusable solution has been implemented
    // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
    window.addEventListener('click', this._handleOutsideClick);
  }

  disconnectedCallback(): void {
    // todo: this should be removed when a reusable solution has been implemented
    // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
    window.removeEventListener('click', this._handleOutsideClick);

    super.disconnectedCallback();
  }

  /**
   * Triggers the validation of this component and returns true
   * if the component's value is valid, otherwise false.
   *
   * If the validation result changes, the component will be updated and the new validation result
   * can be seen in {@link validationState}.
   *
   * @returns {boolean} the validation result
   */
  checkValidity(): boolean {
    this.value = this._inputElement.value;
    if (typeof this.validationCallback === 'function') {
      const result = this._validate();

      const oldValue = this.validationState;
      this._validationState = result;
      this.requestUpdateInternal('validationState', oldValue);

      return result.isValid;
    } else {
      this._resetValidationState();
      return true;
    }
  }

  /**
   * Handle when up control gets clicked
   */
  stepUp(): void {
    this._inputElement.stepUp();
    this._alignValue();
    this.emitInputEvent();
    this._isArrowAsLastClicked = true;
  }

  /**
   * Handle when down control gets clicked
   */
  stepDown(): void {
    this._inputElement.stepDown();
    this._alignValue();
    this.emitInputEvent();
    this._isArrowAsLastClicked = true;
  }

  /**
   * Sets and removes the disabled attribute on icon
   */
  private _updateDisableAttribute(): void {
    if (this._iconSlot) {
      this._iconSlot.assignedNodes().forEach((element: HTMLElement) => {
        if (element.setAttribute) {
          if (this.readonly && this.disabled) {
            element.setAttribute('disabled', '');
          } else {
            element.removeAttribute('disabled');
          }
        }
      });
    }
  }

  /**
   * Sets readonly on input when host has it
   */
  private _readonlyHandling(): void {
    if (this.readonly) {
      this._inputElement.setAttribute('readonly', '');
      this._formattedInputElement.setAttribute('readonly', '');
    } else {
      this._inputElement.removeAttribute('readonly');
      this._formattedInputElement.removeAttribute('readonly');
    }
  }

  /**
   * Adds event listener to a slot which sets different attributes when slot is used
   *
   * @param slot a html slot element
   */
  private _setEventListenerForIconSlot(slot: HTMLSlotElement): void {
    slot.addEventListener('slotchange', () => {
      if (slot.assignedNodes().length !== 0) {
        // sets the size of the element in the slot to m
        const [iteminslot] = slot.assignedNodes();
        if (iteminslot) {
          if ((iteminslot as HTMLElement).setAttribute) {
            if ((iteminslot as HTMLElement).children[0].setAttribute) {
              (iteminslot as HTMLElement).children[0].setAttribute('size', 'm');
            }
            if (this.readonly && this.disabled) {
              (iteminslot as HTMLElement).setAttribute('disabled', '');
            }
          }
        }
      }
    });
  }

  /**
   * Returns the validityState as defined by the "form associated custom components" spec.
   *
   * @returns {ValidityState} the complete validation state
   */
  get validity(): ValidityState {
    return {
      valid: this.validationState.isValid,
      // default values
      badInput: false,
      customError: false,
      patternMismatch: false,
      rangeOverflow: false,
      rangeUnderflow: false,
      stepMismatch: false,
      tooLong: false,
      tooShort: false,
      typeMismatch: false,
      valueMissing: false,
    };
  }

  /**
   * Resets the value to the max value when it is over the max value or to the min value when it is under the min value.
   */
  private _clampingNumberValue(): void {
    if (typeof this.max === 'number' && Number.parseFloat(this._inputElement.value) > this.max) {
      this._inputElement.value = this.max.toString();
    }
    if (typeof this.min === 'number' && Number.parseFloat(this._inputElement.value) < this.min) {
      this._inputElement.value = this.min.toString();
    }
  }

  /**
   * Updates the value of the text field and the formatted input
   */
  private _alignValue(): void {
    if (this.inputType === 'number') {
      this._clampingNumberValue();
    }
    this.checkValidity();
    this.updateFormattedValue();
  }

  /**
   * Validate the value field with the validationCallback and return the result.
   *
   * @returns {ValidationResult} the validation result
   */
  private _validate(): ValidationResult {
    const result = this.validationCallback(this.value);

    if (!result.message && this.validationMessage) {
      result.message = this.validationMessage;
    }

    return result;
  }

  /**
   * reset the validationState if it's set.
   */
  private _resetValidationState(): void {
    if (this.validationState) {
      const oldValue = this.validationState;
      this._validationState = { isValid: true };

      this.requestUpdateInternal('validationState', oldValue);
    }
  }

  /**
   * If the user presses ENTER the validation will be triggered.
   *
   * @param event the keyboard event
   */
  private _handleKeyDown(event: KeyboardEvent): void {
    if (event.code === 'Enter' || event.key === 'Enter') {
      this._alignValue();
    }
  }

  /**
   * React when the user starts typing. This is used to hide the validation message (if any is shown)
   * as soon as the user starts typing to correct the wrong value.
   * We use "input" event instead of "keydown" to prevent hiding the message when a
   * special key (CTRL, Arrow, Alt,...) is pressed.
   */
  private _handleInput(): void {
    this._resetValidationState();

    if (this.inputType === 'number' && isNaN(this._inputElement.valueAsNumber)) {
      this.value = '';

      return;
    }

    this.value = this._inputElement.value;
  }

  /**
   * Trigger validation on blur.
   */
  private _handleBlur(): void {
    this._alignValue();
  }

  /**
   * Emit a change event when the value of the internal textfield has changed.
   * As change-events has composed=false, we need to emit a new one.
   */
  private _handleChange(): void {
    this.emitChangeEvent();
  }

  // todo: this should be removed when a reusable solution has been implemented
  // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
  private _handleOutsideClick = (event: EventWithTarget<TextField | FormElement>): void => {
    const isInsideClick =
      this.isSameNode(event.target) && event.composedPath().some((path) => path instanceof TextField);

    if (!isInsideClick && this._isArrowAsLastClicked) {
      this._isArrowAsLastClicked = false;

      this.emitBlurEvent();
    }
  };

  protected firstUpdated(changedProperties: Map<string, string | number | symbol>): void {
    if (super.firstUpdated) {
      super.firstUpdated(changedProperties);
    }
    this._setEventListenerForIconSlot(this._iconSlot);
    this.updateFormattedValue();
  }

  /**
   * Privated getter for the disabled state of the up arrow
   *
   * @returns {boolean} arrow up disabled state
   */
  private get _isUpArrowDisabled(): boolean {
    return (
      (typeof this.max === 'number' && Number.parseFloat(this.value) >= this.max) || this.readonly || this.disabled
    );
  }

  /**
   * Privated getter for the disabled state of the down arrow
   *
   * @returns {boolean} arrow down disabled state
   */
  private get _isDownArrowDisabled(): boolean {
    return (
      (typeof this.min === 'number' && Number.parseFloat(this.value) <= this.min) || this.readonly || this.disabled
    );
  }

  /**
   * Overwrites the onValueChanged function of the formelement to set the formatted value when the value is changed.
   */
  protected onValueChanged(): void {
    this.updateFormattedValue();
  }

  protected render(): TemplateResult {
    const showValidationMessage = !this.validationState.isValid && !this.readonly && !this.disabled;
    return html`<div
      id="container"
      class="${this.hasTouch ? 'touch' : ''}"
      validation-state="${this.validationState && this.validationState.isValid}"
    >
      <div id="input-container">
        <div id="input-wrapper">
          <input
            ?disabled="${this.disabled}"
            id="raw"
            min=${ifDefined(this.min)}
            max=${ifDefined(this.max)}
            step=${this.step}
            type="${this.inputType}"
            placeholder="${this.placeholder}"
            .value="${this.value ?? ''}"
            @blur="${this._handleBlur}"
            @keydown="${this._handleKeyDown}"
            @input="${this._handleInput}"
            @change="${this._handleChange}"
          />
          <input
            ?disabled="${this.disabled}"
            id="formatted"
            tabindex="-1"
            type="text"
            placeholder="${this.placeholder}"
            .value="${this._formattedValue ?? ''}"
          />
        </div>
        ${this.unit ? html`<span id="unit">${this.unit}</span>` : nothing}
        <slot id="iconslot" name="interactive-icon"></slot>
        ${this.inputType === 'number' && !this.hasTouch
          ? html`
              <div id="arrow-Container">
                <zui-interactive-icon
                  ?disabled=${this._isUpArrowDisabled}
                  emphasis="default"
                  tabindex="-1"
                  use-deprecated-hitarea
                  @click=${this.stepUp}
                >
                  <zui-icon-arrow-filled-arrow-filled-up size="xs"></zui-icon-arrow-filled-arrow-filled-up>
                </zui-interactive-icon>
                <zui-interactive-icon
                  ?disabled=${this._isDownArrowDisabled}
                  emphasis="default"
                  tabindex="-1"
                  use-deprecated-hitarea
                  @click=${this.stepDown}
                >
                  <zui-icon-arrow-filled-arrow-filled-down size="xs"></zui-icon-arrow-filled-arrow-filled-down>
                </zui-interactive-icon>
              </div>
            `
          : nothing}
      </div>
      ${showValidationMessage
        ? html`<zui-error-message id="error">${this.validationState.message}</zui-error-message>`
        : nothing}
    </div> `;
  }
}
