import { CSSResultArray, TemplateResult, customElement, html, css, unsafeCSS, property } from 'lit-element';
import { BaseElement } from '../../base/BaseElement';
import { hostStyles } from '../../../host.styles';
import { event } from '../../../decorators/event.decorator';
import { EventWithTarget } from '../../../types';

import { Button } from '../../button/button.component';

import style from './toggle-bar-button.component.scss';

const toggleBarButtonStyles = css`
  ${unsafeCSS(style)}
`;

type Emphasis = Button['emphasis'];
type Size = 's' | 'l';

/**
 * The zui-toggle-bar-button is a wrapper for the zui-button to add toggle bar specific styling.
 * It is not intended for single use but just as a part of the zui-toggle-bar.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.2?node-id=13009%3A2767)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=23651%3A480653)
 *
 * @example
 * HTML:
 *
 * ```html
 *   <zui-toggle-bar-button value="one">One</zui-toggle-bar-button>
 * ```
 *
 * @slot default - This is the default slot. It's an innerHtml of the button-element
 * @slot icon - Here you can insert your own icon
 *
 * @fires {CustomEvent<{ value: string }>} toggle-bar-button-selected - emits when the toggle bar button is selected
 */
@customElement('zui-toggle-bar-button')
export class ToggleBarButton extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, toggleBarButtonStyles];
  }

  /**
   * disabled state
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * Defines one of three possible emphasis states (default/highlight/primary-highlight).
   * The deprecated emphasis "active" and "active-primary" were renamed to "highlight" and "primary-highlight".
   */
  @property({ reflect: true, type: String })
  emphasis: Emphasis = 'default';

  /**
   * Defines one of two possible sizes (s/l);
   */
  @property({ reflect: true, type: String })
  size: Size = 's';

  /**
   * value of the toggle bar button
   */
  @property({ reflect: true, type: String })
  value: string;

  /**
   * Emits a custom `toggle-bar-button-selected` event with value as detail
   *
   * @private
   */
  @event({
    eventName: 'toggle-bar-button-selected',
    bubbles: true,
    cancelable: false,
    composed: false,
  })
  emitToggleBarButtonSelectedEvent(): void {
    this.dispatchEvent(
      new CustomEvent('toggle-bar-button-selected', {
        bubbles: true,
        cancelable: false,
        composed: false,
        detail: { value: this.value },
      })
    );
  }

  private _handleClick(event: EventWithTarget<Button>): void {
    event.stopPropagation();

    this.emitToggleBarButtonSelectedEvent();
  }

  protected render(): TemplateResult {
    return html`
      <zui-button
        ?disabled="${this.disabled}"
        emphasis="${this.emphasis}"
        size="${this.size}"
        type="button"
        @click="${this._handleClick}"
        ><slot></slot><slot name="icon" slot="icon"></slot
      ></zui-button>
    `;
  }
}
