// we disable func-style, because we'd otherwise have to export const funky = function funky() ... :(

import { Breakpoint } from '../types';
import type { ComplexAttributeConverter } from 'lit-element';

const COLOR_PREFIX = '--zui-color-';

interface MinMaxInterface {
  min: number;
  max: number;
}

const breakpoints: Record<Breakpoint, MinMaxInterface> = {
  [Breakpoint.XS]: {
    min: 0,
    max: 767,
  },
  [Breakpoint.S]: {
    min: 768,
    max: 1023,
  },
  [Breakpoint.M]: {
    min: 1024,
    max: 1279,
  },
  [Breakpoint.L]: {
    min: 1280,
    max: Infinity,
  },
};

/**
 * Checks if a string is not empty
 * a string is also empty when it contains only spaces
 *
 * @param {string | undefined} testString - The string which should be checked
 * @returns {boolean} whether the string is not empty
 * @private
 */
export function isStringNotEmpty(testString: string | undefined): boolean {
  // we are not empty, if the trimmed string has a length > 0
  if (testString) {
    return testString.trim().length > 0;
  } else {
    return false;
  }
}

/**
 * Checks if a string is empty
 * a string is also empty when it contains only spaces
 *
 * @param {string | undefined} testString - The string which should be checked
 * @returns {boolean} whether the string is empty
 * @private
 */
export function isStringEmpty(testString: string | undefined): boolean {
  return !isStringNotEmpty(testString);
}

/**
 * Checks if slot is not empty
 *
 * @param {HTMLSlotElement} slot the slot which gets checked
 * @returns {boolean} whether the slot is not empty
 * @private
 */
export function isSlotNotEmpty(slot: HTMLSlotElement): boolean {
  return slot.assignedNodes().length > 0;
}

/**
 * Checks if a slot is empty, i.e. has no assigned nodes
 *
 * @param {HTMLSlotElement} slot the slot which gets checked
 * @returns {boolean} whether the slot is empty
 * @private
 */
export function isSlotEmpty(slot: HTMLSlotElement): boolean {
  return !isSlotNotEmpty(slot);
}

/**
 * Returns the zui font, because font gets rendered differently by the browser than it gets set in the variable
 *
 * @param {string} fontClass class of the zui font
 * @returns {string} information of the zui font
 * @private
 */
export function getZuiFont(fontClass: string): string {
  // TODO: maybe should be refactored into own generate function
  const div = document.createElement('div');
  div.setAttribute('style', 'font: var(--zui-typography-' + fontClass + ');');
  document.documentElement.appendChild(div);
  const zuiFont = window.getComputedStyle(div).getPropertyValue('font');
  document.documentElement.removeChild(div);
  return zuiFont;
}

/**
 * Gets the rgb of a zui-color
 *
 * @param {string} colorname name of the zui color
 * @param {string} alpha optional alpha/opacity
 * @returns {string} rgb or when opacity is present the rgba of the zui color
 * @private
 */
export function getZuiColor(colorname: string, alpha?: string): string {
  const zuiColor = window.getComputedStyle(document.documentElement).getPropertyValue(COLOR_PREFIX + colorname);
  return hexToRgb(zuiColor, alpha);
}

/**
 * Gets the value of a css prpoerty from the given element
 *
 * @param {HTMLElement} element the element which
 * @param {string} prop the property
 * @returns {string} the value of the css property
 * @private
 */
export function getCssPropertyFromElement(element: HTMLElement, prop: string): string {
  const propertyValue = window.getComputedStyle(element).getPropertyValue(prop);
  return propertyValue;
}

/**
 * Converts a hex color to a rgba color
 *
 * @param {string} hex the hex color which should converted to rgb
 * @param {string} alpha optional alpha/opacity
 * @returns {string} the converted rgb color
 * @private
 */
export function hexToRgb(hex: string, alpha?: string): string {
  // something like -> https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
  const color = hex.trim();
  const red = parseInt(color.slice(1, 3), 16);
  const green = parseInt(color.slice(3, 5), 16);
  const blue = parseInt(color.slice(5, 7), 16);

  return alpha ? `rgba(${red}, ${green}, ${blue}, ${alpha})` : `rgb(${red}, ${green}, ${blue})`;
}

/**
 * Returns the computed style for an element returned by a query from a shadow dom
 *
 * @param {Element} webelement the webelement in which shadow dom the query should search
 * @param {string} cssQuery the query for the search
 * @returns {CSSStyleDeclaration} the returned computed Style, it will be empty when the query didn't found an element
 * @private
 */
export function getComputedStyleForQueryInShadowDom(webelement: Element, cssQuery: string): CSSStyleDeclaration {
  const element = webelement.shadowRoot?.querySelector(cssQuery);
  if (element) {
    return window.getComputedStyle(element);
  } else {
    // this will return an empty CSSStyleDeclaration
    return window.getComputedStyle(document.createElement('p'));
  }
}

/**
 * Compares the value of a specific border-property of an element to an expected value
 *
 * @param {HTMLElement} element the element to check
 * @param {string} borderProperty the specific border-property to check
 * @param {string} value expected value
 * @returns {boolean} whether the value is like it was expected
 * @private
 */
export function hasElementBorderPropertyValue(element: HTMLElement, borderProperty: string, value: string): boolean {
  return ['top', 'right', 'bottom', 'left'].every(
    (borderPart) => value === getCssPropertyFromElement(element, `border-${borderPart}-${borderProperty}`)
  );
}

/**
 * @param {HTMLElement} element the element where to find the slots
 * @param {string} slotName the name of the slot to obtain nodes
 * @returns {HTMLElement[]} returns the slotted element
 * @private
 */
export function getSlottedElementsFromNamedSlot(element: HTMLElement, slotName: string): HTMLElement[] {
  return Array.from(
    (element.shadowRoot.querySelector(`slot[name=${slotName}]`) as HTMLSlotElement).assignedNodes()
  ).filter((node) => node instanceof HTMLElement) as HTMLElement[];
}

/**
 * propagates a value to a selection of childs found by query from an element
 *
 * @param slot that the predicate is used to propagate values for
 * @param predicate function that filters childs
 * @param property that should be set for child matching predicagte
 * @param value for property for matched child by predicate
 */
// TODO: come up with some SICK uber TS magic, that generates a matching type for property and value
export function propagateValueToSlotContentByPredicate(
  slot: HTMLSlotElement,
  predicate: <T extends HTMLElement>(childElement: T) => boolean,
  property: string,
  value: unknown
): void {
  Array.from(slot.assignedElements())
    .filter(predicate)
    // eslint-disable-next-line no-return-assign
    .forEach((contentElement) => (contentElement[property] = value));
}
/**
 * returns the serialized DOMContent as a string from a slot
 *
 * @param {HTMLSlotElement} slot whose content should be serialized
 * @returns {string|undefined} returns either a string if the slot contains something or undefined if it is empty
 */
export function serializeSlotContent(slot: HTMLSlotElement): string | undefined {
  if (slot.assignedNodes().length > 0) {
    // get a temporary node, for our serializing business
    const tempNode = document.createElement('span');
    slot.assignedNodes().forEach((node) => tempNode.append(node.cloneNode(true)));
    return tempNode.innerHTML;
  } else {
    return undefined;
  }
}

/**
 * @param {HTMLElement} element the element from what the shadowRoot should be queried
 * @param {string|undefined} slotName an optional slotName for the querySelector
 * @returns {HTMLSlotElement | null} returns either a HTMLSlotElement or null
 */
export function getSlotByName(element: HTMLElement, slotName?: string): HTMLSlotElement | null {
  return element.shadowRoot.querySelector(`slot${slotName ? `[name="${slotName}"]` : ':not([name])'}`);
}

/**
 * @param {number} width the width that lies between a min and max of a Breakpoint
 * @returns {Breakpoint} returns a Breakpoint
 */
export function getBreakpointForWidth(width: number): Breakpoint {
  return Object.keys(breakpoints).find(
    (bp) => width >= breakpoints[bp].min && width <= breakpoints[bp].max
  ) as Breakpoint;
}

/**
 * @param {Breakpoint} media media
 * @param {'<' | '>'} operator smaller / larger
 * @param {Breakpoint} breakpoint breakpoint
 * @returns {boolean} returns whether the given media is larger or smaller than the breakpoint or not
 */
export function compareMediaBreakpoint(media: Breakpoint, operator: '<' | '>', breakpoint: Breakpoint): boolean {
  return {
    '<': (a: MinMaxInterface, b: MinMaxInterface): boolean => a.max < b.min,
    '>': (a: MinMaxInterface, b: MinMaxInterface): boolean => a.min > b.max,
  }[operator](breakpoints[media], breakpoints[breakpoint]);
}

/**
 * component attribute converter from string to boolean and vice versa
 */
export const booleanStringConverter = {
  fromAttribute: (value?: string): boolean => value === 'true',
  toAttribute: (value: boolean): string => (value ? 'true' : 'false'),
};

/**
 * a factory for creating a custom lit element converter for strings which are declared as list
 * (separated by a given string) in attributes, but handled as array properties internally
 *
 * @param {string} separator to be used to split and join items
 * @returns {ComplexAttributeConverter} custom attribute converter
 */
export const getStringArrayConverter = <T>(separator = ' '): ComplexAttributeConverter<T[]> => ({
  fromAttribute: (value = ''): T[] => (value.split(separator) as unknown) as T[],
  toAttribute: (value): string => value.join(separator),
});
