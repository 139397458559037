import { CSSResultArray, TemplateResult, customElement, html, css, unsafeCSS, property } from 'lit-element';
import { queryAssignedNodes } from 'lit-element/lib/decorators';
import { PropertyValues } from 'lit-element/lib/updating-element';
import { RealBaseElement } from '../../base/BaseElement';
import { FormDataHandlingMixin } from '../../../mixins/form-participation/form-data-handling.mixin';
import { FormEnabledElement } from '../../../mixins/form-participation/form-participation.types';
import { hostStyles } from '../../../host.styles';
import { EventWithTarget } from '../../../types';
import { event } from '../../../decorators/event.decorator';

import { ToggleBarButton } from '../toggle-bar-button/toggle-bar-button.component';

import style from './toggle-bar.component.scss';

const toggleBarStyles = css`
  ${unsafeCSS(style)}
`;

type Emphasis = 'default' | 'active' | 'active-primary';
type Size = 's' | 'l';

/**
 * The toggle bar is a collection of buttons that behave like a group of radio buttons.
 * Only one button can be active at a time. The activated button is highlighted by setting its emphasis.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.2?node-id=13009%3A2767)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=23651%3A480653)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-toggle-bar emphasis="active-primary" size="l" value="one">
 *   <zui-toggle-bar-button value="one">One</zui-toggle-bar-button>
 *   <zui-toggle-bar-button value="two">Two</zui-toggle-bar-button>
 *   <zui-toggle-bar-button value="three">Three</zui-toggle-bar-button>
 * </zui-toggle-bar>
 * ```
 *
 * ```html
 * <zui-toggle-bar emphasis="active-primary" size="l" value="one">
 *   <zui-toggle-bar-button value="one"><zui-icon-holy-placeholder slot="icon"></zui-icon-holy-placeholder></zui-toggle-bar-button>
 *   <zui-toggle-bar-button value="two"><zui-icon-holy-placeholder slot="icon"></zui-icon-holy-placeholder></zui-toggle-bar-button>
 *   <zui-toggle-bar-button value="three"><zui-icon-holy-placeholder slot="icon"></zui-icon-holy-placeholder></zui-toggle-bar-button>
 * </zui-toggle-bar>
 * ```
 *
 * @slot - default slot for zui-toggle-bar-button's
 *
 * @fires {Event} change - emits when the toggle bar value has changed
 * @fires {Event} input - emits when the toggle bar value has changed
 */
@customElement('zui-toggle-bar')
export class ToggleBar extends FormDataHandlingMixin(RealBaseElement) implements FormEnabledElement {
  static get styles(): CSSResultArray {
    return [hostStyles, toggleBarStyles];
  }

  /**
   * emphasis that is used for the selected button state
   */
  @property({ reflect: true, type: String })
  emphasis: Emphasis = 'active';

  /**
   * size
   */
  @property({ reflect: true, type: String })
  size: Size = 's';

  /**
   * value of the current selected button
   */
  @property({ reflect: true, type: String })
  value: string;

  /**
   * Emits a change event
   *
   * @private
   */
  @event({
    eventName: 'change',
    bubbles: true,
    cancelable: false,
    composed: true,
  })
  emitChangeEvent(): void {
    this.dispatchEvent(
      new Event('change', {
        bubbles: true,
        cancelable: false,
        composed: true,
      })
    );
  }

  /**
   * Emits an input event
   *
   * @private
   */
  @event({
    eventName: 'input',
    bubbles: true,
    cancelable: false,
    composed: true,
  })
  emitInputEvent(): void {
    this.dispatchEvent(
      new Event('input', {
        bubbles: true,
        cancelable: false,
        composed: true,
      })
    );
  }

  @queryAssignedNodes('', true, 'zui-toggle-bar-button')
  private _assignedToggleBarButtons: ToggleBarButton[];

  private _propagateDisabled = false;

  connectedCallback(): void {
    super.connectedCallback();

    this.addEventListener('toggle-bar-button-selected', this._handleToggleBarButtonSelected);
  }

  disconnectedCallback(): void {
    this.removeEventListener('toggle-bar-button-selected', this._handleToggleBarButtonSelected);

    super.disconnectedCallback();
  }

  private _propagateState(): void {
    this._assignedToggleBarButtons.forEach((button) => {
      if (this._propagateDisabled) {
        button.disabled = this.disabled;
      }
      button.emphasis = button.value === this.value ? this.emphasis : 'default';
      button.size = this.size;
    });
  }

  private _handleToggleBarButtonSelected(event: CustomEvent<{ value: string }>): void {
    event.stopPropagation();

    this.value = event.detail.value;
    this.emitChangeEvent();
    this.emitInputEvent();
  }

  private _handleSlotchange(): void {
    this._propagateState();
  }

  protected update(changedProperties: PropertyValues): void {
    super.update(changedProperties);
    // if disabled is true it should always be propagated
    if (
      this.disabled === true ||
      // or from true to false, we need to propagate it
      (changedProperties.get('disabled') === true && this.disabled === false)
    ) {
      this._propagateDisabled = true;
    }
  }

  protected updated(changedProperties: PropertyValues): void {
    super.updated(changedProperties);
    this._propagateState();
  }

  protected render(): TemplateResult {
    return html`<slot @slotchange="${this._handleSlotchange}"></slot>`;
  }
}
