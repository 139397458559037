import { css, customElement, html, LitElement, property, TemplateResult, unsafeCSS } from 'lit-element';
import { EventWithTarget } from '../../types';
import { hostStyles } from '../../host.styles';
import { preparePortal, unwrapFirstSlottedElement } from '../../utils/portal.utils';

import style from './portal.directive.scss';

export const PORTAL_DIRECTIVE_STYLES = css`
  ${unsafeCSS(style)}
`;

/**
 * This directive allows projecting arbitrary html elements into a portal.
 * The targeted portal (or at least a default portal) must exist in the DOM somewhere.
 *
 * @example
 * HTML:
 * ```html
 * ```
 *
 * @slot - The default slot content will be projected into the portal.
 */
@customElement('zui-portal-directive')
export class PortalDirective extends LitElement {
  static readonly styles = [hostStyles, PORTAL_DIRECTIVE_STYLES];

  /**
   * The destination can be given to target a specific portal other then the default one.
   * It behaves like the slot attribute, but for portals.
   */
  @property({ reflect: true })
  portal?: string;

  /**
   * An optional level to be used if the portal is created dynamically.
   */
  @property({ reflect: true, type: Number })
  level?: number;

  /**
   * Allows restoring portal contents after they are destroyed.
   */
  @property({ reflect: true, type: Boolean })
  restore = false;

  handleSlotChange({ target }: EventWithTarget<HTMLSlotElement>): void {
    this.projectContents(target);
  }

  /**
   * Projects the given slot elements contents to a portal.
   * **Only one element (not a text node!) can be projected!**
   *
   * @param slot - The slot which contents shall be projected
   */
  async projectContents(slot: HTMLSlotElement): Promise<void> {
    // as the contents are actually moved, the slot change event is triggered
    // twice, but the second time the slot is already missing its contents...
    const element = unwrapFirstSlottedElement(slot);
    // weirdly, the slot change listener is triggered even after disconnect,
    // thus we have to skip the content projection once disconnected
    if (element !== undefined && this.isConnected) {
      // check for related portal (and create if not existing yet)
      const portal = await preparePortal(this.portal, this.level);
      portal.restore = this.restore;
      // update the portal content with the latest slot contents
      portal.showContent(element);
    }
  }

  protected render(): TemplateResult {
    return html`<slot @slotchange="${this.handleSlotChange}"></slot>`;
  }
}
