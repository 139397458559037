import {
  css,
  CSSResultArray,
  customElement,
  html,
  property,
  queryAssignedNodes,
  state,
  TemplateResult,
  unsafeCSS,
} from 'lit-element';
import { nothing } from 'lit-html';
import { query } from 'lit-element/lib/decorators';
import { classMap } from 'lit-html/directives/class-map';
import { event } from '../../../decorators/event.decorator';
import { BaseElement } from '../../base/BaseElement';
import style from './list-accordion.component.scss';

import { ListAccordionItem } from '../list-accordion-item/list-accordion-item.component';

const listAccordionStyles = css`
  ${unsafeCSS(style)}
`;

type ListAccordionIconPosition = 'left' | 'right';
type ListAccordionSize = 's' | 'm' | 'l';

/**
 * List accordion.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=76932%3A367574)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=1%3A102403)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-list-accordion header-text="Header text">
 *   <zui-list-accordion-item primary-text="List item 1"></zui-list-accordion-item>
 *   <zui-list-accordion-item primary-text="List item 2"></zui-list-accordion-item>
 * </zui-list-accordion>
 * ```
 *
 * @slot - default slot for list items
 *
 * @fires {CustomEvent<{ opened: boolean, value: string | null }>} list-accordion-open-changed - emits when the open state has changed
 * @cssprop --zui-list-accordion-content-height - size of the accordion content, is set automatically
 * @cssprop --zui-list-accordion-content-visibility-transition-duration - duration of the toggle animation
 * @cssprop --zui-list-accordion-icon-arrow-rotation-duration - duration of the icon animation
 */
@customElement('zui-list-accordion')
export class ListAccordion extends BaseElement {
  static get styles(): CSSResultArray {
    return [listAccordionStyles];
  }

  /**
   * disabled
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * header text
   */
  @property({ reflect: true, type: String, attribute: 'header-text' })
  headerText = '';

  /**
   * whether the accordion is open or not
   *
   * @returns boolean
   */
  @property({ reflect: true, type: Boolean })
  get open(): boolean {
    return this._open;
  }

  set open(value: boolean) {
    const previousValue = this._open;
    this._open = value;
    this.requestUpdate('open', previousValue).then(() => {
      if (this._nextListAccordion !== null) {
        this._nextListAccordion.topLine = this.open;
      }
    });
  }

  /**
   * size
   */
  @property({ reflect: true, type: String })
  size: ListAccordionSize = 'm';

  /**
   * whether to show the top line or not
   */
  @property({ reflect: true, type: Boolean, attribute: 'top-line' })
  topLine = false;

  /**
   * optional value that is also emitted on open change for identification
   */
  @property({ reflect: true, type: String })
  value: string;

  /**
   * Emits a custom list-accordion-open-changed event when it's open state changes
   *
   * @param detail { opened: boolean, value: string | null }
   * @param detail.opened whether the accordion is opened or closed
   * @param detail.value value of the list accordion or null when undefined
   *
   * @private
   */
  @event({
    eventName: 'list-accordion-open-changed',
    bubbles: true,
    composed: true,
  })
  emitListAccordionOpenChangedEvent(detail: { opened: boolean; value: string | null }): void {
    this.dispatchEvent(
      new CustomEvent('list-accordion-open-changed', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  @state()
  private _iconPosition: ListAccordionIconPosition = 'right';

  @state()
  private _listAccordionContentHeight = 0;

  @state()
  private _open = false;

  @query('.list-accordion-header')
  private _listAccordionHeader: HTMLButtonElement;

  @queryAssignedNodes('', true, 'zui-list-accordion-item')
  private _assignedListAccordionItems: NodeListOf<ListAccordionItem>;

  private get _listAccordionItems(): ListAccordionItem[] {
    return Array.from(this._assignedListAccordionItems);
  }

  private _iconPositionRightMaxWidth = 560;

  private _resizeObserver = new ResizeObserver(
    async ([
      {
        contentRect: { width },
      },
    ]) => {
      requestAnimationFrame(() => (this._iconPosition = width <= this._iconPositionRightMaxWidth ? 'right' : 'left'));
    }
  );

  connectedCallback(): void {
    super.connectedCallback();

    this._resizeObserver.observe(this);
  }

  disconnectedCallback(): void {
    this._resizeObserver.unobserve(this);

    super.disconnectedCallback();
  }

  private static _listAccordionIconTemplate(): TemplateResult {
    return html`
      <div class="list-accordion-icon-arrow">
        <zui-icon-arrow-outline-arrow-outline-actually-centred-down
          size="s"
        ></zui-icon-arrow-outline-arrow-outline-actually-centred-down>
      </div>
    `;
  }

  private get _nextListAccordion(): ListAccordion | null {
    return this.nextElementSibling as ListAccordion;
  }

  private _handleListAccordionItemSlotChange(): void {
    this._listAccordionContentHeight = this._listAccordionItems
      .map((item) => item.getBoundingClientRect())
      .reduce((acc, { height }) => acc + height, 0);
  }

  private _handleListAccordionOpenToggle(): void {
    this.open = !this.open;
    // Chrome `.focus()` on _clicked_ buttons, Firefox and Safari don't; because
    // we do not want to have this behavior from Chrome, we are explicitly blurring
    this._listAccordionHeader.blur();

    this.emitListAccordionOpenChangedEvent({ opened: this.open, value: this.value ? this.value : null });
  }

  protected render(): TemplateResult {
    return html`
      <button
        ?disabled="${this.disabled}"
        class="list-accordion-header ${classMap({
          'icon-left': this._iconPosition === 'left',
          'icon-right': this._iconPosition === 'right',
        })}"
        @click="${this._handleListAccordionOpenToggle}"
      >
        ${this._iconPosition === 'left' ? ListAccordion._listAccordionIconTemplate() : nothing}
        <span class="list-accordion-header-text">${this.headerText}</span>
        ${this._iconPosition === 'right' ? ListAccordion._listAccordionIconTemplate() : nothing}
      </button>
      <div
        style="--zui-list-accordion-content-height: ${this._listAccordionContentHeight}px"
        class="list-accordion-content"
      >
        <slot @slotchange="${this._handleListAccordionItemSlotChange}"></slot>
      </div>
    `;
  }
}
