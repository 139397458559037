import {
  CSSResultArray,
  TemplateResult,
  customElement,
  html,
  property,
  unsafeCSS,
  css,
  queryAssignedNodes,
  internalProperty,
} from 'lit-element';
import type { EventWithTarget } from '../../../types';
import { FormElement } from '../../base/FormElement';
import { event } from '../../../decorators/event.decorator';
import style from './searchbar-input.component.scss';
import { nothing } from 'lit-html';

const searchbarInputStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * The SearchbarInput is a input field for search queries and allows users to quickly explore a website or application.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-searchbar-input placeholder="Enter your search here..."></zui-searchbar-input>
 * ```
 *
 *  * HTML (SearchbarInput with filter):
 *
 * ```html
 * <zui-searchbar-input placeholder="Enter your search here...">
 *   <zui-select
 *     all-item-label="Everything"
 *     hide-border
 *     ignore-outside-click
 *     multiple
 *     show-all-item
 *     slot="filter"
 *   >
 *     <zui-select-placeholder slot="placeholder" pattern-all="%selection" pattern-many="%selection">
 *       Filter...
 *     </zui-select-placeholder>
 *     <zui-select-option value="books">Books</zui-select-option>
 *     <zui-select-option value="movies">Movies</zui-select-option>
 *   </zui-select>
 * </zui-searchbar-input>
 * ```
 * @slot filter - Slot for a select with select options
 * @fires input - The input event is fired when the value of the searchbar-input has received input
 * @fires searchbar-input-changed - The searchbar-input-changed event is fired when the value of the searchbar-input has changed
 */
@customElement('zui-searchbar-input')
export class SearchbarInput extends FormElement {
  static get styles(): CSSResultArray {
    return [searchbarInputStyles];
  }

  /**
   * Sets the placeholder text for the input
   */
  @property({ type: String, reflect: true })
  placeholder = '';

  /**
   * @private
   */
  @event({
    eventName: 'input',
    bubbles: true,
    cancelable: false,
    composed: true,
  })
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  emitInputEvent(): void {
    // this is for generation magic only, because the input event from the input is composed
  }

  /**
   * @private
   */
  @event({
    eventName: 'searchbar-input-changed',
    bubbles: true,
    composed: true,
  })
  emitSearchbarInputChangedEvent(): void {
    this.dispatchEvent(new CustomEvent('searchbar-input-changed', { bubbles: true, composed: true }));
  }

  @internalProperty()
  private _hasFilterMenu = false;

  @queryAssignedNodes('filter', true, 'zui-select')
  private _assignedFilterMenu: NodeListOf<HTMLElement>;

  private _handleResetSearchbarInputValue(): void {
    if (this.disabled) {
      return;
    }

    this.value = '';
    this.emitSearchbarInputChangedEvent();
  }

  private _handleSearchbarInputEvent(event: EventWithTarget<HTMLInputElement, InputEvent>): void {
    if (event instanceof InputEvent) {
      this.value = event.target.value;
      this.emitInputEvent();
    }
  }

  private _handleSearchbarInputChangedEvent() {
    this.emitSearchbarInputChangedEvent();
  }

  private _handleSlotChange(): void {
    this._hasFilterMenu = this._assignedFilterMenu.length > 0;
  }

  protected render(): TemplateResult {
    return html`
      <div class="searchbar-input-wrapper">
        <slot name="filter" @slotchange="${this._handleSlotChange}"></slot>
        ${this._hasFilterMenu ? html`<div class="spacer"></div>` : nothing}
        <input
          .value="${this.value}"
          ?disabled=${this.disabled}
          id="searchbar-input"
          placeholder="${this.placeholder}"
          type="text"
          @change="${this._handleSearchbarInputChangedEvent}"
          @input=${this._handleSearchbarInputEvent}
        />
        ${this.value
          ? html`
              <zui-interactive-icon id="close-icon" emphasis="subtle">
                <zui-icon-close size="s" @click="${this._handleResetSearchbarInputValue}"></zui-icon-close>
              </zui-interactive-icon>
            `
          : html`<zui-icon-search-search id="search-icon" size="m"></zui-icon-search-search> `}
      </div>
    `;
  }
}
