import { css, CSSResultArray, TemplateResult, customElement, html, property, unsafeCSS } from 'lit-element';
import { BaseElement } from '../base/BaseElement';
import { hostStyles } from '../../host.styles';

import style from './divider.component.scss';

const dividerStyles = css`
  ${unsafeCSS(style)}
`;

type Size = 's' | 'l';
type EmphasisDeprecated = 'moderate';
type Emphasis = 'default' | 'highlight' | EmphasisDeprecated;
type Orientation = 'horizontal' | 'vertical';

/**
 * A divider is a horizontal line that can be used to define a thematic break.
 * The component can also be used as a vertical line too.
 * Line height has a 100% and is inherited from parent.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13009%3A2739)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=32766%3A522888)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-divider emphasis="highlight" size="l"></zui-divider>
 * ```
 */
@customElement('zui-divider')
export class Divider extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, dividerStyles];
  }

  /**
   * Defines one of two possible sizes (small/large);
   */
  @property({ reflect: true, type: String })
  size: Size = 's';

  /**
   * Defines one of two possible emphasis states (default/highlight)
   * The deprecated emphasis "moderate" was renamed to "default"
   */
  @property({ reflect: true, type: String })
  emphasis: Emphasis = 'default';

  /**
   * Defines one of two possible orientation (horizontal/vertical);
   */
  @property({ reflect: true, type: String })
  orientation: Orientation = 'horizontal';

  /**
   * Detects if one of the deprecated emphasis states was used
   *
   * @param {Map<string, unknown>} changedProperties properties which have been changed
   */
  protected update(changedProperties: Map<string, unknown>): void {
    if (changedProperties.has('emphasis')) {
      const emphasis = changedProperties.get('emphasis');
      if (emphasis === 'moderate') {
        console.warn(`Deprecated emphasis: ${emphasis} was used on zui-divider.`);
      }
    }
    super.update(changedProperties);
  }

  protected render(): TemplateResult {
    return html`<hr />`;
  }
}
