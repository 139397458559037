import { DateTime, Info, Interval, UnitLength } from 'luxon';
import * as weekData from 'cldr-core/supplemental/weekData.json';

export enum DatePickerWeekdayEnum {
  Mo = 'Mo',
  Tu = 'Tu',
  We = 'We',
  Th = 'Th',
  Fr = 'Fr',
  Sa = 'Sa',
  Su = 'Su',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

const datePickerWeekStartMap = new Map([
  [DatePickerWeekdayEnum.Su, 0],
  [DatePickerWeekdayEnum.Sunday, 0],
  [DatePickerWeekdayEnum.Mo, 1],
  [DatePickerWeekdayEnum.Monday, 1],
  [DatePickerWeekdayEnum.Tu, 2],
  [DatePickerWeekdayEnum.Tuesday, 2],
  [DatePickerWeekdayEnum.We, 3],
  [DatePickerWeekdayEnum.Wednesday, 3],
  [DatePickerWeekdayEnum.Th, 4],
  [DatePickerWeekdayEnum.Thursday, 4],
  [DatePickerWeekdayEnum.Fr, 5],
  [DatePickerWeekdayEnum.Friday, 5],
  [DatePickerWeekdayEnum.Sa, 6],
  [DatePickerWeekdayEnum.Saturday, 6],
]);

const datePickerWeekday: Record<string, DatePickerWeekdayEnum> = {
  ['Mon']: DatePickerWeekdayEnum.Mo,
  ['Tue']: DatePickerWeekdayEnum.Tu,
  ['Wed']: DatePickerWeekdayEnum.We,
  ['Thu']: DatePickerWeekdayEnum.Th,
  ['Fri']: DatePickerWeekdayEnum.Fr,
  ['Sat']: DatePickerWeekdayEnum.Sa,
  ['Sun']: DatePickerWeekdayEnum.Su,
  ['Monday']: DatePickerWeekdayEnum.Monday,
  ['Tuesday']: DatePickerWeekdayEnum.Tuesday,
  ['Wednesday']: DatePickerWeekdayEnum.Wednesday,
  ['Thursday']: DatePickerWeekdayEnum.Thursday,
  ['Friday']: DatePickerWeekdayEnum.Friday,
  ['Saturday']: DatePickerWeekdayEnum.Saturday,
  ['Sunday']: DatePickerWeekdayEnum.Sunday,
};

enum WeekdaysEnum {
  Sun = 'sun',
  Mon = 'mon',
  Tue = 'tue',
  Wed = 'wed',
  Thu = 'thu',
  Fri = 'fri',
  Sat = 'sat',
}

const weekStartMap = new Map([
  [WeekdaysEnum.Sun, 0],
  [WeekdaysEnum.Mon, 1],
  [WeekdaysEnum.Tue, 2],
  [WeekdaysEnum.Wed, 3],
  [WeekdaysEnum.Thu, 4],
  [WeekdaysEnum.Fri, 5],
  [WeekdaysEnum.Sat, 6],
]);

/**
 * @returns {string} returns the full browser locale or en-US when none is found
 */
export function getDefaultLocale(): string {
  return navigator.languages.find((lang) => lang.includes('-')) || 'en-US';
}

/**
 * Get the weekstart for the given region, When the region is not found, Sunday is returned as fallback.
 *
 * @param {string} region DE, US, ...
 * @returns {number} 0 (Sunday), 1 (Monday), ...
 */
export function getWeekStartByRegion(region: string): number {
  const weekDataFirstDay = weekData.supplemental.weekData.firstDay;
  const firstDay = weekDataFirstDay[region];

  return firstDay ? weekStartMap.get(firstDay) : 0;
}

/**
 * Get the weekstart for the given locale. When the region is not found Sunday (0) is returned as fallback.
 *
 * @param {string} locale de-DE, en-US, ...
 * @returns {number} 0 (Sunday), 1 (Monday), ...
 */
export function getWeekStart(locale: string = getDefaultLocale()): number {
  const [, region] = locale.toUpperCase().split(/[-_]/);

  return getWeekStartByRegion(region);
}

/**
 * Get the abbreviations of the days of the week for the given locale
 *
 * @param {string} locale locale de-DE, en-US, ...
 * @param {DatePickerWeekdayEnum | undefined} weekStart when a week start is given the locale is ignored for start of week
 * @returns {{text: string, value: string}[]} returns the abbreviations of the weekdays and the weekday as value
 */
export function getWeekdays(locale: string, weekStart?: DatePickerWeekdayEnum): { text: string; value: string }[] {
  const startOfWeek = weekStart ? datePickerWeekStartMap.get(weekStart) : getWeekStart(locale);
  const weekdays = Info.weekdays('short', { locale });
  const usWeekdays = Info.weekdays('short', { locale: 'en-US' });

  // start at 0 (Sunday)
  weekdays.unshift(weekdays.pop());
  usWeekdays.unshift(usWeekdays.pop());

  let i = startOfWeek;

  return weekdays.map((weekday, index, array) => {
    i = i % 7;

    const next = i++;

    return { text: array[next].toLocaleUpperCase(locale), value: datePickerWeekday[usWeekdays[next]] };
  });
}
/**
 * This function returns an array of DateTimes. The locale is used to get the week start.
 *
 * @param {DateTime} dateTime the DateTime for what the current calendar should be calculated
 * @param {string} locale de-De, en-US, ...
 * @param {DatePickerWeekdayEnum | undefined} weekStart when a week start is given the locale is ignored for start of week
 * @param {number} weekCount number of weeks to show. defaults to 6
 * @returns {DateTime[]} returns an array of DateTimes
 */
export function getCalendarDaysForSelectedMonth(
  dateTime: DateTime,
  locale: string,
  weekStart?: DatePickerWeekdayEnum,
  weekCount = 6
): DateTime[] {
  const startOfWeek = weekStart ? datePickerWeekStartMap.get(weekStart) : getWeekStart(locale);
  const offset = getFirstWeekOffset(dateTime.startOf('month').weekday, startOfWeek);

  return [...Array(7 * weekCount)].map((_, index) => dateTime.plus({ days: index - offset }));
}

/**
 * Get count of previous month dates for first week.
 *
 * @param weekday weekday 1 (Monday), 2 (Tuesday), 3, ...
 * @param startOfWeek 0 (Sunday), 1 (Monday), 2, ...
 * @returns number
 */
export function getFirstWeekOffset(weekday: number, startOfWeek: number): number {
  return weekday >= startOfWeek ? (weekday - startOfWeek) % 7 : 7 - (startOfWeek - weekday);
}

/**
 * This function returns an array of DateTimes. With the default length of 16 these are twelve months of the current year and four of the next year.
 *
 * @param dateTime the DateTime for what the current month calendar should be calculated
 * @param length optional count of DateTimes that should be returned, defaults to 16 (4 x 4)
 * @returns {DateTime[]} returns an array of DateTimes
 */
export function getCalendarMonthsForSelectedYear(dateTime: DateTime, length = 16): DateTime[] {
  if (!dateTime) {
    return [];
  }

  const startOfYear = dateTime.startOf('year');

  return Array.from({ length }).map((_, index) => startOfYear.plus({ months: index }));
}

/**
 * This function returns an array of DateTimes. With the default length of 16 these are ten of the current decade and 6 of the next.
 *
 * @param dateTime the DateTime for what the current decade calendar should be calculated
 * @param length optional count of DateTimes that should be returned, defaults to 16 (4 x 4)
 * @returns {DateTime[]} returns an array of DateTimes
 */
export function getCalendarYearsForSelectedDecade(dateTime: DateTime, length = 16): DateTime[] {
  const startOfDecade = dateTime.startOf('year').minus({ years: dateTime.year % 10 });

  return Array.from({ length }).map((_, index) => startOfDecade.plus({ years: index }));
}

/**
 * Get the DateTime of the start of month dependent on the given year, month
 *
 * @param {number} year 2021
 * @param {number} month 6
 * @returns {DateTime} returns the start of month as a DateTime
 */
export function getStartOfMonth(year: number, month: number): DateTime {
  return DateTime.fromObject({ year, month }).startOf('month');
}

/**
 * Get the DateTime of the end of month dependent on the given year, month
 *
 * @param {number} year 2021
 * @param {number} month 6
 * @returns {DateTime} returns the end of month as a DateTime
 */
export function getEndOfMonth(year: number, month: number): DateTime {
  return DateTime.fromObject({ year, month }).endOf('month');
}

/**
 * Get the DateTime of the previous month dependent on the given year, month
 *
 * @param {number} year 2021
 * @param {number} month 6
 * @returns {DateTime} returns the end of the previous month as a DateTime
 */
export function getPreviousMonthStart(year: number, month: number): DateTime {
  return getStartOfMonth(year, month).plus({ days: -1 }).startOf('month');
}

/**
 * Get the DateTime of the next month dependent on the given year, month
 *
 * @param {number} year 2021
 * @param {number} month 6
 * @returns {DateTime} returns the start of the next month as a DateTime
 */
export function getNextMonthStart(year: number, month: number): DateTime {
  return getEndOfMonth(year, month).startOf('day').plus({ days: 1 });
}

/**
 * Get the month name dependent on the given year, month and locale
 *
 * @param {number} year 2021
 * @param {number} month 6
 * @param {string} locale de-De, en-Us, ...
 * @param {UnitLength} length
 * @returns {string} returns a full month name as a string
 */
export function getStartOfMonthName(year: number, month: number, locale: string, length: UnitLength = 'long'): string {
  const startOfMonth = getStartOfMonth(year, month);
  const months = Info.months(length, { locale });

  return months[startOfMonth.month - 1];
}

/**
 * Transform JsDates to DateTimes
 *
 * @param {Date[]} dates js dates
 * @returns {DateTime[]} returns the JsDates as DateTimes
 */
export function getDateTimesFromJsDates(dates: Date[]): DateTime[] {
  return dates.map((date) => jsDateToDateTime(date));
}

/**
 * Check whether a given DateTime day is found in an array of DateTimes or not
 *
 * @param {DateTime} dateTime the DateTime that is checked
 * @param {DateTime[]} dates the DateTimes to look in
 * @returns {boolean} returns either true or false when the DateTime day is found or not
 */
export function someIsSameDay(dateTime: DateTime, dates: DateTime[]): boolean {
  return dates.some((disabledDate) => isSameDay(disabledDate, dateTime));
}

/**
 * Check whether a given DateTime month is found in an array of DateTimes or not
 *
 * @param {DateTime} dateTime the DateTime that is checked
 * @param {DateTime[]} dates the DateTimes to look in
 * @returns {boolean} returns either true or false when the DateTime month is found or not
 */
export function someIsSameMonth(dateTime: DateTime, dates: DateTime[]): boolean {
  return dates.some((disabledDate) => isSameMonth(disabledDate, dateTime));
}

/**
 * Check whether a given DateTime year is found in an array of DateTimes or not
 *
 * @param {DateTime} dateTime the DateTime that is checked
 * @param {DateTime[]} dates the DateTimes to look in
 * @returns {boolean} returns either true or false when the DateTime year is found or not
 */
export function someIsSameYear(dateTime: DateTime, dates: DateTime[]): boolean {
  return dates.some((disabledDate) => isSameYear(disabledDate, dateTime));
}

/**
 * Check whether a given DateTime weekday is in an array of weekdays
 *
 * @param {DateTime} dateTime the DateTime the weekday is checked against
 * @param {DatePickerWeekdayEnum[]} weekdays Mo, Tu, ... Monday, Tuesday, ...
 * @returns {boolean} returns whether the weekday is included or not
 */
export function hasWeekday(dateTime: DateTime, weekdays: DatePickerWeekdayEnum[]): boolean {
  const dateTimeWeekdayShort = datePickerWeekday[dateTime.setLocale('en-US').weekdayShort];
  const dateTimeWeekdayLong = datePickerWeekday[dateTime.setLocale('en-US').weekdayLong];

  return weekdays.includes(dateTimeWeekdayShort) || weekdays.includes(dateTimeWeekdayLong);
}

/**
 * Check whether a date is inside a range of a decade
 *
 * @param dateOne the date to check
 * @param dateTwo the date that is transformed to interval and checked against
 * @returns {boolean} returns whether the date is in between the decade date years
 */
export function isInDecade(dateOne: DateTime, dateTwo: DateTime): boolean {
  const startOfDecade = dateTwo.startOf('year');
  const endOfDecade = dateTwo.startOf('year').plus({ years: 10 });
  const interval = Interval.fromDateTimes(startOfDecade, endOfDecade);

  return interval.contains(dateOne);
}

/**
 * Compare two DateTimes and return true if the years, months and days matches.
 *
 * @param {DateTime} dateTimeOne first DateTime
 * @param {DateTime} dateTimeTwo second DateTime
 * @returns {boolean} returns either true or false
 */
export function isSameDay(dateTimeOne: DateTime, dateTimeTwo: DateTime): boolean {
  return dateTimeOne && dateTimeTwo && dateTimeTwo.hasSame(dateTimeOne, 'day');
}

/**
 * Compare two DateTimes and return true if the years and months matches.
 *
 * @param {DateTime} dateTimeOne first DateTime
 * @param {DateTime} dateTimeTwo second DateTime
 * @returns {boolean} returns either true or false
 */
export function isSameMonth(dateTimeOne: DateTime, dateTimeTwo: DateTime): boolean {
  return dateTimeOne && dateTimeTwo && dateTimeTwo.hasSame(dateTimeOne, 'month');
}

/**
 * Compare two DateTimes and return true if the years matches.
 *
 * @param {DateTime} dateTimeOne first DateTime
 * @param {DateTime} dateTimeTwo second DateTime
 * @returns {boolean} returns either true or false
 */
export function isSameYear(dateTimeOne: DateTime, dateTimeTwo: DateTime): boolean {
  return dateTimeOne && dateTimeTwo && dateTimeTwo.hasSame(dateTimeOne, 'year');
}

export const isJsDate = (value: unknown): value is Date => value instanceof Date && !isNaN(value.getTime());

export const daysOfWeekConverter = {
  fromAttribute: (value: string): DatePickerWeekdayEnum[] => {
    const weekdays = value.length > 0 ? value.split(',') : [];

    return weekdays.filter((weekday) =>
      Object.values(DatePickerWeekdayEnum).includes(weekday as DatePickerWeekdayEnum)
    ) as DatePickerWeekdayEnum[];
  },
  toAttribute: (value: DatePickerWeekdayEnum[]): string => {
    return value.map((datePickerWeekday) => DatePickerWeekdayEnum[datePickerWeekday]).join(',');
  },
};

export const isoDateConverter = {
  fromAttribute: (value: string): Date | undefined => {
    if (!value) {
      return undefined;
    }

    const newDate = new Date(value);

    return isJsDate(newDate) ? newDate : undefined;
  },
  toAttribute: (value: Date | null | undefined): string | null => (isJsDate(value) ? value.toISOString() : null),
};

export const literalConverter = {
  fromAttribute: (value = ''): string[] => {
    if (!value) {
      return [];
    }

    return value.split(',').map((part) => part.trim());
  },
  toAttribute: (value: string[]): string => {
    if (!value) {
      return '';
    }

    return value.join(',');
  },
};

/**
 * Transforms a JS date to a luxon DateTime.
 *
 * @param date the Date object
 *
 * @returns a DateTime object or <code>undefined</code> if param is falsy
 */
export const jsDateToDateTime = (date: Date | undefined | null): DateTime =>
  date ? DateTime.fromJSDate(date) : undefined;

/**
 * Transforms a luxon DateTime to a JS date.
 *
 * @param dateTime the DateTime object
 *
 * @returns a Date object or <code>undefined</code> if param is falsy
 */
export const dateTimeToJsDate = (dateTime: DateTime | undefined | null): Date =>
  dateTime ? dateTime.toJSDate() : undefined;
