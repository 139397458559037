/**
 * @deprecated
 * zui-ewiq-dialog / EwiqDialog is the current used component
 */
import {
  CSSResultArray,
  TemplateResult,
  customElement,
  html,
  property,
  internalProperty,
  unsafeCSS,
  css,
} from 'lit-element';
import { BaseElement } from '../base/BaseElement';
import { event } from '../../decorators/event.decorator';
import style from './ewi-dialog-light.component.scss';

const ewiDialogLightStyles = css`
  ${unsafeCSS(style)}
`;

const RESIZE_WIDTH = 800;

type PrimaryButtonType = 'accept' | 'cancel';
type State = 'error' | 'warning' | 'info' | 'question' | 'check';
type ConfirmPosition = 'left' | 'right' | 'auto';

/**
 * The zui-ewi-dialog-light is light version of the zui-ewi-dialog which will be added later to the Zeiss UI Library.
 * It differs from the dialog component by having a top border which changes its color depending on its state.
 * Also it calculates its hight based on its content.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13603%3A181749)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=39755%3A336102)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-ewi-dialog-light state='info' accept-label='Accept'>
 * 		<div style="background-color:#FF68C7;width:100px;height:100px;opacity:0.1"></div>
 * </zui-ewi-dialog-light>
 * ```
 *
 * @fires accept - The event which gets fired when the accept button gets clicked
 * @fires cancel - The event which gets fired when the cancel button gets clicked
 * @slot default - This is the default slot. It's an innerHtml of the zui-ewi-dialog-light element
 */
@customElement('zui-ewi-dialog-light')
export class EwiDialogLight extends BaseElement {
  static get styles(): CSSResultArray {
    return [ewiDialogLightStyles];
  }

  /**
   * State of the ewi dialog light, it determinates which color the top border of this component has.
   * The default value is 'info' which colors the top border blue
   */
  @property({ reflect: true, type: String })
  state: State = 'info';

  /**
   * This disables the ewi dialog light
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * This sets the text of the accept Label
   */
  @property({ reflect: true, type: String, attribute: 'accept-label' })
  acceptLabel = 'OK';

  /**
   * This sets the text of the label for the accept button
   */
  @property({ reflect: true, type: String, attribute: 'cancel-label' })
  cancelLabel = 'Cancel';

  /**
   * This defines which button will be the primary button
   */
  @property({ reflect: true, type: String, attribute: 'primary-button' })
  primaryButton: PrimaryButtonType = 'accept';

  /**
   * This disables the accept button
   */
  @property({ reflect: true, type: Boolean, attribute: 'disable-accept' })
  disableAccept = false;

  /**
   * This disables the cancel button
   */
  @property({ reflect: true, type: Boolean, attribute: 'disable-cancel' })
  disableCancel = false;

  /**
   * This sets the position of the primary button
   */
  @property({ reflect: true, type: String, attribute: 'confirm-position' })
  confirmPosition: ConfirmPosition = 'auto';

  /**
   * emits an accept Event
   *
   * @param {EwiDialogLight} detail is the payload
   * @private
   */
  @event({
    eventName: 'accept',
    bubbles: true,
    composed: true,
  })
  emitAcceptEvent(detail: this): void {
    this.dispatchEvent(
      new CustomEvent('accept', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  /**
   * emits a cancel Event
   *
   * @param {EwiDialogLight} detail is the payload
   * @private
   */
  @event({
    eventName: 'cancel',
    bubbles: true,
    composed: true,
  })
  emitCancelEvent(detail: this): void {
    this.dispatchEvent(
      new CustomEvent('cancel', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  /**
   * This internal property is used to signal that the 'button-border-left' attribute
   * should be set. This leads to the styling that buttons will have a fixed size and on the left side
   * of the buttons there will be a visual space so that the buttons will be right-aligned.
   */
  @internalProperty()
  private _buttonBorderLeft = false;

  /**
   * see widthObserver in zui-dialogbox
   */
  private _widthObserver: ResizeObserver = new ResizeObserver(([entry]) => {
    // If the width is above the threshold, a flag will be set that triggers a rendering and will
    // the styling will be changed.
    // This change will also change the height of the component, which will again trigger the resize-observer.
    // This is no problem but in some rare conditions (especially in testing it's possible that both
    // observer invocations happen within a single browser rendering frame.
    // In this case, chrome would throw an exception (ResizeObserver loop limit exceeded).
    // To prevent this, we delay the operation to the next animation frame.
    requestAnimationFrame(() => {
      const buttonContainerWidth: number = entry.contentRect.width;

      this._buttonBorderLeft = buttonContainerWidth > RESIZE_WIDTH;
    });
  });

  /**
   * disconnects the resizeObserver of the button container
   */
  disconnectedCallback(): void {
    this._widthObserver.disconnect();
    super.disconnectedCallback();
  }

  /**
   * Handler for the click event on the accept button, fires a accept event.
   */
  private _handleAcceptButtonClick(): void {
    this.emitAcceptEvent(this);
  }

  /**
   * Handler for the click event on the cancel button, fires a cancel event.
   */
  private _handleCancelButtonClick(): void {
    this.emitCancelEvent(this);
  }

  /**
   * Detects operating system and adds an observer for the width of the button-container
   */
  protected firstUpdated(changedProperties: Map<string, string | number | symbol>): void {
    super.firstUpdated(changedProperties);
    this._buttonBorderLeft = this.getBoundingClientRect().width > RESIZE_WIDTH;
    this._widthObserver.observe(this);
  }

  protected render(): TemplateResult | void {
    return html` <section id="dialog" ?button-border-left=${this._buttonBorderLeft}>
      <div id="top-border"></div>
      <section id="placeholder">
        <slot id="content"></slot>
      </section>
      <footer id="footer-container">
        <span id="front-divider"></span>
        <div id="button-container">
          <button id="accept-button" @click="${this._handleAcceptButtonClick}">${this.acceptLabel}</button>
          <span id="divider"></span>
          <button id="cancel-button" @click="${this._handleCancelButtonClick}">${this.cancelLabel}</button>
        </div>
      </footer>
    </section>`;
  }
}
