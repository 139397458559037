import {
  css,
  customElement,
  html,
  property,
  PropertyValues,
  queryAssignedNodes,
  TemplateResult,
  unsafeCSS,
} from 'lit-element';
import type { Placement } from '@popperjs/core/lib/enums';
import type { EventWithTarget } from '../../../types';
import type { Scrollable } from '../../scrollable/scrollable.component';

import { traverseDOMSiblingsByStepAndDirection } from '../../../utils/dom.utils';
import { BaseElement } from '../../base/BaseElement';
import { MenuItem } from '../menu-item/menu-item.component';

import { hostStyles } from '../../../host.styles';
import style from './menu.component.scss';

type Emphasis = 'default' | 'active' | 'active-primary';
type Overflow = 'truncate' | 'scroll';
type Size = 's' | 'l';

const MENU_STYLES = css`
  ${unsafeCSS(style)}
`;

/**
 * This UI component visualizes the menu used e.g. by a select component. It will be positioned from the outside and
 * will be given its items through the default slot as well, leaving it with being responsible for looking pretty and
 * reflecting disabled states, item sizes and the amount visible before being cut off. It's used internally by the
 * `zui-select` feature component to group available menu items and dividers.
 *
 * ## Functionality
 * If disabled, **all nested items will be disabled as well**. Also, the given size will be applied to them.
 * The count property allows defining the amount of visible items before being cut off from the scrollable overflow. As
 * it only sets a custom CSS property internally, `--zui-menu-item-count` can be used from the outside as well.
 *
 * ## Width
 * The default width of the menu _and its items_ is `100%`, so it always scales to the width of its layout container.
 * Alternatively a fixed width can be given as [CSS length](https://developer.mozilla.org/en-US/docs/Web/CSS/length).
 * If the `adapt-width` property is set, this will be ignored, as the menu scales to its widest item width.
 *
 * @example
 * ```HTML
 * <zui-menu count="3">
 *   <zui-menu-item>item</zui-menu-item>
 * </zui-menu>
 * ```
 *
 * ```HTML
 * <zui-menu style="--zui-menu-width: 220px">
 *   <zui-menu-item>Maybe I will be cut off as I'm way too wide to fit into the menus 220px boundary :)</zui-menu-item>
 * </zui-menu>
 * ```
 *
 * ```HTML
 * <zui-menu adapt-width>
 *   <zui-menu-item>On the other hand, I'll be growing my menu container 💪</zui-menu-item>
 * </zui-menu>
 * ```
 *
 * @slot - default slot for projecting menu items and dividers
 * @cssprop --zui-menu-background - color of the menu background, derived from emphasis attribute by default
 * @cssprop --zui-menu-border-color - sets the border color of the menu
 * @cssprop --zui-menu-border-radius - sets the corner radius of the menu
 * @cssprop --zui-menu-item-count - maximum item count visible, is set automatically
 * @cssprop --zui-menu-margin-top - sets the top offset of the menu
 * @cssprop --zui-menu-width - allows setting explicit sizes, defaults to 100%
 */
@customElement('zui-menu')
export class Menu extends BaseElement {
  static readonly styles = [hostStyles, MENU_STYLES];

  /**
   * the tabindex of the menu;
   * defaults to 0 allowing keyboard navigation with cursor keys
   */
  @property({ reflect: true, type: String })
  tabindex = 0;

  /**
   * ARIA activedescendant for this element; defaults to ''
   */
  @property({ reflect: true, attribute: 'aria-activedescendant' })
  ariaActiveDescendant = '';

  /**
   * enforces the "role" attribute for a11y reasons
   */
  @property({ reflect: true, type: String })
  role = 'menu';

  /**
   * amount of visible items before being cut off
   */
  @property({ reflect: true, type: Number })
  count = 9.5;

  /**
   * sets the menu _and all items_ disabled
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * disables truncation and scales the menu to its items
   */
  @property({ reflect: true, type: Boolean, attribute: 'adapt-width' })
  adaptWidth = false;

  /**
   * sets an emphasis for the menu, **not the nested items**
   */
  @property({ reflect: true, type: String })
  emphasis: Emphasis = 'default';

  /**
   * defines the overflow strategy of the menu items if the width is not
   * sufficient to either be truncated with three dots or to be scrollable
   */
  @property({ reflect: true, type: String })
  overflow: Overflow = 'truncate';

  /**
   * affects the animation and drop shadow placement
   */
  @property({ reflect: true, type: String })
  placement: Placement = 'bottom-start';

  /**
   * defines the size _for all items_ as well
   */
  @property({ reflect: true, type: String })
  size: Size = this.hasTouch ? 'l' : 's';

  /**
   * allows customizing the scrollbar background settings
   */
  @property({ reflect: true, type: String, attribute: 'scrollable-background' })
  scrollableBackground: Scrollable['background'] = 'hidden';

  /**
   * allows customizing the scrollbar hitarea settings
   */
  @property({ reflect: true, type: String, attribute: 'scrollable-hitarea' })
  scrollableHitarea: Scrollable['hitarea'] = 'minimal';

  @queryAssignedNodes(undefined, true, 'zui-menu-item')
  private readonly _itemRefs: NodeListOf<MenuItem> | null;

  /**
   * most of the time the host gets focused from the outside, which should
   * always result in a manual focus delegation to the first menu item;
   * nevertheless, to blur an item, we have to focus the host again to
   * allow focus detection within portals - thus, we have to skip the
   * delegation once for this edge case
   */
  private _skipNextFocusDelegation = false;

  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('focus', this._handleFocus);
    this.addEventListener('keydown', this._handleKeys);
  }

  disconnectedCallback(): void {
    this.removeEventListener('focus', this._handleFocus);
    this.removeEventListener('keydown', this._handleKeys);
    super.disconnectedCallback();
  }

  private _handleSlotChange(): void {
    this._propagateProps();
  }

  private _propagateProps(): void {
    this._itemRefs?.forEach((itemRef, index) => {
      // menu[disabled] beats menuItem
      if (this.disabled) {
        itemRef.disabled = true;
      }
      // in order to have a width to adapt from,
      // the items need to expand properly
      itemRef.disableTruncation = this.adaptWidth || this.overflow === 'scroll';
      // override some defaults
      itemRef.size = this.size;
      itemRef.id = `menu-${index}`;
    });
  }

  private _handleFocus(): void {
    // delegate focus to first menuItem
    // TODO: use generic focus mechanism
    if (!this.disabled && this._itemRefs?.length > 0 && !this._skipNextFocusDelegation) {
      this._setActiveMenuItem(this._itemRefs[0]);
      this._skipNextFocusDelegation = false;
    }
  }

  private _handleKeys({ target: currentMenuItem, code }: EventWithTarget<MenuItem, KeyboardEvent>): void {
    let itemToFocus: MenuItem;
    switch (code) {
      case 'ArrowUp':
        itemToFocus = this._getPreviousMenuItem(currentMenuItem);
        break;
      case 'ArrowDown':
        itemToFocus = this._getNextMenuItem(currentMenuItem);
        break;
    }
    itemToFocus = itemToFocus || currentMenuItem;
    this._setActiveMenuItem(itemToFocus);
  }

  private _getPreviousMenuItem(from: MenuItem): MenuItem {
    return traverseDOMSiblingsByStepAndDirection(
      from,
      'previous',
      1,
      (element) => element instanceof MenuItem && !element.hasAttribute('disabled')
    ) as MenuItem;
  }

  private _getNextMenuItem(from: MenuItem): MenuItem {
    return traverseDOMSiblingsByStepAndDirection(
      from,
      'next',
      1,
      (element) => element instanceof MenuItem && !element.hasAttribute('disabled')
    ) as MenuItem;
  }

  private _setActiveMenuItem(menuItem: MenuItem | undefined | null): void {
    if (menuItem) {
      this.ariaActiveDescendant = menuItem.id;
      menuItem.focus();
    }
  }

  private _handleMouseOver(event: MouseEvent): void {
    if (event.target instanceof MenuItem && !event.target.disabled) {
      this._setActiveMenuItem(event.target);
    }
  }

  private _handleMouseOut(event: MouseEvent): void {
    this.ariaActiveDescendant = '';
    if (event.target instanceof MenuItem && !event.target.disabled) {
      this._skipNextFocusDelegation = true;
      this.focus();
    }
  }

  protected updated(changedProperties: PropertyValues): void {
    super.updated(changedProperties);
    if (
      changedProperties.has('disabled') ||
      changedProperties.has('size') ||
      changedProperties.has('adaptWidth') ||
      changedProperties.has('overflow')
    ) {
      this._propagateProps();
    }
    if (changedProperties.has('count')) {
      this.style.setProperty('--zui-menu-item-count', this.count.toString());
    }
  }

  protected render(): TemplateResult {
    return html` <zui-scrollable
      background="${this.scrollableBackground}"
      hitarea="${this.scrollableHitarea}"
      @mouseover=${this._handleMouseOver}
      @mouseout=${this._handleMouseOut}
    >
      <slot @slotchange="${this._handleSlotChange}"></slot>
    </zui-scrollable>`;
  }
}
