import { Decimal } from 'decimal.js';

type Step = number | 'any';

/**
 * calculates the rest of a division with regard of rounding errors
 *
 * @param range that is to be tested against
 * @param interval the interval to be tested
 * @returns the modulo
 */
export function getModuloRest(range: number, interval: number): number {
  return ((range * 100) % (interval * 100)) / 100;
}

/**
 * calculates the needed offset for the given slider with range and interval
 *
 * @param range the range of the slider
 * @param interval that the offset should be calculated for
 * @returns calculated offset
 */
export function getOffsetForSliderByInterval(range: number, interval: number): number {
  // the total offset is a relation between the modulo minus rounding errors
  const offset = (1000 / range) * (getModuloRest(range, interval) / 10);

  return getModuloRest(range, interval) === 0 ? 0 : offset - offset / 200;
}

/**
 * return next slider value
 *
 * @param min slider min value
 * @param max sider max value
 * @param step slider step value
 * @param value slider value
 *
 * @returns { number } next step
 */
export function getNextValidSliderValue(min: number, max: number, step: number, value: number): number {
  return [...new Array(new Decimal(max).minus(min).dividedBy(step).plus(1).toNumber())]
    .map((_, i) => new Decimal(i).mul(step).plus(min).toNumber())
    .reduce((prev, curr) => (Decimal.abs(curr - value).lessThan(Decimal.abs(prev - value)) ? curr : prev), Infinity);
}

/**
 * set step to 'any' or number
 */
export const stepConverter = {
  fromAttribute: (value: number | string): Step => (isNaN(+value) ? 'any' : +value),
  toAttribute: (value: Step): number | string => value,
};

/**
 * @param min slider min value
 * @param max slider max value
 * @param step slider step size
 *
 * @returns { boolean } whether it is a valid step dependent on min and max values
 */
export function isValidStep(min: number, max: number, step: number): boolean {
  return Decimal.mod(new Decimal(max).minus(min), new Decimal(step)).equals(0);
}

/**
 * @param min slider min value
 * @param value value to test
 * @param step slider step size
 *
 * @returns { boolean } whether it is a valid value dependent on min and step values
 */
export function isValidValue(min: number, value: number, step: number): boolean {
  return Decimal.mod(new Decimal(value).minus(min), new Decimal(step)).equals(0);
}
