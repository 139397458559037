import { css, customElement, html, property, PropertyValues, query, TemplateResult, unsafeCSS } from 'lit-element';
import { hostStyles } from '../../host.styles';
import { BaseElement } from '../base/BaseElement';
import style from './tooltip.component.scss';

export const TOOLTIP_STYLES = css`
  ${unsafeCSS(style)}
`;

/**
 * The tooltip is a common graphical user interface element.
 * It is used in conjunction with a cursor, usually a pointer.
 * The user hovers the pointer over an item, without clicking it,
 * and a tooltip may appear — a small "hover box" with information about the item being hovered over.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13009%3A2751)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=23852%3A34)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-tooltip></zui-tooltip>
 * <zui-tooltip emphasis="warning"></zui-tooltip>
 * ```
 *
 * @slot - This is the default slot. It's an innerHtml of the tooltip-element. <br/>It can contain either text:<br/>\<p\>, \<span\>, \<a\>, \<i\>, \<b\>, \<strong\>, \<code\>, \<pre\> <br/>or non-text content.
 *
 * @cssprop --zui-tooltip-max-width - as the tooltip grows with its contents, this defines the horizontal limit
 * @cssprop --zui-tooltip-padding - inner spacing of the tooltip container
 */
@customElement('zui-tooltip')
export class Tooltip extends BaseElement {
  static readonly styles = [hostStyles, TOOLTIP_STYLES];

  /**
   * defines one of two possible emphasis states (default/warning);
   */
  @property({ reflect: true, type: String })
  emphasis: 'default' | 'warning' = 'default';

  /**
   * allows defining a text message body (which would be overwritten by any slotted content)
   */
  @property({ reflect: true, type: String })
  message = '';

  /**
   * flags non-text content for styling
   *
   * @private
   */
  @property({ reflect: true, type: Boolean, attribute: 'zui-internal-no-text' })
  isNotText = false;

  @query('slot')
  private _slotRef: HTMLSlotElement | null;

  // detect non-text content
  private _checkSlotContent(): void {
    this.isNotText = this._slotRef.assignedElements().length > 0;
  }

  // run initial tasks
  protected firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);
    this._checkSlotContent();
  }

  protected render(): TemplateResult {
    return html` <slot @slotchange="${this._checkSlotContent}">${this.message}</slot> `;
  }
}
