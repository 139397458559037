import { CSSResultArray, TemplateResult, customElement, html, property, query, css, unsafeCSS } from 'lit-element';
import { hostStyles } from '../../../host.styles';
import { FormElement } from '../../base/FormElement';
import { ifDefined } from 'lit-html/directives/if-defined';
import { event } from '../../../decorators/event.decorator';
import { getHostElementFromChild } from '../../../utils/mixin.utils';
import { RadioButtonGroup } from '../radio-button-group/radio-button-group.component';
import style from './radio-button.component.scss';

const radioButtonStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * Radio buttons allow the user to select only one of a set.
 * The component has to be placed in a radio button group to create the standard radio button behaviour, of only one selected button in a group.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13009%3A2721)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=23637%3A483839)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-radio-button name="size" label="Small" value="small"></zui-radio-button>
 * ```
 *
 * @fires change - The event that fires when 'checked' is changed to true by clicking the radio button
 * @fires input - The event that fires when 'checked' is changed to true by clicking the radio button
 * @fires checked-change - the event that fires when checked changed
 * @slot default - This is the default slot. It's an innerHtml of the radio-button-element
 */
@customElement('zui-radio-button')
export class RadioButton extends FormElement {
  static get styles(): CSSResultArray {
    return [hostStyles, radioButtonStyles];
  }
  /**
   * The label of the radio button
   */
  @property({ reflect: true })
  label = '';

  /**
   * This checks the radio button
   *
   * @default false
   */
  @property({ reflect: true, type: Boolean })
  get checked(): boolean {
    return this._checked;
  }

  set checked(checked: boolean) {
    if (!this.disabled) {
      const oldChecked = this._checked;
      this._checked = checked;
      this.requestUpdate('checked', oldChecked).then(() => {
        if (oldChecked === this._checked) {
          return;
        }
      });
    }
  }

  /**
   * Value of the radio button
   *
   */
  @property({
    reflect: true,
    converter: {
      fromAttribute: (value): string => (value === null ? 'on' : value),
      toAttribute: (value): string => String(value),
    },
  })
  get value(): string {
    return super.value;
  }

  set value(value: string) {
    super.value = value;
  }

  /**
   * emits a checked-change event
   * @private
   */
  @event({
    eventName: 'checked-change',
    bubbles: true,
  })
  emitCheckedChangeEvent(): void {
    this.dispatchEvent(
      new CustomEvent('checked-change', {
        bubbles: true,
        composed: false,
        detail: { checked: this.checked },
      })
    );
  }

  /**
   * emits an input Event
   * @private
   */
  @event({
    eventName: 'input',
    bubbles: true,
    composed: false,
  })
  emitInputEvent(): void {
    this.dispatchEvent(
      new Event('input', {
        bubbles: true,
        composed: false,
      })
    );
  }

  /**
   * emits a change Event
   * @private
   */
  @event({
    eventName: 'change',
    bubbles: true,
    composed: true,
  })
  emitChangeEvent(): void {
    this.dispatchEvent(
      new Event('change', {
        bubbles: true,
        composed: true,
      })
    );
  }

  /**
   * Selects the defaultslot element
   */
  @query('#defaultslot')
  private _slotDefault: HTMLSlotElement;

  private _checked = false;

  constructor() {
    super();
    this.value = 'on';
  }

  formResetCallback(): void {
    if (!this._hasRadioButtonGroupAsParent()) {
      // TODO hook resets the radio button value, which is probably not intended behaviour.
      super.formResetCallback();
      this.checked = false;
    }
  }

  /**
   * updates the value of the radio button
   */
  private _updateValue(): void {
    if (!this.disabled) {
      const oldValue = this.checked;
      this.checked = true;
      if (!oldValue && this.checked) {
        this.emitInputEvent();
        this.emitChangeEvent();
        this.emitCheckedChangeEvent();
      }
    }
  }

  /**
   * Handler for the click event. Once the user clicks on the
   * radio button but not when checked was set programmatically
   * the handler is invoked.
   *
   * @param {Event} clickEvent onclick event
   */
  private _handleClick(clickEvent: Event): void {
    clickEvent.preventDefault();
    this._updateValue();
  }

  /**
   * Set css classes based on content of the slots or removes them if slots change
   */
  private _slotChangeHandler(): void {
    const slotContainer = this.shadowRoot.querySelector('#slot-container');
    if (this._slotDefault.assignedNodes().length > 0 || (this.label !== '' && this.label)) {
      slotContainer.setAttribute('used', '');
    } else {
      slotContainer.removeAttribute('used');
    }
  }

  /**
   * Checks if the radio button has a radio button group as parent.
   *
   * @returns {boolean}
   */
  private _hasRadioButtonGroupAsParent(): boolean {
    return getHostElementFromChild(this) instanceof RadioButtonGroup;
  }

  /**
   * Registers slotchange listener and sets css classes based on slot content
   */
  protected firstUpdated(changedProperties: Map<string, string | number | symbol>): void {
    super.firstUpdated(changedProperties);
    this._slotChangeHandler();
    this._slotDefault.addEventListener('slotchange', this._slotChangeHandler.bind(this));
    this.addEventListener('click', this._handleClick.bind(this));
  }

  protected render(): TemplateResult | void {
    return html`
      <label
        class="${this.hasTouch ? 'touch' : ''} radio-button-and-label-wrapper"
        ?checked="${this.checked}"
        ?disabled=${this.disabled}
      >
        <input
          type="radio"
          .value="${this.value}"
          name="${ifDefined(this.name)}"
          ?disabled=${this.disabled}
          ?checked=${this.checked}
        />
        <div class="radio-button"></div>
        <div id="slot-container">
          <slot id="defaultslot">${this.label ? html`${this.label}` : null}</slot>
        </div>
      </label>
    `;
  }
}
