import {
  CSSResultArray,
  TemplateResult,
  customElement,
  html,
  query,
  internalProperty,
  css,
  unsafeCSS,
} from 'lit-element';
import { hostStyles } from '../../../host.styles';
import { FormElement } from '../../base/FormElement';
import { RadioButton } from '../radio-button/radio-button.component';
import { event } from '../../../decorators/event.decorator';
import { EventWithTarget } from '../../../types';
import style from './radio-button-group.component.scss';

const radioButtonGroupStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * The radioButtonGroup is a component to encapsulate multiple radio buttons.
 * Only one of this radio buttons can be chaecked at a given time.
 * If a new radio button gets checked the other checked radio button gets unchecked.
 * It is possible to reset the radio button group to it's default value, by calling the function reset(); on it.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-radio-button-group name='size' value='small'>
 * 		<zui-radio-button name='size' label='Small' value='small' checked></zui-radio-button>
 * 		<zui-radio-button name='size' label='Medium' value='medium'></zui-radio-button>
 * 		<zui-radio-button name='size' label='Large' value='large'></zui-radio-button>
 * </zui-radio-button-group>
 * ```
 *
 * @fires changed - The event that fires when the value of the radio button group gets changed
 * @fires input - Simulates the default `input` event to imitate default behavior
 * @fires blur - Simulates the default `blur` event to imitate default behavior
 * @slot default - In this slot should the radio buttons of the radio button group be placed.
 * No other type of HTML elements should be placed inside.
 */
@customElement('zui-radio-button-group')
export class RadioButtonGroup extends FormElement {
  static get styles(): CSSResultArray {
    return [hostStyles, radioButtonGroupStyles];
  }
  /**
   * Emits a custom changed event with the internal value of the radio button group as detail
   * @private
   */
  @event({
    eventName: 'changed',
    bubbles: true,
    composed: false,
  })
  emitChangedEvent(): void {
    this.dispatchEvent(
      new CustomEvent('changed', {
        bubbles: true,
        composed: false,
        detail: this._internalValue,
      })
    );
  }
  /**
   * emits an input Event
   */
  @event({
    eventName: 'input',
    bubbles: true,
    composed: false,
  })
  emitInputEvent(): void {
    this.dispatchEvent(
      new Event('input', {
        bubbles: true,
        composed: false,
      })
    );
  }

  /**
   * @private
   */
  @event({
    eventName: 'blur',
    // use values from blur event of HTML <input type=text>.
    bubbles: true,
    cancelable: false,
    composed: false,
  })
  emitBlurEvent(): void {
    this.dispatchEvent(
      new FocusEvent('blur', {
        bubbles: true,
        cancelable: false,
        composed: false,
      })
    );
  }

  @query('#radio-button-slot')
  private _radioButtonSlot: HTMLSlotElement;

  /**
   * Has the value of the default radioButton
   */
  @internalProperty()
  private _defaultButtonValue: string | undefined = undefined;

  /**
   * Internal Value of the Radio Button Group
   */
  @internalProperty()
  private _internalValue: string | undefined = undefined;

  /**
   * Overwrites Form Elements onValueChanged function
   */
  onValueChanged(): void {
    this._setInternalValue(this.value);
  }

  /**
   * Overwrites formDisabledCallback to set disabled on all radio buttons
   *
   * @param {boolean} disabled the new value for disabled
   */
  formDisabledCallback(disabled: boolean): void {
    this._radioButtonSlot?.assignedNodes().forEach((node) => {
      if (node instanceof RadioButton) {
        if (disabled) {
          node.setAttribute('disabled', '');
        } else {
          node.removeAttribute('disabled');
        }
      }
    });
  }

  /**
   * Resets the value of the radio button group to the default one.
   *
   *
   */
  reset(): void {
    this._setInternalValue(this._defaultButtonValue);
  }

  formResetCallback(): void {
    this.reset();
  }

  connectedCallback(): void {
    super.connectedCallback();
    // todo: this should be removed when a reusable solution has been implemented
    // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
    window.addEventListener('click', this._handleOutsideClick);
  }
  disconnectedCallback(): void {
    // todo: this should be removed when a reusable solution has been implemented
    // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
    window.removeEventListener('click', this._handleOutsideClick);
    super.disconnectedCallback();
  }

  // todo: this should be removed when a reusable solution has been implemented
  // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
  private _handleOutsideClick = (event: EventWithTarget<RadioButtonGroup | FormElement>): void => {
    const isInsideClick =
      this.isSameNode(event.target) && event.composedPath().some((path) => path instanceof RadioButton);
    if (!isInsideClick) {
      this.emitBlurEvent();
    }
  };

  /**
   * Returns the value of the first checked RadioButton
   *
   *  @returns {string | undefined} value of first checked RadioButton or undefined
   */
  private _getValueOfFirstCheckedRadioButton(): string | undefined {
    for (const node of this._radioButtonSlot.assignedNodes()) {
      if (node instanceof RadioButton) {
        if (node.hasAttribute('checked')) {
          return node.value;
        }
      }
    }
    return undefined;
  }

  /**
   * Checks the radio Button which has the given value
   *
   *  @param {string | undefined} newValue value of the radio button which should be checked
   */
  private _setCheckedOnRadioButton(newValue: string | undefined): void {
    this._radioButtonSlot.assignedNodes().forEach((node) => {
      if (node instanceof RadioButton) {
        if (node.value === newValue && !node.checked) {
          node.setAttribute('checked', '');
        }
      }
    });
  }

  /**
   * Removes checked from all Radio Buttons
   */
  private _cleanUp(): void {
    this._radioButtonSlot.assignedNodes().forEach((node) => {
      if (node instanceof RadioButton) {
        if (node.checked) {
          node.removeAttribute('checked');
        }
      }
    });
  }

  /**
   * Sets the internal value
   *
   * @param {string | undefined} newValue new value for the internal value
   */
  private _setInternalValue(newValue: string | undefined): void {
    if (newValue !== this._internalValue) {
      this._internalValue = newValue;
      this._cleanUp();
      this._setCheckedOnRadioButton(newValue);
      if (this.value !== this._internalValue) {
        this.value = this._internalValue;
      }
    }
  }

  private _checkedChangeEventHandler(checkedChangeEvent: Event) {
    if ((checkedChangeEvent.target as RadioButton).checked) {
      this._setInternalValue((checkedChangeEvent.target as RadioButton).value);
      this.emitChangedEvent();
      this.emitInputEvent();
    }
    this._stopEvent(checkedChangeEvent);
  }

  private _stopEvent(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
  }

  /**
   * Initializes the radio button group
   */
  protected firstUpdated(changedProperties: Map<string, string | number | symbol>): void {
    super.firstUpdated(changedProperties);
    if (this.value !== undefined) {
      this._defaultButtonValue = this.value;
      this._setInternalValue(this.value);
    } else {
      this._defaultButtonValue = this._getValueOfFirstCheckedRadioButton();
      this._cleanUp();
      this._setInternalValue(this._defaultButtonValue);
    }
    if (this.disabled) {
      this._radioButtonSlot.assignedNodes().forEach((node) => {
        if (node instanceof RadioButton) {
          if (!node.disabled) {
            node.setAttribute('disabled', '');
          }
        }
      });
    }
  }

  protected render(): TemplateResult | void {
    return html`<slot
      @checked-change=${this._checkedChangeEventHandler}
      @change=${this._stopEvent}
      @input=${this._stopEvent}
      id="radio-button-slot"
    ></slot>`;
  }
}
