import { customElement, html, property, PropertyValues, TemplateResult } from 'lit-element';
import { BaseElement } from '../../base/BaseElement';

/**
 * A structural element to be used within the `zui-select` feature component to describe options.
 * It has neither styling nor functional implementations whatsoever and just carryies some attributes  to be
 * used by the feature component to prepare and track the UI and state.
 * This components attributes will always be updated at runtime and can be used to query for current values.
 *
 * @example
 * ```HTML
 * <zui-select-option value="foo">Foo</zui-select-option>
 * <zui-select-option value="bar" disabled>Bar</zui-select-option>
 * <zui-select-option value="baz" selected>Baz</zui-select-option>
 * ```
 *
 * @slot - default slot for an optional label
 * @slot icon - optional slot to pass-in an icon
 */
@customElement('zui-select-option')
export class SelectOption extends BaseElement {
  /**
   * ARIA role for this element; defaults to 'option' if not explicitly set by author
   */
  @property({ reflect: true })
  role = 'option';

  /**
   * ARIA selected for this element; defaults to 'false' if not explicitly set by author
   */
  @property({ reflect: true, attribute: 'aria-selected' })
  ariaSelected = false;

  /**
   * sets the options as disabled, thus it will not be selectable
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * marks the option as selected
   */
  @property({ reflect: true, type: Boolean })
  selected = false;

  /**
   * the value of the option
   */
  @property({ reflect: true, type: String })
  value = '';

  /**
   * the id of the option
   */
  @property({ reflect: true, type: String })
  id = '';

  /**
   * sync ARIA selected state
   */
  protected update(changedProperties: PropertyValues): void {
    super.update(changedProperties);
    this.ariaSelected = this.selected;

    // allow setting an id explicitly
    if (this.id !== '') {
      return;
    }

    // transform value to id
    this.id = this.value.replace(/\s/g, '').toLocaleLowerCase();
  }

  protected render(): TemplateResult {
    return html`
      <slot name="icon"></slot>
      <slot></slot>
    `;
  }
}
