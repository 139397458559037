// type guard to differentiate between touch and mouse events
export const isTouchEvent = (event: Event): event is TouchEvent => 'touches' in event;

// delivers the cursor (or pointer) position from a given touch _or_ mouse event
export const getCursorPosition = (
  event: MouseEvent | TouchEvent
): {
  clientX: number;
  clientY: number;
} => {
  const { clientX, clientY } = isTouchEvent(event) ? event.touches[0] : event;
  return { clientX, clientY };
};

/**
 * traverse a DOM tree for siblings in either previous or next direction
 * and utilizes a step length, i.e. it either advances 1 or multiple steps
 *
 * @param current Element to traverse from
 * @param direction to traverse to
 * @param step length that should be traverse, defaults to 1
 * @param isValidNextElement is a predicate, indicating whether the currently traverse element is valid; defaults to a
 *   comparison of tagNames of passed current element
 * @returns either a matching Element or null, if none is found
 */
export function traverseDOMSiblingsByStepAndDirection(
  current: HTMLElement,
  direction: 'previous' | 'next',
  step = 1,
  isValidNextElement = (element) => element.tagName === current.tagName
): null | HTMLElement {
  // determine next element by binding to direction
  const nextElement = (element: HTMLElement) =>
    direction === 'previous' ? element.previousElementSibling : element.nextElementSibling;

  const advanceElement = (element: HTMLElement): HTMLElement | null =>
    Array.from({ length: step }).reduce((acc: HTMLElement) => {
      return acc ? nextElement(acc) : null;
    }, element) as HTMLElement | null;

  let nextElementInDirection = current;
  do {
    nextElementInDirection = advanceElement(nextElementInDirection);
  } while (nextElementInDirection && isValidNextElement(nextElementInDirection) === false);
  return nextElementInDirection;
}

/**
 * return whether a boolean DOM attribute is set
 *
 * @param elm DOM element to be checked for
 * @param attr name of the attribute to be checked for
 * @returns true if the attribute is set on the passed element
 */
export function isBooleanDOMAttributeSet(elm: HTMLElement, attr: string): boolean {
  const attrValue = elm.getAttribute(attr);
  // a boolean DOM attribute is true...
  // if it is present
  if (attrValue === '') {
    return true;
    // if it is set to its name
  } else if (attrValue === attr) {
    return true;
  } else {
    return false;
  }
}

/**
 *
 * @param otherElement the node we like to know whether it is before an element or not
 * @param element the node the otherElement position is tested against
 *
 * @returns boolean true when otherElement is before element
 */
export const isElementBeforeOther = (otherElement: Node, element: Node): boolean => {
  return (
    (element.compareDocumentPosition(otherElement) & Node.DOCUMENT_POSITION_PRECEDING) ===
    Node.DOCUMENT_POSITION_PRECEDING
  );
};

/**
 *
 * @param otherElement the node we like to know whether it is after an element or not
 * @param element the node the otherElement position is tested against
 *
 * @returns boolean true when otherElement is after element
 */
export const isElementAfterOther = (otherElement: Node, element: Node): boolean => {
  return (
    (element.compareDocumentPosition(otherElement) & Node.DOCUMENT_POSITION_FOLLOWING) ===
    Node.DOCUMENT_POSITION_FOLLOWING
  );
};
