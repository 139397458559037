import { css, CSSResult, customElement, html, property, TemplateResult, unsafeCSS } from 'lit-element';
import { IconBaseElement } from '@zeiss/zui-icons';
import { hostStyles } from '../../../host.styles';
import styles from './state-dot.component.scss';
import type { Size } from '../../../types';

type State = 'default' | 'error' | 'warning' | 'progress' | 'ready';

const stateDotStyles = css`
  ${unsafeCSS(styles)}
`;

/**
 * The state dot is a colored dot which has a color based on its state and one of 8 sizes.
 *
 * Size: xs | s | m | l | xl | 2xl | 3xl | 4xl
 *
 * State: default | error | warning | progress | ready
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=23531%3A787239)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=12425%3A0)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-state-dot size="s" state="default"></zui-state-dot>
 * ```
 */
@customElement('zui-state-dot')
export class StateDot extends IconBaseElement {
  static get styles(): CSSResult[] {
    return [hostStyles, stateDotStyles];
  }

  /**
   * Sets the size of the StateDot
   */
  @property({ reflect: true, type: String })
  size: Size = 's';

  /**
   * Sets the state of the StateDot
   */
  @property({ reflect: true, type: String })
  state: State = 'default';

  protected render(): TemplateResult {
    return html`<span class="state-dot"></span>`;
  }
}
