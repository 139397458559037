import { css, CSSResultArray, customElement, html, property, query, TemplateResult, unsafeCSS } from 'lit-element';
import { hostStyles } from '../../../host.styles';
import { BaseElement } from '../../base/BaseElement';
import { ListItem } from '../list-item/list-item.component';

import style from './list.component.scss';

const listStyles = css`
  ${unsafeCSS(style)}
`;

type Hierarchy = 'first' | 'second' | 'third';
type Size = 'xs' | 's' | 'm' | 'l' | 'xl';

/**
 * Map associating item sizes with its height expressed in grid units.
 */
const heights: { [key in Size]: number } = {
  xs: 3,
  s: 4,
  m: 6,
  l: 9,
  xl: 13,
};

/**
 * The list container represents a list of items.
 *
 * ## Figma
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=13761%3A0)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-list integrated hierarchy="second">
 *   <zui-list-item></zui-list-item>
 *   <zui-list-item></zui-list-item>
 * </zui-list>
 * ```
 *
 * Usage with skeletons:
 *
 * ```js
 * const skeletonTemplate = document.createElement('template');
 * skeletonTemplate.innerHTML = '<zui-list-item>My Skeleton</zui-list-item>'
 *
 * const list = document.createElement('zui-list')
 *
 * list.numberOfSkeletons = 5;
 * list.skeleton = skeletonTemplate;
 *
 * document.body.append(list)
 * ```
 * @slot - This is the default slot. It's an innerHtml of the list-element
 */
@customElement('zui-list')
export class List extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, listStyles];
  }

  /**
   * This disables the list
   */
  @property({ reflect: true, type: Boolean })
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._updatePropertyAndSlot('disabled', value);
  }

  /**
   * Defines the hierarchy for this list.
   */
  @property({ reflect: true })
  get hierarchy(): Hierarchy {
    return this._hierarchy;
  }

  set hierarchy(value: Hierarchy) {
    this._updatePropertyAndSlot('hierarchy', value);
  }

  /**
   * Defines if the list is read-only or not.
   * A read-only list contains items without interaction states.
   */
  @property({ reflect: true, type: Boolean })
  get readonly(): boolean {
    return this._readonly;
  }

  set readonly(value: boolean) {
    this._updatePropertyAndSlot('readonly', value);
  }

  /**
   * Defines if the list is integrated or not.
   * A list that is integrated but not read-only contains integrated items.
   * A list that is neither integrated nor read-only contains standalone items.
   */
  @property({ reflect: true, type: Boolean })
  get integrated(): boolean {
    return this._integrated;
  }

  set integrated(value: boolean) {
    this._updatePropertyAndSlot('integrated', value);
  }

  /**
   *	Defines one of five possible sizes ('xs' / 's' / 'm' / 'l' / 'xl');
   *	This size will also be applied to each list item.
   */
  @property({ reflect: true })
  get size(): Size {
    return this._size;
  }

  set size(value: Size) {
    this._updatePropertyAndSlot('size', value);
  }

  /**
   * Defines the number of skeletons.
   */
  @property({ reflect: true, type: Number, attribute: 'number-of-skeletons' })
  numberOfSkeletons: number;

  /**
   * This property allows to set a skeleton template for the list items.
   * Each skeleton will be placed within the placeholder element of each item container.
   * These skeletons will be shown instead of "real" list items, if no data is loaded to the list component.
   * The number of skeleton items can be set using the "numberOfSkeletons" property.
   *
   * To get a consistent styling you should pass a `<zui-list-item>` as template.
   *
   * @example
   *
   * skeleton="<zui-list-item><div> Skeleton's content</div></zui-list-item>"
   *
   */
  @property({ attribute: false })
  skeleton: HTMLTemplateElement;

  /**
   * Using the skeleton is a bit problematic. Because of the inner workings of the template tag,
   * you can't create a template with react. Instead you have to create a template tag with
   * DOM operations and `innerHTML` like this:
   *
   * ```js
   * const template = document.createElement("template")
   * template.innerHTML = "<zui-list-item>my skeleton</zui-list-item>"
   *
   * // pass "template" to the skeletonReact prop of the ZuiList
   * ```
   *
   * The reason for this behavior is that DOM nodes appended to the template node aren't available in the template's content-property.
   * Instead you have to use innerHTML because it has a special handling for template tags
   * (see https://developer.mozilla.org/en-US/docs/Web/API/Element/innerHTML#Operational_details).
   *
   * At the moment there is no easy solution for this. In the future we might rework the skeleton behavior
   * or provide a separate solution for react.
   * @private
   * */
  @property({ attribute: false })
  skeletonReact: HTMLTemplateElement;

  /**
   * Slot item for the items of a list.
   *
   * @private
   * @type {HTMLSlotElement}
   * @memberof List
   */
  @query('slot:not([name])')
  private _slotItem: HTMLSlotElement;

  private _disabled = false;
  private _hierarchy: Hierarchy = 'first';
  private _readonly = false;
  private _integrated = false;
  private _size: Size = 'm';

  /**
   * Resize Observer which triggers a reload when the height of the list gets changed
   */
  private _heightObserver: ResizeObserver = new ResizeObserver(() => {
    // if this.numberOfSkeletons is defined the rerendering isn't needed
    if (typeof this.numberOfSkeletons !== 'number') {
      this.requestUpdate();
    }
  });

  /**
   * This helper method is used to pass properties/attributes from the parent component to
   * all child components in the slot.
   * It is intended to be used in a setter method for a property of this component.
   * It will:
   * - set the value of the property in this instance
   * - set the value as attribute on all slotted list items
   * - call 'requestUpdate' so that lit-element can re-render the component.
   *
   * @param {string} propertyName name of the property to update
   * @param {any} newValue new value of the property
   * @param {string} attributeName optional name of attribute if there is a difference between attribute and property name
   * (i.e. property: "someValue", attribute: "some-value").
   */
  private _updatePropertyAndSlot<T>(propertyName: string, newValue: T, attributeName?: string): void {
    const oldValue = this[propertyName];

    this[`_${propertyName}`] = newValue;

    const actualAttributeName = attributeName ? attributeName : propertyName;

    if (this._slotItem) {
      this._slotItem
        .assignedNodes()
        .filter((node) => node instanceof ListItem)
        .forEach((slottedElement: ListItem) => {
          if (newValue) {
            if (typeof newValue === 'boolean') {
              slottedElement.setAttribute(actualAttributeName, '');
            } else {
              slottedElement.setAttribute(actualAttributeName, newValue.toString());
            }
          } else {
            slottedElement.removeAttribute(actualAttributeName);
          }
        });
    }

    this.requestUpdate(propertyName, oldValue);
  }

  /**
   * Returns the height of a skeleton based on the the size of the list.
   */
  private _getSkeletonHeight(): number {
    // TODO: find a way to use gu here
    return heights[this.size] * 8;
  }

  /**
   * Returns the number of skeletons the list contains.
   *
   * If the user has not set a value for the number of skeletons this function will default to the smallest amount of skeletons necessary
   * to fill at least half of the list. In that case the amount of skeletons may fill more than half of the list but never less.
   *
   * @returns {number} number of skeletons to generate.
   */
  private _getNumberOfSkeletons(): number {
    if (typeof this.numberOfSkeletons === 'number') {
      return this.numberOfSkeletons;
    }

    const halfHeight = Number(getComputedStyle(this).height.replace('px', '')) / 2;
    const numberOfItemsToFillTheHalf = halfHeight / this._getSkeletonHeight();
    return Math.ceil(numberOfItemsToFillTheHalf);
  }

  /**
   * Generates the required number of skeletons.
   *
   * @returns {ListItem[]} ListItems with all required attributes
   */
  private _generateSkeletons(): Node[] {
    const items: Node[] = [];
    const numberOfSkeletons = this._getNumberOfSkeletons();

    for (let i = 0; i < numberOfSkeletons; i++) {
      items.push(this._createSkeletonListItem());
    }

    return items;
  }

  /**
   * Creates `zui-list-items` to be used as skeletons based on the {@link skeleton} property.
   * - if no skeleton template was provided, an empty list item will be created
   * - if a template was provided it will be used (by cloning)
   *
   * 	@returns {ListItem} a new list item
   */
  private _createSkeletonListItem(): Node {
    if (!(this.skeleton instanceof HTMLTemplateElement)) {
      const listItem = new ListItem();
      this._updateListItemAttributes(listItem);
      return listItem;
    }

    const node = this.skeleton.content.cloneNode(true);

    if (node instanceof DocumentFragment) {
      Array.from(node.children).forEach((item) => this._updateListItemAttributes(item));
    }

    return node;
  }

  /**
   * Update the container type, size and css class when the slot changes
   */
  private _slotChangeHandler(): void {
    this._slotItem
      .assignedNodes()
      .filter((node) => node instanceof ListItem)
      .forEach((slotElement: ListItem) => {
        this._updateListItemAttributes(slotElement);
      });
  }

  /**
   * Helper method to synchronize all relevant attributes/properties of the list component with a given zui-list-item component.
   * This method is intended to be used when new list items are added to the list (either as normal list items or as skeletons).
   *
   * There is also the method {@link _updatePropertyAndSlot} which is used in setters and only updates one specific attribute.
   *
   * @param {Node} node can either be an instance of {@link ListItem} or a DOM Element with the tag 'zui-list-item'.
   */
  private _updateListItemAttributes(node: Node): void {
    if (node instanceof ListItem) {
      node.disabled = this.disabled;
      node.integrated = this.integrated;
      node.readonly = this.readonly;
      node.size = this.size;
      node.hierarchy = this.hierarchy;
    } else if (node instanceof Element && node.nodeName.toLowerCase() === 'zui-list-item') {
      // This case happens when a skeleton is created from a template. In this case it's possible that the node has not the correct type ListItem but
      // is still a valid list-item. However, in this case we can't safely set properties of the element but have to use attributes.

      // The following code is based on the assumption that propertyName === attributeName which is the case at the moment.
      // If in the future attributes are added that don't match exactly (i.e. property uses camelCase while attribute uses kebab-case)
      // we have to set those attributes explicitly by hand.

      // Extract<keyof List, keyof ListItem> constructs a type that contain only those property names that are included both in List and ListItem.
      const updateBooleanAttribute = (attr: Extract<keyof List, keyof ListItem>) =>
        this[attr] ? node.setAttribute(attr, attr) : node.removeAttribute(attr);
      const updateAttribute = (attr: Extract<keyof List, keyof ListItem>) =>
        this[attr] ? node.setAttribute(attr, this[attr].toString()) : node.removeAttribute(attr);

      (['readonly', 'integrated', 'disabled'] as const).forEach(updateBooleanAttribute);
      (['size', 'hierarchy'] as const).forEach(updateAttribute);
    }
  }

  /**
   * Adds height Observer to the List component
   */
  protected firstUpdated(changedProperties: Map<string, string | number | symbol>): void {
    super.firstUpdated(changedProperties);
    this._heightObserver.observe(this);
  }

  protected render(): TemplateResult | void {
    return html`<slot @slotchange=${this._slotChangeHandler}>${this._generateSkeletons()}</slot>`;
  }
}
