/* eslint-disable @typescript-eslint/member-ordering */
import { LitElement, property } from 'lit-element';
import { EventWithTarget } from '../../types';
import { isBooleanDOMAttributeSet } from '../../utils/dom.utils';
import { Constructor } from '../../utils/util.types';
import { FormDataHandlingMixinInterface, FormValidationMixinInterface } from './form-participation.interfaces';
import { ValidationResult, Validator, ValidityMessages } from './form-participation.types';

/**
 * This mixin is used to add form-handling capabilities to a component.
 * The component has to implement `FormEnabledElement`.
 *
 * Example:
 * ```
 * class MyExampleComponent extends FormValidationMixin(FormDataHandlingMixin(RealBaseElement))
 *  implements FormValidationElement<FormEnabledElement> {

 *   @property({reflect: true})
 *   value: string;
 *
 *   render() {
 *     //...
 *   }
 * }
 *
 * ```
 *
 *  @param superClass the superClass for the component. Most likely this is `RealBaseElement`.
 *  @param options to customize behavior
 *  @returns a constructor for the component with form-participation enabled.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const FormValidationMixin = <T extends Constructor<FormDataHandlingMixinInterface & LitElement>>(
  superClass: T,
  // eslint-disable-next-line no-empty-pattern
  {} = {}
) => {
  class FormEnabledComponentClass extends superClass {
    static initialValidator: Validator = { type: 'customError', validator: () => true };
    static initialValidityMessages: ValidityMessages = {
      customError: undefined,
      badInput: undefined,
      patternMismatch: undefined,
      rangeOverflow: undefined,
      rangeUnderflow: undefined,
      stepMismatch: undefined,
      tooLong: undefined,
      tooShort: undefined,
      typeMismatch: undefined,
      valueMissing: undefined,
    };

    // eslint-disable-next-line jsdoc/require-jsdoc
    @property({ reflect: true, type: Boolean })
    valid = false;

    // eslint-disable-next-line jsdoc/require-jsdoc
    @property({ reflect: true, type: Boolean })
    invalid = false;

    get willValidate(): boolean {
      // if we are disabled, we are not a candidate, nor if we are readonly
      if (this.disabled || this.readonly) {
        return false;
      }
      // we are a candidate for validation, if any validators have been added
      // or if validateCallback has been "implemented"
      // eslint-disable-next-line no-prototype-builtins
      return this._validators.length > 1 || this.hasOwnProperty('validationCallback');
    }

    get validationMessage(): string {
      if (!this.willValidate || this.valid) {
        return '';
      }
      const [failedValidationType] = Object.entries(this.validity).find(([, failed]) => failed === true) || [];
      const validationMessages = this.getValidityMessages();
      // fallback to customError if no specific error message has been specified
      // and be graceful to return otherwise an empty string if nothing has set whatsoever
      return validationMessages[failedValidationType] || validationMessages.customError || '';
    }

    set validationMessage(message: string) {
      console.warn(
        'Setting validationMessage is deprecated and will lead to an error in the next major release.' +
          ' Please use setCustomValidity() or setValidityMessages() instead!'
      );
      this.setCustomValidity(message);
    }

    get validity(): ValidityState {
      return {
        ...this._validationState,
        // we are always reporting a customError, because
        // built-in messages won't work with custom elements anyhow
        valid: this.valid,
      };
    }

    private _isFirstUpdate = true;

    private _validators: Validator[] = [FormEnabledComponentClass.initialValidator];

    private _validationState: ValidityState = {
      valid: true,
      customError: false,
      badInput: false,
      rangeOverflow: false,
      rangeUnderflow: false,
      stepMismatch: false,
      tooLong: false,
      tooShort: false,
      typeMismatch: false,
      valueMissing: false,
      patternMismatch: false,
    };

    private _validationMessages: Partial<ValidityMessages> = {};
    private _defaultValidationMessages = FormEnabledComponentClass.initialValidityMessages;

    private _toggleValidationState(type: Validator['type']): void {
      for (const key in this._validationState) {
        this._validationState[key] = type === key;
      }
    }

    formSubmitCallback(subEvent: EventWithTarget<HTMLFormElement>): void {
      const { target: hostForm } = subEvent;
      // we are to prevent form submission, if we are invalid
      // we can only be invalid, if we are to be checked for validity
      // we will ignore validation altogether, if our parent form has novalidate set

      // if parent has novalidate do nothing
      if (isBooleanDOMAttributeSet(hostForm, 'novalidate')) {
        return;
      }
      // if we are to validate and this turns out to be false
      if (this.willValidate && this.checkValidity() === false) {
        subEvent.preventDefault();
      }
    }

    checkValidity(): boolean {
      // run through all custom Validators being added via addValidator by component author and also call user validationCallback
      const { isValid = false, message = this._validationMessages.customError } = this.validationCallback(
        this['value']
      );
      const customValidationFailed = !isValid;
      // only run through author's validators if customValidation did not fail
      const firstFailedValidator = !customValidationFailed
        ? this._validators.find((validator) => validator.validator(this['value']) === false)
        : undefined;
      const validationFailed = customValidationFailed || firstFailedValidator !== undefined;
      if (customValidationFailed) {
        this.setCustomValidity(message);
        this._toggleValidationState('customError');
      }
      if (firstFailedValidator !== undefined) {
        this._toggleValidationState(firstFailedValidator.type);
      }
      if (validationFailed) {
        // dispatch invalid, toggle attribute states, toggle parts
        this.dispatchEvent(new Event('invalid'));
        this.valid = false;
        this.invalid = true;
      } else {
        this.valid = true;
        this.invalid = false;
      }
      return !validationFailed;
    }

    setCustomValidity(message: string): void {
      this.setValidityMessages({ customError: message });
    }

    setValidityMessages(validityMessages: Partial<ValidityMessages>): void {
      this._validationMessages = { ...this._validationMessages, ...validityMessages };
    }

    getValidityMessages(): ValidityMessages {
      return { ...this._defaultValidationMessages, ...this._validationMessages };
    }

    setDefaultValidityMessages(validityMessages: Partial<ValidityMessages>): void {
      this._defaultValidationMessages = { ...this._defaultValidationMessages, ...validityMessages };
    }

    validationCallback(value: unknown): ValidationResult {
      return { isValid: true };
    }

    addValidator(validator: Validator): void {
      this._validators.push(validator);
    }

    removeValidator(validatorType: Validator['type']): void {
      this._validators = this._validators.filter((validator) => validator.type !== validatorType);
    }

    resetValidators(): void {
      this._validators = [FormEnabledComponentClass.initialValidator];
    }

    resetValidationState(): void {
      this.valid = false;
      this.invalid = false;
    }

    updated(changedProperties: Map<string, unknown>): void {
      super.updated(changedProperties);
      // skip validation on first update and only validate if not readonly
      if (changedProperties.has('value') && !this._isFirstUpdate && this.willValidate) {
        this.checkValidity();
      }
      this._isFirstUpdate = false;
    }
  }

  return FormEnabledComponentClass as Constructor<FormValidationMixinInterface> & T;
};
