/* eslint-disable @typescript-eslint/member-ordering */
import { LitElement, property } from 'lit-element';
import { query } from 'lit-element/lib/decorators';
import { Constructor } from '../../utils/util.types';
import { getParentElementBySelector } from '../../utils/mixin.utils';
import { FormDataHandlingMixinInterface } from './form-participation.interfaces';

// we need them as closure, for proper removal
let _boundResetCallback;
let _boundSubmitCallback;
let _boundFormDataCallback;
let _boundKeyHandler;

const isFormDataEventSupported = Boolean('FormDataEvent' in window);

/**
 * This mixin is used to add form-data-handling capabilities to a component.
 * The component has to implement `FormEnabledElement`.
 *
 * Example:
 * ```
 * class MyFormComponent extends FormDataHandlingMixin(RealBaseElement) implements FormEnabledElement {
 *   @property({reflect: true})
 *   value: string;
 *
 *   render() {
 *     //...
 *   }
 * }
 *
 * ```
 *
 *  @param superClass the superClass for the component. Most likely this is `RealBaseElement`.
 *  @param options to customize behavior
 *  @param options.disableSubmitOnEnter to disable submission of parent form if `Enter` is pressed
 *  @returns a constructor for the component with form-participation enabled.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const FormDataHandlingMixin = <T extends Constructor<LitElement>>(
  superClass: T,
  { disableSubmitOnEnter = false, formControlSelector = '*[zuiFormControl]' } = {}
) => {
  class FormEnabledComponentClass extends superClass {
    // eslint-disable-next-line jsdoc/require-jsdoc
    @property({ reflect: true, type: Boolean })
    readonly = false;

    // eslint-disable-next-line jsdoc/require-jsdoc
    @property({ reflect: true, type: Boolean })
    disabled = false;

    // eslint-disable-next-line jsdoc/require-jsdoc
    @property({ reflect: true, type: String })
    name: string;

    @query(formControlSelector)
    formControl: HTMLElement;

    hostForm: HTMLFormElement;

    // eslint-disable-next-line jsdoc/require-jsdoc
    @property({ reflect: true, type: String, attribute: 'reset-value' })
    private get _resetValueAttribute(): string {
      return this._resetAttributeValue;
    }

    private set _resetValueAttribute(val) {
      this._hasResetBeenInitialized = true;
      this._lastResetValueType = 'attribute';
      this._resetAttributeValue = val;
    }

    get resetValue(): unknown {
      return this._resetPropertyValue;
    }

    set resetValue(val: unknown) {
      this._hasResetBeenInitialized = true;
      this._lastResetValueType = 'property';
      this._resetPropertyValue = val;
    }

    private _resetPropertyValue: unknown;
    private _resetAttributeValue: string;

    private _lastResetValueType: 'attribute' | 'property' = 'property';

    private _hasResetBeenInitialized = false;

    private _initialValue: unknown;

    private get _shouldSyncHiddenInput(): boolean {
      return this.hostForm && this['name'] && !isFormDataEventSupported;
    }

    private get _hiddenInput(): HTMLInputElement {
      const hasHiddenInput = this.hostForm.querySelector<HTMLInputElement>(`input[name=${this['name']}]`);
      return hasHiddenInput ? hasHiddenInput : this._addHiddenInput();
    }

    private _addHiddenInput(): HTMLInputElement {
      const inputElement = document.createElement('input');
      inputElement.type = 'hidden';
      inputElement.name = this['name'];
      this.hostForm.append(inputElement);
      return inputElement;
    }

    private _syncHiddenInput(): void {
      const inputRef = this._hiddenInput;
      inputRef.value = this.getAttribute('value');
      inputRef.disabled = this.disabled;
    }

    private _deleteInput(name: string): void {
      this.hostForm.querySelector(`input[name=${name}]`)?.remove();
    }

    private _handleKey({ code }: KeyboardEvent): void {
      // Enter will submit the _hostForm
      if (code === 'Enter') {
        this.hostForm?.requestSubmit();
      }
    }

    reset(): void {
      this.formResetCallback();
    }

    formResetCallback(): void {
      // if we are not initialized set value to initial value
      if (!this._hasResetBeenInitialized) {
        this['value'] = this._initialValue;
      } else {
        // depending on the lastResetValue use either the prop or the attribute
        if (this._lastResetValueType === 'property') {
          this['value'] = this.resetValue;
        } else {
          this.setAttribute('value', this._resetValueAttribute);
        }
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    formDisabledCallback(): void {}

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    formSubmitCallback(subEvent: Event): void {}

    formDataCallback({ formData }: { formData: FormData }): void {
      if (this.name && !this.disabled) {
        formData.append(this.name, this.getAttribute('value'));
      }
    }

    updated(changedProperties: Map<string, unknown>): void {
      super.updated(changedProperties);
      // toggle pristine if value change from undef -> sth
      if (this._shouldSyncHiddenInput) {
        // changes of disabled + value need to be reflected to hidden input
        if (changedProperties.has('value') || changedProperties.has('disabled')) {
          this._syncHiddenInput();
        }
        // name change, needs some special treatment, because the old hidden input
        // must be potentially be deleted
        if (changedProperties.has('name')) {
          const oldName = changedProperties.get('name') as string;
          this._deleteInput(oldName);
          this._syncHiddenInput();
        }
      }
      if (!disableSubmitOnEnter) {
        // for each update, remove eventlisteners and re-attach, because the template might
        // have re-rendered
        _boundKeyHandler = this._handleKey.bind(this);
        this.formControl?.removeEventListener('keydown', _boundKeyHandler);
        this.formControl?.addEventListener('keydown', _boundKeyHandler);
      }
      if (changedProperties.has('disabled') && this.disabled) {
        this.formDisabledCallback();
      }
    }

    // on connection to the DOM, find parent form
    connectedCallback(): void {
      super.connectedCallback();
      this.hostForm = getParentElementBySelector<HTMLFormElement>(this, 'form');
      // retain initial value
      this._initialValue = this['value'];
      if (this.hostForm) {
        // bind to onFormData + Reset & Co.
        _boundResetCallback = (): void => {
          if (!this.disabled) {
            this.formResetCallback();
          }
        };
        _boundSubmitCallback = (subEvent: Event): void => {
          if (!this.disabled) {
            this.formSubmitCallback(subEvent);
          }
        };
        this.hostForm.addEventListener('reset', _boundResetCallback);
        this.hostForm.addEventListener('submit', _boundSubmitCallback);
        if (isFormDataEventSupported) {
          _boundFormDataCallback = this.formDataCallback.bind(this);
          this.hostForm.addEventListener('formdata', _boundFormDataCallback);
        }
      }
    }

    disconnectedCallback(): void {
      // remove event listeners from parent form
      if (this.hostForm) {
        this.hostForm.removeEventListener('reset', _boundResetCallback);
        this.hostForm.removeEventListener('submit', _boundSubmitCallback);
        if (isFormDataEventSupported) {
          this.hostForm.removeEventListener('formdata', _boundFormDataCallback);
        }
        if (this._shouldSyncHiddenInput) {
          this._deleteInput(this['name']);
        }
      }
      super.disconnectedCallback();
    }
  }

  return FormEnabledComponentClass as Constructor<FormDataHandlingMixinInterface> & T;
};
