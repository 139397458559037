import { CSSResultArray, TemplateResult, customElement, html, property, css, unsafeCSS } from 'lit-element';
import { hostStyles } from '../../host.styles';
import { BaseElement } from '../base/BaseElement';
import style from './interactive-icon.component.scss';

const interactiveIconStyles = css`
  ${unsafeCSS(style)}
`;

type EmphasisDeprecated = 'moderate' | 'muted';
type Emphasis = 'default' | 'subtle' | 'highlight' | EmphasisDeprecated;

/**
 * Unlike a basic icon (see icon component) an interactive icon reacts to
 * interaction states (e.g. disabled, pressed etc.) with a different styling.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13%3A3323)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-interactive-icon emphasis="highlight">
 *   <zui-icon-holy-placeholder size="m"></zui-icon-holy-placeholder>
 * </zui-interactive-icon>
 * ```
 *
 * @slot default - This is the default slot. It's an innerHtml of the interactive-icon-element and should contain an regular icon like zui-icon-close. (See documentation of icon component for a list of all available icons.)
 */
@customElement('zui-interactive-icon')
export class InteractiveIcon extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, interactiveIconStyles];
  }

  /**
   * Defines one of three possible emphasis states ('default' | 'subtle' | 'highlight')
   * The deprecated emphasis "muted" and "moderate" were renamed to "subtle" and "default"
   */
  @property({ reflect: true })
  emphasis: Emphasis = 'default';

  /**
   * This disables the interactive icon
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * Disables the lately introduced enlarged hitareas
   */
  @property({ reflect: true, type: Boolean, attribute: 'use-deprecated-hitarea' })
  useDeprecatedHitarea = false;

  /**
   * Stops the click event from emiting when disabled is set
   *
   * @param {MouseEvent} clickEvent click event which should not be emited when the interactive icon is disabled
   */
  private _handleClickEvent(clickEvent: MouseEvent): void {
    // do not emit if disabled
    if (this.disabled) {
      clickEvent.preventDefault();
      clickEvent.stopPropagation();
      clickEvent.stopImmediatePropagation();
    }
  }

  /**
   * Detects if one of the deprecated emphasis states was used
   *
   * @param {Map<string,unknown>} changedProperties properties which have been changed
   */
  protected update(changedProperties: Map<string, unknown>): void {
    super.update(changedProperties);
    const emphasis = changedProperties.get('emphasis');
    if (emphasis === 'muted' || emphasis === 'moderate') {
      console.warn(`Deprecated emphasis: ${emphasis} was used on zui-interactive-icon.`);
    }
  }

  protected render(): TemplateResult | void {
    return html`<button ?disabled=${this.disabled}><slot @click="${this._handleClickEvent}"></slot></button>`;
  }
}
