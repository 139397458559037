import {
  css,
  CSSResultArray,
  customElement,
  html,
  property,
  PropertyValues,
  query,
  state,
  TemplateResult,
  unsafeCSS,
} from 'lit-element';
import { ifDefined } from 'lit-html/directives/if-defined.js';
import { hostStyles } from '../../../host.styles';
import { DateTime } from 'luxon';
import type { VariationPlacement } from '@popperjs/core/lib/enums';
import type { Instance as PopperInstance } from '@popperjs/core/lib/types';
import { defaultModifiers, popperGenerator } from '@popperjs/core/dist/esm/popper-lite';
import offset from '@popperjs/core/dist/esm/modifiers/offset';
import { event } from '../../../decorators/event.decorator';
import { SimpleFormParticipationMixin } from '../../../mixins/simple-form-participation.mixin';
import { RealBaseElement } from '../../base/BaseElement';
import { EventWithTarget, SimpleFormParticipation } from '../../../types';
import styles from './textfield-date-picker.component.scss';
import {
  DatePickerWeekdayEnum,
  dateTimeToJsDate,
  daysOfWeekConverter,
  getDateTimesFromJsDates,
  getDefaultLocale,
  hasWeekday,
  isJsDate,
  isoDateConverter,
  jsDateToDateTime,
  someIsSameDay,
  someIsSameMonth,
  someIsSameYear,
} from '../utils/date-picker.utils';

import { DatePickerInput } from '../date-picker-input/date-picker-input.component';
import { Popover } from '../../popover/popover.component';
import { Tooltip } from '../../tooltip/tooltip.component';

enum CurrentPickerEnum {
  Day = 'day',
  Month = 'month',
  Year = 'year',
}

const textfieldDatePickerStyles = css`
  ${unsafeCSS(styles)}
`;

type TextfieldDatePickerInputWarning = 'disabled' | 'invalid';

const createPopper = popperGenerator({
  defaultModifiers: [
    ...defaultModifiers,
    {
      ...offset,
      options: {
        offset: [0, 8],
      },
    },
  ],
});

/**
 * The textfield date picker component shows an input and opens the date picker when the interactive icon is selected.
 *
 * ## Figma
 * - [Desktop - Component Library - Text Field](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---2.7?node-id=384:64193)
 * - [Desktop - Component Library - Date Picker](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=21190%3A191753)
 * - [Styleguide – Desktop - Text Field](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=48826:397355)
 * - [Styleguide – Desktop - Date Picker](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=6557%3A241082)
 *
 * @example
 * HTML:
 *
 * Textfield date picker
 * ```html
 * <div class="side-bar">
 *   <zui-textfield-date-picker
 *     close-on-date-selected
 *     disabled-days-of-week="Mo,Tuesday"
 *     locale="en-US"
 *     max-date="2010-01-01T00:00:00.000+01:00"
 *     min-date="2020-12-31T23:59:59.999+01:00"
 *     name="textfieldDatePicker"
 *     parent-selector=".side-bar"
 *     placeholderDay="DD"
 *     placeholderMonth="MM"
 *     placeholderYear="YYYY"
 *     value="2021-07-21T11:00:00.000+02:00"
 *   >
 *   </zui-textfield-date-picker>
 * </div>
 * ```
 *
 * Form example
 * ```html
 * <form>
 *   <button type="reset">reset</button>
 *   <button type="submit">submit</button>
 *   <zui-textfield-date-picker
 *    ...
 *   >
 *   </zui-textfield-date-picker>
 * </form>
 * ```
 *
 * @fires {CustomEvent} textfield-date-picker-date-selected - emits the selected day
 * @fires {CustomEvent} textfieldDatePickerDateSelected - (Deprecated) emits the selected day
 *
 * @cssprop --zui-textfield-date-picker-input-width - size of the input
 * @cssprop --zui-textfield-date-picker-day-placeholder-width - override default day input placeholder width that is optimized for DD
 * @cssprop --zui-textfield-date-picker-month-placeholder-width - override default month input placeholder width that is optimized for MM
 * @cssprop --zui-textfield-date-picker-year-placeholder-width - override default year input placeholder width that is optimized for YYYY
 */
@customElement('zui-textfield-date-picker')
export class TextfieldDatePicker
  extends SimpleFormParticipationMixin(RealBaseElement)
  implements SimpleFormParticipation<Date> {
  static get styles(): CSSResultArray {
    return [hostStyles, textfieldDatePickerStyles];
  }

  /**
   * The name of the element for form participation.
   */
  @property({ reflect: true, type: String })
  name: string;

  /**
   * disables the datePicker
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * makes the datePicker readonly
   */
  @property({ reflect: true, type: Boolean })
  readonly = false;

  /**
   * whether the picker should be closed after date selection or not
   */
  @property({ reflect: true, type: Boolean, attribute: 'close-on-date-selected' })
  closeOnDateSelected = false;

  /**
   * disabled dates
   */
  @property({ type: Array, attribute: false })
  disabledDates: Date[] = [];

  private get _disabledDatesDT(): DateTime[] {
    return getDateTimesFromJsDates(this.disabledDates);
  }

  /**
   * disabled months
   */
  @property({ type: Array, attribute: false })
  disabledMonths: Date[] = [];

  private get _disabledMonthsDT(): DateTime[] {
    return getDateTimesFromJsDates(this.disabledMonths);
  }

  /**
   * disabled years
   */
  @property({ type: Array, attribute: false })
  disabledYears: Date[] = [];

  private get _disabledYearsDT(): DateTime[] {
    return getDateTimesFromJsDates(this.disabledYears);
  }

  /**
   * disabled days of week
   */
  @property({ reflect: true, type: String, attribute: 'disabled-days-of-week', converter: daysOfWeekConverter })
  disabledDaysOfWeek: DatePickerWeekdayEnum[] = [];

  /**
   * whether the input is invalid or not
   */
  @property({ reflect: true, type: Boolean })
  invalid = false;

  /**
   * locale
   */
  @property({ reflect: true, type: String })
  locale = getDefaultLocale();

  /**
   * max date
   */
  @property({ reflect: true, type: String, attribute: 'max-date', converter: isoDateConverter })
  maxDate: Date;

  private get _maxDateDT(): DateTime {
    return jsDateToDateTime(this.maxDate);
  }

  /**
   * min date
   */
  @property({ reflect: true, type: String, attribute: 'min-date', converter: isoDateConverter })
  minDate: Date;

  private get _minDateDT(): DateTime {
    return jsDateToDateTime(this.minDate);
  }

  /**
   * placeholder day
   */
  @property({ reflect: true, type: String, attribute: 'placeholder-day' })
  placeholderDay = 'DD';

  /**
   * placeholder month
   */
  @property({ reflect: true, type: String, attribute: 'placeholder-month' })
  placeholderMonth = 'MM';

  /**
   * placeholder year
   */
  @property({ reflect: true, type: String, attribute: 'placeholder-year' })
  placeholderYear = 'YYYY';

  /**
   * The default alignment of the date picker popover is flush with the right side of the textfield.
   * By default the calculation is based on the viewport. When there isn't enough space it is left aligned.
   * When used inside a container we do not want to calculate the space based on the viewport but inside the container.
   * To limit the calculation on the container you can define this by class or id.
   */
  @property({ reflect: true, type: String, attribute: 'parent-selector' })
  parentSelector: string = null;

  /**
   * show the calendar UI or not, defaults to false
   */
  @property({ reflect: true, type: Boolean, attribute: 'show-calendar' })
  showCalendar = false;

  /**
   * selected date value
   */
  @property({ reflect: true, type: String, converter: isoDateConverter })
  value: Date;

  private get _valueDT(): DateTime {
    return jsDateToDateTime(this.value);
  }
  private set _valueDT(dt: DateTime) {
    this.value = dateTimeToJsDate(dt);
  }

  /**
   * alternative disabled date selected warning message
   */
  @property({ reflect: true, type: String, attribute: 'warning-disabled' })
  warningDisabled = 'This date is not allowed.';

  /**
   * alternative invalid date selected warning message
   */
  @property({ reflect: true, type: String, attribute: 'warning-invalid' })
  warningInvalid = 'Please enter a valid date.';

  /**
   * optional weekstart that overrides the locale
   */
  @property({ reflect: true, type: String, attribute: 'week-start' })
  weekStart: DatePickerWeekdayEnum;

  /**
   * Emits a custom textfield-date-picker-date-selected event when a date is selected
   *
   * @param detail object with value
   * @param detail.value the selected date
   *
   * @private
   */
  @event({
    eventName: 'textfield-date-picker-date-selected',
    bubbles: true,
    composed: true,
  })
  emitTextfieldDatePickerDateSelectedEvent(detail: { value: Date }): void {
    // TODO: remove in version 2.0
    this.dispatchEvent(
      new CustomEvent('textfieldDatePickerDateSelected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );

    this.dispatchEvent(
      new CustomEvent('textfield-date-picker-date-selected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  /**
   * Emits an input event
   *
   * @private
   */
  @event({
    eventName: 'input',
    bubbles: true,
    composed: true,
  })
  emitInputEvent(): void {
    this.dispatchEvent(
      new Event('input', {
        bubbles: true,
        composed: true,
      })
    );
  }

  @state()
  private get _currentDate(): DateTime {
    if (!this._internalCurrentDate) {
      return this._valueDT || DateTime.now();
    }

    return this._internalCurrentDate;
  }

  private set _currentDate(current: DateTime) {
    const oldValue = this._internalCurrentDate;
    this._internalCurrentDate = current;
    this.requestUpdate('_currentDate', oldValue).then();
  }

  @state()
  private _currentPicker = CurrentPickerEnum.Day;

  @state()
  private _datePickerPlacement: Extract<VariationPlacement, 'bottom-start' | 'bottom-end'> = 'bottom-start';

  @state()
  private _warning: TextfieldDatePickerInputWarning | null = null;

  @query('zui-date-picker-input')
  private _datePickerInput: DatePickerInput;

  @query('zui-popover')
  private _popover: Popover;

  @query('zui-tooltip')
  private _tooltip: Tooltip;

  private _internalCurrentDate: DateTime | null = null;
  private _popperDatePicker: PopperInstance;
  private _popperTooltip: PopperInstance;
  private _tooltipHideDelay = 300;
  private _tooltipShowDelay = 300;

  private get _disabledDateConditions(): ((date: DateTime) => boolean)[] {
    return [
      (date): boolean => (this._minDateDT ? date.toMillis() < this._minDateDT.toMillis() : false),
      (date): boolean => (this._maxDateDT ? date.toMillis() > this._maxDateDT.toMillis() : false),
      (date): boolean => someIsSameDay(date, this._disabledDatesDT),
      (date): boolean => someIsSameMonth(date, this._disabledMonthsDT),
      (date): boolean => someIsSameYear(date, this._disabledYearsDT),
      (date): boolean => hasWeekday(date, this.disabledDaysOfWeek),
    ];
  }

  private _textfieldDatePickerResizeObserver = new ResizeObserver(async () => {
    requestAnimationFrame(() => {
      const { left: viewPortLeft } = this.getBoundingClientRect();
      const { left: parentLeft } =
        this.parentSelector && this.closest(this.parentSelector)
          ? this.closest(this.parentSelector).getBoundingClientRect()
          : { left: 0 };
      const { width: datePickerInputWidth } = this._datePickerInput.getBoundingClientRect();

      this._datePickerPlacement =
        datePickerInputWidth >= 290 || viewPortLeft - parentLeft + datePickerInputWidth > 290
          ? 'bottom-end'
          : 'bottom-start';

      this._popperDatePicker?.setOptions({
        placement: this._datePickerPlacement,
      });
    });
  });

  connectedCallback(): void {
    super.connectedCallback();

    this.addEventListener('textfield-date-picker-date-selected', this._handleDatePickerDateSelectedEvent);

    // todo: this should be removed when a reusable solution has been implemented
    // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
    window.addEventListener('click', this._handleOutsideClick);
  }

  disconnectedCallback(): void {
    this._popperDatePicker.destroy();
    this._popperTooltip.destroy();
    this._textfieldDatePickerResizeObserver.unobserve(this);

    this.removeEventListener('textfield-date-picker-date-selected', this._handleDatePickerDateSelectedEvent);

    // todo: this should be removed when a reusable solution has been implemented
    // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
    window.removeEventListener('click', this._handleOutsideClick);

    super.disconnectedCallback();
  }

  private get _hasWarning(): Promise<TextfieldDatePickerInputWarning | null> {
    return new Promise((resolve) => {
      const value = this._valueDT;
      const disabled = value ? this._disabledDateConditions.some((predicate) => predicate(value)) : false;
      const invalid = value ? !value.isValid : false;
      const warning = disabled ? 'disabled' : invalid ? 'invalid' : null;

      setTimeout(() => resolve(warning), warning === null ? this._tooltipHideDelay : this._tooltipShowDelay);
    });
  }

  private async _handleDatePickerInputCalendarSelected(): Promise<void> {
    this.showCalendar = !this.showCalendar;

    if (this.showCalendar) {
      await this._popperDatePicker.setOptions({
        placement: this._datePickerPlacement,
      });
    }
  }

  private _updateWarning(): void {
    this._hasWarning.then((warning) => {
      this.invalid = warning !== null;
      // on hide we have to wait until the opacity ease in out duration is elapsed
      setTimeout(() => (this._warning = warning), warning === null ? 200 : 0);
    });
  }

  private _handleDatePickerInputChanged({
    detail,
  }: CustomEvent<{ value: Date | { error: Record<'day' | 'month' | 'year', number | null> } | null }>): void {
    this._valueDT =
      detail.value === null
        ? null
        : isJsDate(detail.value)
        ? DateTime.fromJSDate(detail.value)
        : DateTime.fromObject(detail.value.error);

    this._currentDate = this._valueDT?.isValid ? this._valueDT : DateTime.now();

    this._updateWarning();

    if (!this._valueDT || !this._valueDT.isValid) {
      return;
    }

    this.emitTextfieldDatePickerDateSelectedEvent({
      value: this._valueDT.toJSDate(),
    });
  }

  private _handleDatePickerCurrentDateChanged({
    detail,
  }: CustomEvent<{ currentDate: Date; currentPicker: CurrentPickerEnum }>): void {
    this._currentDate = jsDateToDateTime(detail.currentDate);
    this._currentPicker = detail.currentPicker;
  }

  private _handleDatePickerInputFocused(): void {
    this.showCalendar = false;
  }

  private _handleDatePickerDateSelected({ detail }: CustomEvent<{ value: Date }>): void {
    this.value = detail.value;
    this.showCalendar = !this.closeOnDateSelected;

    this._updateWarning();

    this.emitTextfieldDatePickerDateSelectedEvent({ value: detail.value });
  }

  private _handleDatePickerDateSelectedEvent(): void {
    this.emitInputEvent();
  }

  // todo: this should be removed when a reusable solution has been implemented
  // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
  private _handleOutsideClick = (event: EventWithTarget<TextfieldDatePicker | Element>): void => {
    if (!this.showCalendar) {
      return;
    }

    const isInsideClick =
      this.isSameNode(event.target) &&
      event.composedPath().some((path) => path instanceof DatePickerInput || path instanceof Popover);

    if (!isInsideClick) {
      this.showCalendar = false;
    }
  };

  protected async firstUpdated(): Promise<void> {
    await this.updateComplete;

    this._popperDatePicker = createPopper(this._datePickerInput, this._popover, {
      placement: this._datePickerPlacement,
    });
    this._popperTooltip = createPopper(this._datePickerInput, this._tooltip, { placement: 'bottom-start' });

    this._textfieldDatePickerResizeObserver.observe(this);
  }

  protected updated(changedProperties: PropertyValues): void {
    if (changedProperties.has('value')) {
      this._updateWarning();
    }

    if (changedProperties.has('showCalendar')) {
      if (this.showCalendar === false) {
        this._currentDate = this._valueDT && this._valueDT.isValid ? this._valueDT : DateTime.now();
        this._currentPicker = CurrentPickerEnum.Day;
      }
    }
  }

  protected render(): TemplateResult {
    return html`
      <zui-date-picker-input
        ?calendar-opened="${this.showCalendar}"
        ?disabled="${this.disabled}"
        ?invalid="${this.invalid}"
        ?readonly="${this.readonly}"
        locale="${this.locale}"
        placeholder-day="${this.placeholderDay}"
        placeholder-month="${this.placeholderMonth}"
        placeholder-year="${this.placeholderYear}"
        selected-date="${ifDefined(this._valueDT?.isValid ? this._valueDT.toISO() : undefined)}"
        @date-picker-input-calendar-selected="${this._handleDatePickerInputCalendarSelected}"
        @date-picker-input-changed="${this._handleDatePickerInputChanged}"
        @date-picker-input-focused="${this._handleDatePickerInputFocused}"
      >
      </zui-date-picker-input>
      <zui-popover class="popover">
        <zui-date-picker
          .disabledDates="${this.disabledDates}"
          .disabledMonths="${this.disabledMonths}"
          .disabledYears="${this.disabledYears}"
          ?close-on-date-selected="${this.closeOnDateSelected}"
          current-date="${ifDefined(this._currentDate?.isValid ? this._currentDate.toISO() : undefined)}"
          current-picker="${this._currentPicker}"
          disabled-days-of-week="${this.disabledDaysOfWeek.length > 0 ? this.disabledDaysOfWeek.join(',') : ''}"
          locale="${this.locale}"
          max-date="${this.maxDate}"
          min-date="${this.minDate}"
          selected-date="${ifDefined(this._valueDT?.isValid ? this._valueDT.toISO() : undefined)}"
          week-start="${ifDefined(this.weekStart)}"
          @date-picker-date-selected="${this._handleDatePickerDateSelected}"
          @date-picker-current-date-changed="${this._handleDatePickerCurrentDateChanged}"
        >
        </zui-date-picker>
      </zui-popover>
      <zui-tooltip class="tooltip" emphasis="warning">
        ${this._warning === 'disabled' ? this.warningDisabled : this._warning === 'invalid' ? this.warningDisabled : ''}
      </zui-tooltip>
    `;
  }
}
