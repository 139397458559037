import {
  CSSResultArray,
  TemplateResult,
  customElement,
  html,
  css,
  unsafeCSS,
  property,
  query,
  state,
} from 'lit-element';
import styles from './date-picker-input-part.component.scss';
import { EventWithTarget } from '../../../types';
import { event } from '../../../decorators/event.decorator';
import { addLeadingZeros, constrainValue, getNextInput, getPreviousInput } from '../utils/date-picker-input.utils';
import { BaseElement } from '../../base/BaseElement';
import { literalConverter } from '../utils/date-picker.utils';

const datePickerInputPartStyles = css`
  ${unsafeCSS(styles)}
`;

/**
 * The date picker input part is used inside the zui-date-picker-input for displaying the day, month or year.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-date-picker-input-part
 *  input-part-type="day"
 *  literals="."
 *  max="31"
 *  min="1"
 *  value="5"
 * >
 * </zui-date-picker-input-part>
 * ```
 *
 * @fires {CustomEvent} date-picker-input-part-value-changed - emits when the input value has changed
 *
 * @cssprop --zui-date-picker-input-part-placeholder-width - override input placeholder width
 */
@customElement('zui-date-picker-input-part')
export class DatePickerInputPart extends BaseElement {
  static get styles(): CSSResultArray {
    return [datePickerInputPartStyles];
  }

  /**
   * disabled
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * type
   */
  @property({ reflect: true, type: String, attribute: 'input-part-type' })
  inputPartType: string;

  /**
   * input width
   */
  @property({ reflect: true, type: String, attribute: 'input-width' })
  inputWidth: string;

  /**
   * literals
   */
  @property({ reflect: true, type: String, converter: literalConverter })
  literals: string[] = [];

  /**
   * max
   */
  @property({ reflect: true, type: Number })
  max: number;

  /**
   * min
   */
  @property({ reflect: true, type: Number })
  min: number;

  /**
   * placeholder
   */
  @property({ reflect: true, type: String })
  placeholder: string;

  /**
   * readonly
   */
  @property({ reflect: true, type: Boolean })
  readonly = false;

  /**
   * value
   */
  @property({ reflect: true, type: Number })
  value: number | null = null;

  /**
   * @param detail object
   * @param detail.type string
   * @param detail.value number or null
   * @private
   */
  @event({ eventName: 'date-picker-input-part-value-changed', bubbles: true, composed: true })
  emitInputValueChangeEvent(detail: { type: string; value: number | null }): void {
    this.dispatchEvent(
      new CustomEvent('date-picker-input-part-value-changed', { bubbles: true, composed: true, detail })
    );
  }

  @state()
  private get _showPlaceholder(): boolean {
    return this.value ? false : this._internalShowPlaceholder;
  }

  private set _showPlaceholder(value: boolean) {
    const oldValue = this._internalShowPlaceholder;
    this._internalShowPlaceholder = value;
    this.requestUpdate('_showPlaceholder', oldValue).then();
  }

  @query('input')
  private _inputElement: HTMLInputElement;

  private _internalShowPlaceholder = true;
  private _validLiterals = ['.', '/'];

  focus(options?: FocusOptions): void {
    this._inputElement.focus(options);
  }

  private get _maxCharacterLength(): number {
    return this.max.toString().length;
  }

  private get _value(): number | null {
    return this.value ? addLeadingZeros(this.value, this._maxCharacterLength) : null;
  }

  private _updateValue(event: EventWithTarget<HTMLInputElement>): void {
    const targetValue = parseInt(event.target.value);

    if (!isNaN(targetValue)) {
      event.target.value =
        addLeadingZeros(constrainValue(targetValue, this.min, this.max), this._maxCharacterLength)?.toString() || null;
    }

    this.emitInputValueChangeEvent({
      type: this.inputPartType,
      value: !isNaN(targetValue) ? constrainValue(targetValue, this.min, this.max) : null,
    });
  }

  private _handleInputEvent(event: EventWithTarget<HTMLInputElement, InputEvent>): void {
    if (event.target.value.length > this._maxCharacterLength) {
      event.target.value = event.target.value.slice(0, this._maxCharacterLength);
    }

    const targetValue = parseInt(event.target.value);

    if (!isNaN(targetValue)) {
      this._showPlaceholder = false;
    } else {
      this.value = null;
      this._showPlaceholder = true;
    }

    if (event.inputType === 'insertText' && event.target.value.length === this._maxCharacterLength) {
      getNextInput(this)?.focus();
    }
  }

  private _handleInputBlurEvent(event: EventWithTarget<HTMLInputElement, FocusEvent>): void {
    this._updateValue(event);
  }

  private _handleBackspaceKeydownEvent(event: EventWithTarget<HTMLInputElement, KeyboardEvent>): void {
    if (isNaN(parseInt(event.target.value))) {
      event.preventDefault();

      getPreviousInput(this)?.focus();
    }
  }

  private _handleLiteralInputEvent(event: EventWithTarget<HTMLInputElement, KeyboardEvent>): void {
    event.preventDefault();

    if (!isNaN(parseInt(event.target.value))) {
      getNextInput(this)?.focus();
    }
  }

  private _handleInputKeydownEvent(event: EventWithTarget<HTMLInputElement, KeyboardEvent>): void {
    switch (event.key) {
      case '+':
      case ',':
      case '-':
      case 'e':
        event.preventDefault();
        break;
      case 'Enter':
        this._updateValue(event);
        break;
      case 'Backspace':
        this._handleBackspaceKeydownEvent(event);
        break;
      default:
        if (this.literals.includes(event.key)) {
          this._handleLiteralInputEvent(event);
        } else if (this._validLiterals.filter((key) => !this.literals.includes(key)).includes(event.key)) {
          event.preventDefault();
        }
        break;
    }
  }

  protected render(): TemplateResult {
    return html`
      <input
        ?disabled="${this.disabled}"
        ?readonly="${this.readonly}"
        .value="${this._value}"
        max="${this.max}"
        maxlength="${this._maxCharacterLength}"
        min="${this.min}"
        placeholder="${this.placeholder}"
        type="number"
        style="---zui-date-picker-input-part-width: var(${this._showPlaceholder
          ? '--zui-date-picker-input-part-placeholder-width'
          : '---zui-date-picker-input-part-input-width'})"
        @blur="${this._handleInputBlurEvent}"
        @input="${this._handleInputEvent}"
        @keydown="${this._handleInputKeydownEvent}"
      />
    `;
  }
}
