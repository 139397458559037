// FIXME: rethink the whole thing
import { BaseElement } from './BaseElement';
import { property } from 'lit-element';

declare global {
  // Declarations for:
  // https://html.spec.whatwg.org/multipage/custom-elements.html#elementinternals
  //
  // TypeScript does not support form-associated custom elements
  // at this moment. If this changes in the future the following
  // definitions will be unnecessary and everything below this
  // comment should be removed.

  // Copy&Paste from https://github.com/microsoft/TSJS-lib-generator/pull/818/commits/3f0d0cf53f08831acdb93ea083314ee490c14ad1
  interface ValidityStateFlags {
    badInput?: boolean;
    customError?: boolean;
    patternMismatch?: boolean;
    rangeOverflow?: boolean;
    rangeUnderflow?: boolean;
    stepMismatch?: boolean;
    tooLong?: boolean;
    tooShort?: boolean;
    typeMismatch?: boolean;
    valueMissing?: boolean;
  }

  interface HTMLElement {
    /**
     * Returns an ElementInternals object targeting the custom element
     * element. Throws an exception if element is not a custom element, if
     * the "internals" feature was disabled as part of the element
     * definition, or if it is called twice on the same element.
     */
    attachInternals(): ElementInternals;
  }

  interface ElementInternals {
    /**
     * Returns the form owner of internals's target element.
     */
    readonly form: HTMLFormElement | null;

    /**
     * Returns a NodeList of all the label elements that internals's target
     * element is associated with.
     */
    readonly labels: NodeList;

    /**
     * Returns the error message that would be shown to the user if
     * internals's target element was to be checked for validity.
     */
    readonly validationMessage: string;

    /**
     * Returns the ValidityState object for internals's target element.
     */
    readonly validity: ValidityState;

    /**
     * Returns true if internals's target element will be validated when
     * the form is submitted; false otherwise.
     */
    readonly willValidate: boolean;

    /**
     * Returns true if internals's target element has no validity problems;
     * false otherwise. Fires an invalid event at the element in the latter
     * case.
     */
    checkValidity(): boolean;

    /**
     * Returns true if internals's target element has no validity problems;
     * otherwise, returns false, fires an invalid event at the element,
     * and (if the event isn't canceled) reports the problem to the user.
     */
    reportValidity(): boolean;

    /**
     * Sets both the state and submission value of internals's target
     * element to value.
     *
     * If value is null, the element won't participate in form submission.
     */
    setFormValue(value: File | string | FormData | null, state?: File | string | FormData | null): void;

    /**
     * Marks internals's target element as suffering from the constraints
     * indicated by the flags argument, and sets the element's validation
     * message to message. If anchor is specified, the user agent might use
     * it to indicate problems with the constraints of internals's target
     * element when the form owner is validated interactively or
     * reportValidity() is called.
     */
    setValidity(flags: ValidityStateFlags, message?: string, anchor?: HTMLElement): void;
  }

  interface FormDataEvent extends Event {
    readonly formData: FormData;
  }

  interface FormDataEventInit extends EventInit {
    formData: FormData;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const FormDataEvent: {
    prototype: FormDataEvent;
    new (type: string, eventInitDict?: FormDataEventInit): FormDataEvent;
  };

  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ElementInternals: ElementInternals;
  }

  interface GlobalEventHandlersEventMap {
    formdata: FormDataEvent;
  }

  interface GlobalEventHandlers {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onformdata: ((this: GlobalEventHandlers, ev: FormDataEvent) => any) | null;
  }
}

/**
 * Parent class for form-associated custom elements
 *
 * HTML form-elements have a value that must be a string.
 * However, for components it may be useful to use a different type.
 * This can be done by adjusting the generic type parameter T to whatever type your component likes to use.
 * If your type can be converted to a string out-of-the-box with it's `toString` method, then nothing has to be done.
 * Otherwise you need to override {@link serializeValue} in a way that converts your value to a string that will be
 * used as form-value.
 *
 * @see {@link https://html.spec.whatwg.org/dev/custom-elements.html#custom-elements-face-example|Creating a form-associated custom element} for further information
 *
 * @typedef {any} T
 */
export abstract class FormElement<T = string> extends BaseElement {
  //* Properties and Getter/Setter
  /* Declare component as form-associated custom element */
  static get formAssociated(): boolean {
    return true;
  }

  /**
   * The ElementInternals interface helps you to
   * implement functions and properties common to
   * form control elements.
   */
  protected internals: ElementInternals;

  /**
   * A reference to a parent form element the component
   * might be part of.
   * Is only used if the browser does not understand the ElementInternals
   * interface.
   */
  protected parentForm: HTMLFormElement;

  /**
   * The elements disabled attribute.
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * The elements readonly attribute.
   *
   * @returns {boolean} the readonly state
   */
  @property({ reflect: true, type: Boolean })
  get readonly(): boolean {
    return this._readonly;
  }

  set readonly(newValue: boolean) {
    const oldValue = this._readonly;
    this._readonly = newValue;
    this.requestUpdate('readonly', oldValue);
  }

  /**
   * The name of the element for form participation.
   */
  @property({ reflect: true, type: String })
  name: string;

  /**
   * The value of the element for form participation.
   *
   * @returns {T} the current value
   */
  @property({ reflect: true })
  get value(): T {
    return this._value;
  }

  set value(value: T) {
    const oldValue = this._value;
    this._value = this.parseValue((value as unknown) as string);
    this.internals?.setFormValue(this.serializeValue(value));
    this.requestUpdate('value', oldValue).then(() => this.onValueChanged());
  }

  private _readonly = false;

  private _value: T;

  // The following properties are provided since native form
  // controls provide them as well.

  // eslint-disable-next-line jsdoc/require-returns
  /**
   * @private
   */
  get form(): HTMLFormElement {
    return this.internals?.form || this.parentForm;
  }

  // eslint-disable-next-line jsdoc/require-returns
  /**
   * @private
   */
  get type(): string {
    return this.localName;
  }

  // eslint-disable-next-line jsdoc/require-returns
  /**
   * @private
   */
  get validity(): ValidityState {
    return this.internals?.validity;
  }

  // eslint-disable-next-line jsdoc/require-returns
  /**
   * @private
   */
  get validationMessage(): string {
    return this.internals?.validationMessage;
  }

  // eslint-disable-next-line jsdoc/require-returns
  /**
   * @private
   */
  get willValidate(): boolean {
    return this.internals?.willValidate;
  }

  //* Constructor and static functions
  constructor() {
    super();

    // The Method of form participation depends on whether the
    // browser supports the form participation API (and therefore
    // the attachInternals method).
    if ('ElementInternals' in window && 'setFormData' in window.ElementInternals) {
      this.internals = this.attachInternals();
    }

    this.onFormData = this.onFormData.bind(this);
  }

  // eslint-disable-next-line jsdoc/require-returns
  /**
   * @private
   */
  checkValidity(): boolean {
    return this.internals?.checkValidity();
  }

  // eslint-disable-next-line jsdoc/require-returns
  /**
   * @private
   */
  reportValidity(): boolean {
    return this.internals?.reportValidity();
  }

  //* Callbacks and EventListener
  /**
   * When it becomes connected, its connectedCallback is called, with no
   * arguments.
   */
  connectedCallback(): void {
    super.connectedCallback();

    if (![null, undefined].includes(this.internals)) {
      this.parentForm = this.closest('form');
      this.parentForm?.addEventListener('formdata', this.onFormData);
    }
  }

  /**
   * When it becomes disconnected, its disconnectedCallback is called, with
   * no arguments.
   */
  disconnectedCallback(): void {
    this.parentForm?.removeEventListener('formdata', this.onFormData);
    delete this.parentForm;

    super.disconnectedCallback();
  }

  /**
   * When the user agent resets the form owner of a form-associated custom
   * element and doing so changes the form owner, its formAssociatedCallback
   * is called, given the new form owner (or null if no owner) as an argument.
   *
   * @param {HTMLFormElement | null} form The HTML Form the component is associated with
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  formAssociatedCallback(form: HTMLFormElement | null): void {
    // Placeholder to allow calls from subclass without throwing
  }

  /**
   * When the form owner of a form-associated custom element is reset, its
   * formResetCallback is called.
   */
  formResetCallback(): void {
    this.value = undefined;
  }

  /**
   * When the disabled state of a form-associated custom element is changed,
   * its formDisabledCallback is called, given the new state as an argument.
   *
   * @param disabled the new value for disabled
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  formDisabledCallback(disabled: boolean): void {
    // Placeholder to allow calls from subclass without throwing
  }

  /**
   * When user agent updates a form-associated custom element's value on
   * behalf of a user, its formStateRestoreCallback is called, given the new
   * value and a string indicating a reason, "restore" or "autocomplete", as
   * arguments.
   */
  formStateRestoreCallback(): void {
    // Placeholder to allow calls from subclass without throwing
  }

  // eslint-disable-next-line jsdoc/require-param
  /**
   * @private
   */
  onFormData(event: FormDataEvent): void {
    event.formData.append(this.name, this.serializeValue(this.value));
  }

  /**
   * Can be overwritten by child elements to do things when the value gets set.
   */
  protected onValueChanged(): void {
    // Placeholder can be overwritten by child elements.
  }

  /**
   * This method is used to serialize the {@link value} to a String to be used as formValue.
   * By default, this method uses the `toString` method on the value.
   * You only need to override this method if the component uses a special type for the value (the generic type T of
   * the class) that cannot be serialized to a string out of the box. If the value is a string or can be converted to a
   * string with it's `toString` method	then this method may not be overwritten.
   *
   * Background:
   * HTML custom form elements can only work with string as form-data. If a component wants to use a different type as
   * value (for example Date) then this value needs to be converted to a string before it can be set as form-data.
   *
   * @param value the value to be serialized
   * @returns {string} the serialized result
   */
  protected serializeValue(value?: T): string {
    return value?.toString();
  }

  /**
   * This method is used to parse the form data string value to arbitrary data satisfying the generic `T` type of the
   * component. You may have a look at the `serializeMethod` as its counterpart as well.
   *
   * @param value the value to be parsed
   * @returns {T} the parsed value
   */
  protected parseValue(value?: unknown): T {
    return value as T;
  }
}
