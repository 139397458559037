import {
  CSSResultArray,
  TemplateResult,
  customElement,
  html,
  unsafeCSS,
  css,
  property,
  queryAssignedNodes,
} from 'lit-element';
import { BaseElement } from '../../base/BaseElement';
import style from './searchbar-results-menu.component.scss';
import { MenuItem } from '../../menu/menu-item/menu-item.component';
import type { Size } from '../../../types';

const searchbarResultsMenuStyles = css`
  ${unsafeCSS(style)}
`;

const isMenuItem = (element: HTMLElement): element is MenuItem => element instanceof MenuItem;

/**
 * The SearchbarResultMenu shows a list of menu items and dividers.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-searchbar-results-menu>
 *   <zui-menu-item value="1">First result</zui-menu-item>
 *   <zui-menu-item value="2">
 *     <zui-icon-holy-placeholder slot="icon"></zui-icon-holy-placeholder>
 *     Second result
 *   </zui-menu-item>
 *   <zui-menu-divider>Label</zui-menu-divider>
 *   <zui-menu-item value="3">Third result</zui-menu-item>
 * </zui-searchbar-results-menu>
 * ```
 *
 * @slot - default slot for menu items and dividers
 */
@customElement('zui-searchbar-results-menu')
export class SearchbarResultsMenu extends BaseElement {
  static get styles(): CSSResultArray {
    return [searchbarResultsMenuStyles];
  }

  /**
   * Disabled state of the results menu
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * Size of the menu
   */
  @property({ reflect: true, type: String })
  size: Size = 's';

  @queryAssignedNodes('', true)
  private _assignedMenuItems: NodeListOf<HTMLElement>;

  private _handleSlotchange(): void {
    Array.from(this._assignedMenuItems)
      .filter(isMenuItem)
      .forEach((menuItem) => (menuItem.disabled = this.disabled));
  }

  protected render(): TemplateResult {
    return html`
      <zui-menu ?disabled="${this.disabled}" size="${this.size}" style="--zui-menu-margin-top: 4px">
        <slot @slotchange="${this._handleSlotchange}"></slot>
      </zui-menu>
    `;
  }
}
