import { css, customElement, property, TemplateResult, unsafeCSS } from 'lit-element';
import { html } from 'lit-html';
import { FormDataHandlingMixin } from '../../../mixins/form-participation/form-data-handling.mixin';
import { FormEnabledElement, FormValidationElement } from '../../../mixins/form-participation/form-participation.types';
import { FormValidationMixin } from '../../../mixins/form-participation/form-validation.mixin';

import style from './slider.component.scss';
import { hostStyles } from '../../../host.styles';
import { SliderBaseClass } from '../slider-base.class';
import { ifDefined } from 'lit-html/directives/if-defined.js';
import { PropertyValues } from 'lit-element/lib/updating-element';
import { EventWithTarget } from '../../../types';
import type { SliderBasic } from '../slider-basic/slider-basic.component';
import { event } from '../../../decorators/event.decorator';
import { getNextValidSliderValue, isValidStep, isValidValue } from '../slider.utils';

// import all the components we need
import '../slider-scale/slider-scale.component';
import '../slider-basic/slider-basic.component';

const sliderStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * The slider is a form element that is used to select a number value from a range of values.
 * The range is defined by a min and max value.
 * It's possible to have ticks and labels to visualize the selectable values.
 * The slider is similar to a standard HTML `<input type="range">` with additional features and styling.
 *
 * **Important note 1**: The slider only works properly if the step value is integer divisible with regard to the
 *  minimum / maximum value
 *
 * **Important note 2**: The usage of the label format can be looked up here: https://github.com/alexei/sprintf.js/
 *  some examples:
 *
 * - "%s" for strings
 * - "%.2f" for floats with a certain amount of decimal places
 * - "%f" for pure floats
 * - "+%.2f%%" for having a "+" as prefix and "%" as suffix
 *
 * **Important note 3**: When you change the `value` via code you have to make sure that the value
 * is compatible with `min`, `max` and `step` on your own.
 * The component will not prevent you from setting invalid values via code but the visual behaviour will likely break.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13009%3A2729)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=1%3A102409)
 *
 * @example
 *
 * ```html
 *  <zui-slider
 *    min="0"
 *    max="10"
 *    value="0"
 *    step="1"
 *    active-line-start="3"
 *    tick-interval="0.5"
 *    label-interval="1"
 *    label-format="$ %.2f"
 *    readonly
 *    >
 *      <zui-slider-tick-label>one</<zui-slider-tick-label>
 *      <zui-slider-tick-label>two</<zui-slider-tick-label>
 *    </zui-slider>
 * ```
 *
 * @fires change - The change event is fired when the value has changed
 * @fires input - The input event is fired when the value of the has received input
 * @fires value-changed - The value-changed event calls out the current value
 *
 * @slot - The default slot, used for the custom tick labels
 * @cssprop --zui-slider-label-odd-offset - displacement of the odd labels
 * @cssprop --zui-slider-line-height - height of the slider line
 * @cssprop --zui-slider-max-padding - spacing to the right
 * @cssprop --zui-slider-min-padding - spacing to the left
 * @cssprop --zui-slider-step-top-margin - sets offset of the tick to the line
 * @cssprop --zui-slider-step-height - sets the tick height
 * @cssprop --zui-slider-step-width - sets the tick width
 * @cssprop --zui-slider-thumb-border - width of the thumb border
 * @cssprop --zui-slider-thumb-border-readonly - width of the thumb border if readonly
 * @cssprop --zui-slider-thumb-diameter - thumb default diameter
 * @cssprop --zui-slider-thumb-diameter-readonly - thumb default diameter if readonly
 * @cssprop --zui-slider-thumb-margin-readonly - thumb margin if readonly
 * @cssprop --zui-slider-tick-odd-offset - displacement of the odd ticks
 */
@customElement('zui-slider')
export class Slider
  extends FormValidationMixin(FormDataHandlingMixin(SliderBaseClass))
  implements FormValidationElement<FormEnabledElement> {
  static readonly styles = [hostStyles, sliderStyles];

  /**
   * the tabindex of the slider
   */
  @property({ reflect: true, type: Number })
  tabindex = 0;

  /**
   * the tick interval for the slider
   */
  @property({ reflect: true, type: Number, attribute: 'tick-interval' })
  tickInterval = 0;

  /**
   * the label interval for the slider
   */
  @property({ reflect: true, type: Number, attribute: 'label-interval' })
  labelInterval = 0;

  /**
   * the format template of the label. Use https://github.com/alexei/sprintf.js/ as a reference.
   */
  @property({ reflect: true, type: String, attribute: 'label-format' })
  labelFormat;

  /**
   * the start value of the active line
   * defaults to negative infinity to ensure it defaults to the very beginning of the slider
   */
  @property({ reflect: true, type: String, attribute: 'active-line-start' })
  activeLineStart: 'min' | 'max' | number = 'min';

  /**
   * the enabled/ disabled state of the active line
   */
  @property({ reflect: true, type: Boolean, attribute: 'active-line-disabled' })
  activeLineDisabled = false;

  /**
   * @private
   */
  @event({ eventName: 'change', bubbles: true, cancelable: false, composed: true })
  emitChangeEvent(): void {
    this.dispatchEvent(new Event('change', { bubbles: true, cancelable: false, composed: true }));
  }

  /**
   * @private
   */
  @event({ eventName: 'input', bubbles: true, cancelable: false, composed: true })
  emitInputEvent(): void {
    // input event is bubbling from the internal input element
  }

  /**
   * @private
   */
  @event({ eventName: 'value-changed', bubbles: false, cancelable: false, composed: true })
  emitValueChanged(): void {
    this.dispatchEvent(
      new CustomEvent('value-changed', { bubbles: false, cancelable: false, composed: true, detail: this.value })
    );
  }

  connectedCallback(): void {
    super.connectedCallback();

    this.addValidator({ validator: this._overflowValidator, type: 'rangeOverflow' });
    this.addValidator({ validator: this._underflowValidator, type: 'rangeUnderflow' });
    this.addValidator({ validator: this._stepMismatchValidator, type: 'stepMismatch' });

    this.setDefaultValidityMessages({ rangeOverflow: 'The given value is greater than max.' });
    this.setDefaultValidityMessages({ rangeUnderflow: 'The given value is less than min.' });
    this.setDefaultValidityMessages({ stepMismatch: 'The given value does not match the step size.' });
  }

  private _syncValue({ target: { value } }: EventWithTarget<SliderBasic>): void {
    this.value = value;
  }

  private get _derivedValue(): number {
    if (this.step === 'any' || this.step === 0) {
      return this.value;
    }

    if (this.value < this.min) {
      return this.min;
    } else if (this.value > this.max) {
      return this.max;
    } else if (!isValidStep(this.min, this.max, this.step)) {
      return this.min;
    } else {
      return isValidValue(this.min, this.value, this.step)
        ? this.value
        : getNextValidSliderValue(this.min, this.max, this.step, this.value);
    }
  }

  private _overflowValidator = (): boolean => {
    return this.value <= this.max;
  };

  private _underflowValidator = (): boolean => {
    return this.value >= this.min;
  };

  private _stepMismatchValidator = (): boolean => {
    if (this.step === 'any' || this.step === 0) {
      return true;
    }

    return isValidStep(this.min, this.max, this.step) && isValidValue(this.min, this.value, this.step);
  };

  protected updated(changedProperties: PropertyValues): void {
    super.updated(changedProperties);

    if (changedProperties.has('min') || changedProperties.has('max') || changedProperties.has('step')) {
      this.checkValidity();
    }
  }

  protected render(): TemplateResult {
    return html` <div class="main-container">
      <zui-slider-basic
        zuiFormControl
        min="${this.min}"
        max="${this.max}"
        step="${this.step}"
        value="${this._derivedValue}"
        ?active-line-disabled="${this.activeLineDisabled}"
        active-line-start="${this.activeLineStart}"
        ?readonly="${this.readonly}"
        ?disabled="${this.disabled}"
        @input="${this._syncValue}"
      ></zui-slider-basic>

      <zui-slider-scale
        min="${this.min}"
        max="${this.max}"
        step="${this.step}"
        label-format="${ifDefined(this.labelFormat)}"
        label-interval="${this.labelInterval}"
        tick-interval="${this.tickInterval}"
        ?readonly="${this.readonly}"
        ?disabled="${this.disabled}"
      >
        <slot></slot>
      </zui-slider-scale>
    </div>`;
  }
}
