import { BaseElement } from '../base/BaseElement';
import { property } from 'lit-element';
import { stepConverter } from './slider.utils';

type Step = number | 'any';

export class SliderBaseClass extends BaseElement {
  /**
   * The min value of the slider.
   */
  @property({ reflect: true, type: Number })
  min = 0;

  /**
   * The max value of the slider.
   */
  @property({ reflect: true, type: Number })
  max = 1;

  /**
   * the step value for the slider. defines what values can be selected by the user.
   */
  @property({ reflect: true, converter: stepConverter })
  step: Step = 'any';

  /**
   * the actual value of the Slider
   */
  @property({ reflect: true, type: Number })
  value = 0;
}
