import {
  CSSResultArray,
  TemplateResult,
  customElement,
  html,
  css,
  unsafeCSS,
  property,
  queryAll,
  state,
} from 'lit-element';
import { nothing } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import { hostStyles } from '../../../host.styles';
import { DateTime } from 'luxon';
import { BaseElement } from '../../base/BaseElement';
import styles from './date-picker-input.component.scss';
import { jsDateToDateTime, getDefaultLocale, isoDateConverter } from '../utils/date-picker.utils';
import { event } from '../../../decorators/event.decorator';

import { DatePickerInputPart } from '../date-picker-input-part/date-picker-input-part.component';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { InteractiveIcon } from '../../interactive-icon/interactive-icon.component';

const datePickerInputStyles = css`
  ${unsafeCSS(styles)}
`;

const dateTimeFormatOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};

type DatePickerInputDateTimeFormatPart = Omit<Intl.DateTimeFormatPart, 'value'> & { value: null | number | string };
type DateTimeValue = Record<'day' | 'month' | 'year', number | null>;

/**
 * The date picker input shows an input with an interactive calendar icon.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-date-picker-input
 *   locale="en-US"
 *   placeholderDay="DD"
 *   placeholderMonth="MM"
 *   placeholderYear="YYYY"
 * >
 * </zui-date-picker-input>
 * ```
 *
 * @fires {CustomEvent} date-picker-input-calendar-selected - emits when the interactive icon is selected
 * @fires {CustomEvent} datePickerInputCalendarSelected - (Deprecated) emits when the interactive icon is selected
 * @fires {CustomEvent} date-picker-input-changed - emits when the input has changed
 * @fires {CustomEvent} date-picker-input-focused - emits when one of the input parts is focused
 *
 * @cssprop --zui-date-picker-input-width - size of the input
 * @cssprop --zui-date-picker-input-day-placeholder-width - override default day input placeholder width that is optimized for DD
 * @cssprop --zui-date-picker-input-month-placeholder-width - override default month input placeholder width that is optimized for MM
 * @cssprop --zui-date-picker-input-year-placeholder-width - override default year input placeholder width that is optimized for YYYY
 */
@customElement('zui-date-picker-input')
export class DatePickerInput extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, datePickerInputStyles];
  }

  /**
   * whether the calendar is opened or not
   */
  @property({ reflect: true, type: Boolean, attribute: 'calendar-opened' })
  calendarOpened = false;

  /**
   * disabled
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * focused
   */
  @property({ reflect: true, type: Boolean })
  focused = false;

  /**
   * whether the input is valid or not
   */
  @property({ reflect: true, type: Boolean })
  invalid = false;

  /**
   * locale
   */
  @property({ reflect: true, type: String })
  locale = getDefaultLocale();

  /**
   * placeholder day
   */
  @property({ reflect: true, type: String, attribute: 'placeholder-day' })
  placeholderDay = 'DD';

  /**
   * placeholder month
   */
  @property({ reflect: true, type: String, attribute: 'placeholder-month' })
  placeholderMonth = 'MM';

  /**
   * placeholder year
   */
  @property({ reflect: true, type: String, attribute: 'placeholder-year' })
  placeholderYear = 'YYYY';

  /**
   * readonly
   */
  @property({ reflect: true, type: Boolean })
  readonly = false;

  /**
   * selected date
   */
  @property({ reflect: true, type: String, attribute: 'selected-date', converter: isoDateConverter })
  selectedDate: Date;

  private get _selectedDateDT(): DateTime {
    return jsDateToDateTime(this.selectedDate);
  }

  /**
   * Emits a custom date-picker-input-calendar-selected event when the calendar icon is selected
   *
   * @private
   */
  @event({
    eventName: 'date-picker-input-calendar-selected',
    bubbles: true,
    composed: true,
  })
  emitDatePickerInputCalendarSelectedEvent(): void {
    // TODO: remove in version 2.0
    this.dispatchEvent(
      new CustomEvent('datePickerInputCalendarSelected', {
        bubbles: true,
        composed: true,
      })
    );

    this.dispatchEvent(
      new CustomEvent('date-picker-input-calendar-selected', {
        bubbles: true,
        composed: true,
      })
    );
  }

  /**
   * Emits a custom date-picker-input-changed event when the input value changed
   *
   * @param detail detail
   * @param detail.value value
   * @private
   */
  @event({
    eventName: 'date-picker-input-changed',
    bubbles: true,
    composed: true,
  })
  emitDatePickerInputChangedEvent(detail: { value: Date | { error: DateTimeValue } | null }): void {
    this.dispatchEvent(
      new CustomEvent('date-picker-input-changed', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  /**
   * Emits a custom date-picker-input-focused event when one of the input parts is focused
   *
   * @private
   */
  @event({
    eventName: 'date-picker-input-focused',
    bubbles: true,
    composed: true,
  })
  emitDatePickerInputFocusedEvent(): void {
    this.dispatchEvent(
      new CustomEvent('date-picker-input-focused', {
        bubbles: true,
        composed: true,
      })
    );
  }

  @state()
  private _internalDateTimeValue: DateTimeValue = {
    day: null,
    month: null,
    year: null,
  };

  @queryAll('zui-date-picker-input-part')
  private _datePickerInputParts: DatePickerInputPart[];

  private get _dateTimeFormatParts(): DatePickerInputDateTimeFormatPart[] {
    return DateTime.now().toLocaleParts({ locale: this?.locale, ...dateTimeFormatOptions });
  }

  private get _inputParts(): DatePickerInputDateTimeFormatPart[] {
    return this._selectedDateDT?.isValid
      ? this._selectedDateDT
          .toLocaleParts({ locale: this?.locale, ...dateTimeFormatOptions })
          .map((part: Intl.DateTimeFormatPart) =>
            part.type === 'literal' ? part : { ...part, value: parseInt(part.value) }
          )
      : this._dateTimeFormatParts.map((part) => {
          return {
            ...part,
            value: part.type === 'literal' ? part.value : null,
          };
        });
  }

  private get _validLiterals(): string {
    const literals = this._dateTimeFormatParts.filter(({ type }) => type === 'literal').map(({ value }) => value);

    return [...new Set(literals)].join(',');
  }

  private _handleDatePickerInputPartBlurEvent(): void {
    this.focused = false;
  }

  private _handleDatePickerInputPartFocusEvent(): void {
    this.focused = true;

    this.emitDatePickerInputFocusedEvent();
  }

  private _handleDatePickerInputPartValueChangedEvent({
    detail,
  }: CustomEvent<{ type: string; value: number | null }>): void {
    const { type, value } = detail;

    this._internalDateTimeValue = {
      ...(this._selectedDateDT?.isValid
        ? {
            day: this._selectedDateDT.day,
            month: this._selectedDateDT.month,
            year: this._selectedDateDT.year,
          }
        : this._internalDateTimeValue),
      [type]: value,
    };

    const isPartMissing = Object.values(this._internalDateTimeValue).includes(null);
    const selectedDate = !isPartMissing ? DateTime.fromObject({ ...this._internalDateTimeValue }) : null;

    this.emitDatePickerInputChangedEvent({
      value: isPartMissing
        ? null
        : selectedDate && selectedDate?.isValid
        ? selectedDate.toJSDate()
        : { error: this._internalDateTimeValue },
    });
  }

  private _handleShowCalendar(): void {
    this.emitDatePickerInputCalendarSelectedEvent();
  }

  protected render(): TemplateResult {
    return html`
      <div
        style="---zui-date-picker-input-color-literal: var(${this._selectedDateDT || this.focused
          ? '--zui-color-text-default'
          : '--zui-color-placeholder-input'})"
        class="input-parts"
      >
        ${repeat(this._inputParts, ({ type, value }) => {
          switch (type) {
            case 'day':
              return html`<zui-date-picker-input-part
                ?disabled="${this.disabled}"
                ?readonly="${this.readonly}"
                input-part-type="${type}"
                literals=${this._validLiterals}
                max="31"
                min="1"
                placeholder="${this.placeholderDay}"
                style="
                  ---zui-date-picker-input-part-input-width: var(---zui-date-picker-input-day-input-width);
                  --zui-date-picker-input-part-placeholder-width: var(--zui-date-picker-input-day-placeholder-width)
                "
                value="${value || this._internalDateTimeValue.day}"
                @blur=${this._handleDatePickerInputPartBlurEvent}
                @date-picker-input-part-value-changed=${this._handleDatePickerInputPartValueChangedEvent}
                @focus=${this._handleDatePickerInputPartFocusEvent}
              >
              </zui-date-picker-input-part>`;
            case 'month':
              return html`<zui-date-picker-input-part
                ?disabled="${this.disabled}"
                ?readonly="${this.readonly}"
                input-part-type="${type}"
                literals=${this._validLiterals}
                max="12"
                min="1"
                placeholder="${this.placeholderMonth}"
                style="
                  ---zui-date-picker-input-part-input-width: var(---zui-date-picker-input-month-input-width);
                  --zui-date-picker-input-part-placeholder-width: var(--zui-date-picker-input-month-placeholder-width)
                "
                value="${value || this._internalDateTimeValue.month}"
                @blur=${this._handleDatePickerInputPartBlurEvent}
                @date-picker-input-part-value-changed=${this._handleDatePickerInputPartValueChangedEvent}
                @focus=${this._handleDatePickerInputPartFocusEvent}
              >
              </zui-date-picker-input-part>`;
            case 'year':
              return html`<zui-date-picker-input-part
                ?disabled="${this.disabled}"
                ?readonly="${this.readonly}"
                input-part-type="${type}"
                literals=${this._validLiterals}
                max="9999"
                min="1"
                placeholder="${this.placeholderYear}"
                style="
                  ---zui-date-picker-input-part-input-width: var(---zui-date-picker-input-year-input-width);
                  --zui-date-picker-input-part-placeholder-width: var(--zui-date-picker-input-year-placeholder-width)
                "
                value="${value || this._internalDateTimeValue.year}"
                @blur=${this._handleDatePickerInputPartBlurEvent}
                @date-picker-input-part-value-changed=${this._handleDatePickerInputPartValueChangedEvent}
                @focus=${this._handleDatePickerInputPartFocusEvent}
              >
              </zui-date-picker-input-part>`;
            case 'literal':
              return html`<span class="literal">${value}</span>`;
            default:
              return nothing;
          }
        })}
      </div>
      <zui-interactive-icon
        ?disabled="${this.disabled || this.readonly}"
        emphasis="moderate"
        @click=${this._handleShowCalendar}
      >
        <zui-icon-time-time-date-calender size="m"></zui-icon-time-time-date-calender>
      </zui-interactive-icon>
    `;
  }
}
