import {
  TemplateResult,
  customElement,
  html,
  property,
  CSSResultArray,
  queryAssignedNodes,
  query,
  css,
  unsafeCSS,
  internalProperty,
} from 'lit-element';
import { styleMap } from 'lit-html/directives/style-map';
import { SearchbarInput } from '../searchbar-input/searchbar-input.component';
import { BaseElement } from '../../base/BaseElement';
import { MenuItem } from '../../menu/menu-item/menu-item.component';
import { event } from '../../../decorators/event.decorator';
import type { EventWithTarget } from '../../../types';
import { hostStyles } from '../../../host.styles';
import type { Select } from '../../select/select/select.component';
import type { SearchbarResultsMenu } from '../searchbar-results-menu/searchbar-results-menu.component';

import style from './searchbar.component.scss';
const searchbarStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * The Searchbar shows an input and a menu with menu items and dividers. When the menu is shown and a menu item is clicked
 * a custom event with the value of the clicked menu item is emitted and the menu is closed.
 *
 * ## Figma
 * - [Styleguide – Web](https://www.figma.com/file/6dkjypErYWQPfuRBD58Aey/%F0%9F%93%96--Styleguide---Web?node-id=3279%3A73599&viewport=279%2C103%2C0.15313252806663513)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-searchbar placeholder="Enter your search here..." value="result">
 *   <zui-menu-item value="1">First result</zui-menu-item>
 *   <zui-menu-item value="2">
 *     <zui-icon-holy-placeholder slot="icon"></zui-icon-holy-placeholder>
 *     Second result
 *   </zui-menu-item>
 *   <zui-menu-divider>Label</zui-menu-divider>
 *   <zui-menu-item value="3">Third result</zui-menu-item>
 * </zui-searchbar>
 * ```
 *
 * HTML (Searchbar with filter):
 *
 * ```html
 * <zui-searchbar placeholder="Enter your search here..." value="result">
 *   <zui-select
 *     hide-border
 *     ignore-outside-click
 *     slot="filter"
 *   >
 *     <zui-select-option value="all" selected>Everything</zui-select-option>
 *     <zui-select-option value="books">Books</zui-select-option>
 *     <zui-select-option value="movies">Movies</zui-select-option>
 *   </zui-select>
 *   <zui-menu-item value="1">First result</zui-menu-item>
 *   <zui-menu-item value="2">
 *     <zui-icon-holy-placeholder slot="icon"></zui-icon-holy-placeholder>
 *     Second result
 *   </zui-menu-item>
 *   <zui-menu-divider>Label</zui-menu-divider>
 *   <zui-menu-item value="3">Third result</zui-menu-item>
 * </zui-searchbar>
 * ```
 *
 * HTML (Searchbar with multi select filter):
 *
 * ```html
 * <zui-searchbar placeholder="Enter your search here..." value="result">
 *   <zui-select
 *     all-item-label="Everything"
 *     hide-border
 *     ignore-outside-click
 *     multiple
 *     show-all-item
 *     slot="filter"
 *   >
 *     <zui-select-placeholder slot="placeholder" pattern-all="%selection" pattern-many="%selection">
 *       Filter...
 *     </zui-select-placeholder>
 *     <zui-select-option value="books">Books</zui-select-option>
 *     <zui-select-option value="movies">Movies</zui-select-option>
 *   </zui-select>
 *   <zui-menu-item value="1">First result</zui-menu-item>
 *   <zui-menu-item value="2">
 *     <zui-icon-holy-placeholder slot="icon"></zui-icon-holy-placeholder>
 *     Second result
 *   </zui-menu-item>
 *   <zui-menu-divider>Label</zui-menu-divider>
 *   <zui-menu-item value="3">Third result</zui-menu-item>
 * </zui-searchbar>
 * ```
 *
 * @slot - Default slot for menu items and dividers
 * @slot filter - Slot for a select with select options
 * @fires {CustomEvent<{ detail: { value: string[] } }>} searchbar-filter-changed - The searchbar-filter-changed event is fired when the filter has changed - the detail value is an array of the selected option values
 * @fires {CustomEvent} searchbar-filter-opened - The searchbar-filter-opened event is fired when the filter has opened
 * @fires {CustomEvent<{ detail: { value: string } }>} searchbar-input - The searchbar-input event is fired when the value of the input changes
 * @fires {CustomEvent} searchbar-input-blurred - The searchbar-input-blurred event is fired when the searchbar input has lost it's focus
 * @fires {CustomEvent<{ detail: { value: string } }>} searchbar-input-focused - The searchbar-input-focused event is fired when the searchbar input is focused
 * @fires {CustomEvent<{ detail: { value: string } }>} searchbar-input-selected - The searchbar-input-selected event is fired when the searchbar input is selected, i.e. the Enter key is invoked
 * @fires {CustomEvent<{ detail: { value: string } }>} searchbar-result-selected - The searchbar-result-selected event is fired when a menu item is clicked - the detail value is the value of the selected menu item
 * @fires {CustomEvent} searchbar-results-closed - The searchbar-results-closed event is fired when the menu is closed
 * @fires {CustomEvent} searchbar-results-opened - The searchbar-results-opened event is fired when the menu is opened
 * @cssprop --zui-searchbar-results-menu-animation-duration - duration of the menu toggle animation
 * @cssprop --zui-searchbar-results-menu-expanded-height - limits the height of the menu, calculated automatically by default
 * @cssprop --zui-searchbar-results-menu-margin-left - left offset of the menu
 * @cssprop --zui-searchbar-results-menu-margin-right - right offset of the menu
 * @cssprop --zui-searchbar-results-menu-z-index - level of the menu
 */
@customElement('zui-searchbar')
export class Searchbar extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, searchbarStyles];
  }

  /**
   * Disabled state of the searchbar
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * Placeholder text for the searchbar input
   */
  @property({ reflect: true, type: String })
  placeholder = '';

  /**
   * Whether to show or hide the search results menu
   */
  @property({ reflect: true, type: Boolean })
  showSearchResults = false;

  /**
   * Value of the searchbar input
   */
  @property({ reflect: true, type: String })
  value = '';

  @query('zui-searchbar-results-menu')
  resultsMenu: SearchbarResultsMenu;

  /**
   * Emits a custom `searchbar-filter-changed` event
   *
   * @param value value of the selected filter
   * @private
   */
  @event({ eventName: 'searchbar-filter-changed', bubbles: true, composed: true })
  emitSearchbarFilterChangedEvent(value: string[]): void {
    this.dispatchEvent(
      new CustomEvent('searchbar-filter-changed', { bubbles: true, composed: true, detail: { value } })
    );
  }

  /**
   * Emits a custom `searchbar-filter-opened` event
   *
   * @private
   */
  @event({ eventName: 'searchbar-filter-opened', bubbles: true, composed: true })
  emitSearchbarFilterOpenedEvent(): void {
    this.dispatchEvent(new CustomEvent('searchbar-filter-opened', { bubbles: true, composed: true }));
  }

  /**
   * Emits a custom `searchbar-input` event
   *
   * @param value value of the input
   */
  @event({ eventName: 'searchbar-input', bubbles: true, composed: true })
  emitSearchbarInputEvent(value: string): void {
    this.dispatchEvent(new CustomEvent('searchbar-input', { bubbles: true, composed: true, detail: { value } }));
  }

  /**
   * Emits a custom `searchbar-input-blurred` event
   *
   * @private
   */
  @event({
    eventName: 'searchbar-input-blurred',
    bubbles: true,
    composed: false,
  })
  emitSearchbarInputBlurredEvent(): void {
    this.dispatchEvent(new CustomEvent('searchbar-input-blurred', { bubbles: true, composed: true }));
  }

  /**
   * Emits a custom `searchbar-input-focused` event
   *
   * @param value value of the input
   * @private
   */
  @event({
    eventName: 'searchbar-input-focused',
    bubbles: true,
    composed: true,
  })
  emitSearchbarInputFocusedEvent(value: string): void {
    this.dispatchEvent(
      new CustomEvent('searchbar-input-focused', { bubbles: true, composed: true, detail: { value } })
    );
  }

  /**
   * Emits a custom `searchbar-input-selected` event, that gets emitted, if Enter key is invoked
   * on the `<zui-searchbar-input>`
   *
   * @param value value of the input
   * @private
   */
  @event({
    eventName: 'searchbar-input-selected',
    bubbles: true,
    composed: true,
  })
  emitSearchbarInputSelectedEvent(value: string): void {
    this.dispatchEvent(
      new CustomEvent('searchbar-input-selected', { bubbles: true, composed: true, detail: { value } })
    );
  }

  /**
   * Emits a custom `searchbar-result-selected` event
   *
   * @param value value of the selected menu item
   * @private
   */
  @event({ eventName: 'searchbar-result-selected', bubbles: true, composed: true })
  emitSearchbarResultSelectedEvent(value: string): void {
    this.dispatchEvent(
      new CustomEvent('searchbar-result-selected', { bubbles: true, composed: true, detail: { value } })
    );
  }

  /**
   * Emits a custom `searchbar-results-closed` event
   *
   * @private
   */
  @event({ eventName: 'searchbar-results-closed', bubbles: true, composed: true })
  emitSearchbarResultsClosedEvent(): void {
    this.dispatchEvent(new CustomEvent('searchbar-results-closed', { bubbles: true, composed: true }));
  }

  /**
   * Emits a custom `searchbar-results-opened` event
   *
   * @private
   */
  @event({ eventName: 'searchbar-results-opened', bubbles: true, composed: true })
  emitSearchbarResultsOpenedEvent(): void {
    this.dispatchEvent(new CustomEvent('searchbar-results-opened', { bubbles: true, composed: true }));
  }

  @internalProperty()
  private _hasFilterMenu = false;

  @queryAssignedNodes('filter', true, 'zui-select')
  private _assignedFilterMenu: NodeListOf<HTMLElement>;

  /**
   * When the menu is opened the showSearchResults property is set to false and a `searchbar-results-closed` event is emitted
   */
  closeSearchbarResultsMenu(): void {
    if (!this.showSearchResults) {
      return;
    }

    this.showSearchResults = false;
    this.emitSearchbarResultsClosedEvent();
  }

  /**
   * When the menu is closed the showSearchResults property is set to true and a `searchbar-results-opened` event is emitted
   */
  openSearchbarResultsMenu(): void {
    if (this.showSearchResults) {
      return;
    }

    this.showSearchResults = true;
    this.emitSearchbarResultsOpenedEvent();
  }

  private _handleSearchbarFilterChangedEvent({ target }: EventWithTarget<Select>): void {
    this.emitSearchbarFilterChangedEvent(target.value);
  }

  private _handleSearchbarFilterOpenedEvent(): void {
    this.emitSearchbarFilterOpenedEvent();
  }

  private _handleSearchbarFilterSlotChange(): void {
    this._hasFilterMenu = this._assignedFilterMenu.length > 0;
  }

  private _handleSearchbarResultSelected({ target }: EventWithTarget<Element>): void {
    if (this.disabled) {
      return;
    }

    if (target instanceof MenuItem) {
      this.emitSearchbarResultSelectedEvent(target.value);
    }
  }

  private _handleSearchbarInputKeyDownEvent({ code }: EventWithTarget<Element, KeyboardEvent>): void {
    if (this.disabled || code !== 'Enter') {
      return;
    }

    this.emitSearchbarInputSelectedEvent(this.value);
  }

  private _handleSearchbarInputEvent({ target }: EventWithTarget<SearchbarInput | Select>): void {
    if (target instanceof SearchbarInput) {
      this.value = target.value;
      this.emitSearchbarInputEvent(this.value);
    }
  }

  private _handleSearchbarInputBlurredEvent(): void {
    this.emitSearchbarInputBlurredEvent();
  }

  private _handleSearchbarInputChangedEvent({ target }: EventWithTarget<SearchbarInput>): void {
    this.value = target.value;
  }

  private _handleSearchbarInputFocusedEvent({ target }: EventWithTarget<SearchbarInput>): void {
    this.emitSearchbarInputFocusedEvent(target.value);
  }

  protected render(): TemplateResult {
    return html`
      <zui-searchbar-input
        .value="${this.value}"
        ?disabled="${this.disabled}"
        placeholder="${this.placeholder}"
        @blur="${this._handleSearchbarInputBlurredEvent}"
        @focus="${this._handleSearchbarInputFocusedEvent}"
        @input="${this._handleSearchbarInputEvent}"
        @searchbar-input-changed="${this._handleSearchbarInputChangedEvent}"
        @keydown="${this._handleSearchbarInputKeyDownEvent}"
      >
        <slot
          name="filter"
          slot="filter"
          @change="${this._handleSearchbarFilterChangedEvent}"
          @open="${this._handleSearchbarFilterOpenedEvent}"
          @slotchange="${this._handleSearchbarFilterSlotChange}"
        ></slot>
      </zui-searchbar-input>
      <div
        class="searchbar-results-menu-wrapper"
        style=${styleMap({
          '--zui-searchbar-results-menu-margin-right': '8px',
          '--zui-searchbar-results-menu-margin-left': this._hasFilterMenu ? '144px' : '8px',
        })}
      >
        <zui-searchbar-results-menu ?disabled="${this.disabled}" class="searchbar-results-menu">
          <slot @click="${this._handleSearchbarResultSelected}"></slot>
        </zui-searchbar-results-menu>
      </div>
    `;
  }
}
