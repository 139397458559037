import {
  CSSResultArray,
  TemplateResult,
  customElement,
  html,
  state,
  property,
  css,
  unsafeCSS,
  queryAssignedNodes,
} from 'lit-element';
import { nothing } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map';
import { ifDefined } from 'lit-html/directives/if-defined';
import { hostStyles } from '../../host.styles';
import { FormDataHandlingMixin } from '../../mixins/form-participation/form-data-handling.mixin';
import { FormEnabledElement } from '../../mixins/form-participation/form-participation.types';
import { RealBaseElement } from '../base/BaseElement';
import style from './button.component.scss';

const buttonStyles = css`
  ${unsafeCSS(style)}
`;

type Size = 's' | 'l';
type EmphasisDeprecated = 'active' | 'active-primary';
type Emphasis = 'default' | 'highlight' | 'primary-highlight' | EmphasisDeprecated;
type IconPosition = 'top' | 'left';
type Content = 'text-only' | 'icon-only';
type ButtonType = 'button' | 'submit' | 'reset';

/**
 * Buttons allow users to take actions, and make choices, with a single tap.
 * They communicate actions that users can take and are typically placed throughout your UI.
 * The ZUi Button can be used as an HTML button, where you could write any phrasing content.
 * However, it comes with the default ZUi style and has a default AND minimal width of 48px (size S) / 64px (size L)
 * for the "icon-only" variant. All the other variants do not have a minimum width, but a default width of 120px.
 * The width can be set externally with the --zui-button-width custom property.
 * You can put an ZUi Icon you wish in the icon-slot of the ZUi Button.
 * The image and text will automatically be positioned accordingly to the styleguide.
 *
 * The ZUi button can act as submit or reset button as well, just set its `type` accordingly.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13009%3A2718)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=23651%3A465503)
 *
 * @example
 * HTML:
 *
 * Text only:
 *
 * ```html
 * <zui-button
 * title="My custom text"
 * environment="auto"
 * >
 * My custom text
 * </zui-button>
 * ```
 *
 * Icon only:
 *
 * ```html
 * <zui-button>
 * <zui-icon-holy-placeholder slot="icon"></zui-icon-holy-placeholder>
 * </zui-button>
 *  ```
 *
 * Text and icon (default/small size):
 *
 * ```html
 * <zui-button>
 *   <zui-icon-holy-placeholder slot="icon"></zui-icon-holy-placeholder>
 *   My Text
 * </zui-button>
 *  ```
 *
 * @cssprop --zui-button-width - for setting the width of the button externally
 * @slot default - This is the default slot. It's an innerHtml of the button-element
 * @slot icon - Here you can insert your own icon
 */
@customElement('zui-button')
export class Button extends FormDataHandlingMixin(RealBaseElement) implements FormEnabledElement {
  static get styles(): CSSResultArray {
    return [hostStyles, buttonStyles];
  }
  /**
   * The url of an optional icon. Can be a Data URI.
   */
  @property({ reflect: true })
  iconURL: string = undefined;

  /**
   * Defines one of two possible sizes (s/l);
   */
  @property({ reflect: true })
  size: Size = 's';

  /**
   * Defines one of three possible emphasis states (default/highlight/primary-highlight)
   * The deprecated emphasis "active" and "active-primary" were renamed to "highlight" and "primary-highlight"
   */
  @property({ reflect: true })
  emphasis: Emphasis = 'default';

  /**
   * Defines one of two possible icon positions (top/left);
   */
  @property({ reflect: true, attribute: 'icon-position' })
  iconPosition: IconPosition = 'left';

  /**
   * type for this button; use to make this a submit / reset button
   * that will trigger the corresponding action on the closest form
   */
  @property({ reflect: true })
  type: ButtonType = 'button';

  /**
   * optional value for this button
   */
  @property({ reflect: true })
  value;

  /**
   * Defines the content;
   */
  @state()
  private _content: Content = undefined;

  /**
   * Selects the icon slot element
   */
  @queryAssignedNodes('icon', true, '*[zuiIcon]')
  private _icons: HTMLElement[];

  /**
   * Selects the default slot element
   */
  @queryAssignedNodes('', true)
  private _slotDefault: HTMLElement[];

  /**
   * Set css classes based on content of the slots or removes them if slots change
   */
  private _handleSlotChanges(): void {
    // When icon slot is empty and default slot is not empty then property content is set to 'text-only'
    if (this._slotDefault.length > 0 && !(this._icons.length > 0)) {
      this._content = 'text-only';
      // When default slot is empty and icon slot is not empty then property content ist set to 'icon-only'
    } else if (this._slotDefault.length === 0 && this._icons.length > 0) {
      this._content = 'icon-only';
    } else {
      this._content = undefined;
    }
    // always update icons
    this._iconSizeUpdate();
  }

  /**
   * Sets size of icon depending on touch
   */
  private _iconSizeUpdate(): void {
    this._icons?.forEach((icon) => {
      // icon size changes only in touch environment
      // https://www.figma.com/file/s8uLXmabsSIhozo7OWNEwt/%E2%9D%96-02-Touch---Component-Library---1.3?node-id=10050%3A51020
      if (this.hasTouch) {
        switch (this.size) {
          case 's':
            icon.setAttribute('size', 'm');
            break;
          case 'l':
            icon.setAttribute('size', 'l');
            break;
          default:
            break;
        }
      } else {
        // always reset to size "m", on non-touch devices
        icon.setAttribute('size', 'm');
      }
    });
  }

  // maps from component state _content to a css class
  private get _contentClass(): Content | '_' {
    // return '_' as special css class, that is never used; but we have to return something...
    return this._content ?? '_';
  }

  private _handleButtonAction(): void {
    switch (this.type) {
      case 'reset':
        this.hostForm?.reset();
        break;
      case 'submit':
        this.hostForm?.requestSubmit();
        break;
      case 'button':
        break;
    }
  }

  /**
   * Detects if one of the deprecated emphasis states was used
   *
   * @param {Map<string,unknown>} changedProperties properties which have been changed
   */
  protected update(changedProperties: Map<string, unknown>): void {
    if (changedProperties.has('emphasis')) {
      const emphasis = changedProperties.get('emphasis');
      if (emphasis === 'active' || emphasis === 'active-primary') {
        console.warn(`Deprecated emphasis: ${emphasis} was used on zui-button.`);
      }
    }
    super.update(changedProperties);
  }

  protected render(): TemplateResult | void {
    return html`
      <button
        id="button"
        part="button"
        @click="${this._handleButtonAction}"
        class="${classMap({ touch: this.hasTouch, [this._contentClass]: true })}"
        name=${ifDefined(this.name)}
        value=${ifDefined(this.value)}
      >
        <div id="icon-slot-container">
          <slot name="icon" @slotchange="${this._handleSlotChanges}"
            >${this.iconURL ? html`<img alt="" src="${this.iconURL}" />` : nothing}</slot
          >
        </div>
        <div id="placeholder"></div>
        <div id="default-slot-container">
          <slot @slotchange="${this._handleSlotChanges}"></slot>
        </div>
      </button>
    `;
  }
}
