import { CSSResultArray, TemplateResult, customElement, html, property, css, unsafeCSS, query } from 'lit-element';
import { hostStyles } from '../../host.styles';
import style from './comment.component.scss';
import { FormElement } from '../base/FormElement';
import { ifDefined } from 'lit-html/directives/if-defined.js';
import { event } from '../../decorators/event.decorator';
import { Scrollable } from '../scrollable/scrollable.component';

const commentStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * The zui-comment element represents a multi-line plain-text editing control,
 * useful when you want to allow users to enter a sizeable amount of free-form
 * text, for example a comment on a review or feedback form.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13009%3A2731)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-comment name="fu_bar" placeholder="Message (optional)"></zui-comment>
 * ```
 *
 * @fires change - when the value was changed and committed (i.e. by pressing leaving the focus) by user input.
 * @fires input - when the value was changed by user input.
 * @cssprop --zui-comment-height - sets the height of the comment
 * @cssprop --zui-comment-width - sets the width of the comment
 * @cssprop --zui-comment-textarea-height - sets the height of the textarea
 */
@customElement('zui-comment')
export class Comment extends FormElement {
  static get styles(): CSSResultArray {
    return [hostStyles, commentStyles];
  }

  /**
   * placeholder that is shown if no value is set
   */
  @property({ reflect: true })
  placeholder: string;

  /**
   * @private
   */
  @event({
    eventName: 'input',
    // same configuration as the original 'input' event on <textarea>
    bubbles: true,
    cancelable: false,
    composed: true,
  })
  emitInputEvent(): void {
    // input event is bubbling from internal input element.
    // this method is only here to signal the existence of the input event
  }

  /**
   * @private
   */
  @event({
    eventName: 'change',
    // same configuration as the original 'change' event on <textarea>
    bubbles: true,
    cancelable: false,
    composed: false,
  })
  emitChangeEvent(): void {
    this.dispatchEvent(
      new Event('change', {
        bubbles: true,
        cancelable: false,
        composed: false,
      })
    );
  }

  @query('zui-scrollable')
  private _scrollable: Scrollable;

  @query('textarea')
  private _textarea: HTMLTextAreaElement;

  /**
   * Ensures that the internal textarea is set to its full auto-height, without any overflow.
   * This is required because the textarea does not support `height: auto` natively.
   *
   * Implementation inspired by: https://stackoverflow.com/a/24676492
   */
  private _resizeTextarea(): void {
    // let textarea shrink to 'auto' height, because without it 'scrollHeight' might actually be ceiled to the last height value instead of reflecting the true scroll height
    this._textarea.style.setProperty('--zui-comment-textarea-height', 'auto');

    // explicitly set the height of the internal textarea to its scroll height (it should never overflow)
    // this is required because native `height:auto` does not work for textareas
    const scrollHeight = this._textarea.scrollHeight;
    // implement min-height manually (required because css `min-height:100%` does not work in Safari)
    const textareaHeight = Math.max(this.offsetHeight, scrollHeight);
    this._textarea.style.setProperty('--zui-comment-textarea-height', `${textareaHeight}px`);
  }

  /**
   * Handle the input event from the internal textarea.
   * * Set the outer 'value' property based on the internal textarea value.
   * * The InputEvent does NOT need to be refired, as it bubbles.
   * * Ensure that the InputEvent is not prevented here.
   */
  private _handleTextareaInput(): void {
    // set the outer 'value' property based on the internal textarea value
    this.value = this._textarea.value;
  }

  /**
   * Handle the 'change' event from the internal textarea.
   * * Re-emit the change event from the internal textarea, because it is `composed: false` natively.
   */
  private _handleTextareaChange(): void {
    // re-emit the change event from the internal textarea
    this.emitChangeEvent();
  }

  protected async firstUpdated(): Promise<void> {
    // wait till the textarea is fully initialized to set it's height via max of comment offsetHeight and it's scrollHeight
    await this.updateComplete;
    await this._scrollable.updateComplete;

    // ensure textarea has correct height for initial 'value'
    this._resizeTextarea();
  }

  /**
   * Overrides the empty onValueChanged method of FormElement.
   * Called whenever the 'value' property is updated.
   */
  protected onValueChanged(): void {
    // ensure textarea has correct height after 'value' changed
    this._resizeTextarea();
  }

  protected render(): TemplateResult {
    return html`
      <div id="container">
        <zui-scrollable background="visible" hitarea="enlarged">
          <textarea
            ?disabled=${this.disabled}
            ?readonly=${this.readonly}
            name=${this.name}
            placeholder=${ifDefined(this.placeholder)}
            @input=${this._handleTextareaInput}
            @change=${this._handleTextareaChange}
            .value=${ifDefined(typeof this.value === 'string' ? this.value : undefined)}
          ></textarea>
          <span id="bottom-line"></span>
        </zui-scrollable>
      </div>
    `;
  }
}
