import {
  css,
  CSSResult,
  customElement,
  html,
  internalProperty,
  property,
  queryAssignedNodes,
  TemplateResult,
  unsafeCSS,
} from 'lit-element';
import { BaseElement } from '../../base/BaseElement';
import { hostStyles } from '../../../host.styles';
import styles from './pill.component.scss';
import { nothing } from 'lit-html';
import { event } from '../../../decorators/event.decorator';
import { IconBaseElement } from '@zeiss/zui-icons';
import { ifDefined } from 'lit-html/directives/if-defined.js';

const pillStyles = css`
  ${unsafeCSS(styles)}
`;

/**
 * The pill component displays a rounded rectangle with a simple text content or either a state dot or icon.
 * When closable an additional close icon is shown. The pill emits a close event when this icon is clicked or when
 * focused the 'Enter' key is pressed.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13009%3A2728)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=40415%3A344667)
 *
 * @example
 * HTML:
 *
 * Closable pill with text
 * ```html
 * <zui-pill closable>
 *   Content
 * </zui-pill>
 * ```
 *
 * Closable pill with a state dot
 * ```html
 * <zui-pill closable>
 *   <zui-state-dot slot="icon" state="ready"></zui-state-dot>
 * </zui-pill>
 * ```
 *
 * Closable pill with an icon
 * ```html
 * <zui-pill closable>
 *   <zui-icon-holy-placeholder slot="icon"></zui-icon-holy-placeholder>
 * </zui-pill>
 * ```
 *
 * @slot - default slot for content
 * @slot icon - slot for a state dot or an icon
 * @fires {CustomEvent} close - emitted when the pill is closable not disabled and the close icon is clicked or when focused the 'Enter' key is pressed
 *
 */
@customElement('zui-pill')
export class Pill extends BaseElement {
  static get styles(): CSSResult[] {
    return [hostStyles, pillStyles];
  }

  /**
   * Sets closable of the PillComponent
   */
  @property({ reflect: true, type: Boolean })
  closable = false;

  /**
   * Sets disabled of the PillComponent
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * @private
   */
  @event({
    eventName: 'close',
    bubbles: true,
    composed: false,
  })
  emitCloseEvent(): void {
    this.dispatchEvent(
      new CustomEvent('close', {
        bubbles: true,
        composed: false,
      })
    );
  }

  @internalProperty()
  private _defaultSlotContent: string = null;

  @queryAssignedNodes('icon', true)
  private _iconSlotNodes;

  private _handleClick(event: PointerEvent): void {
    if (this.disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();

      return;
    }

    this.emitCloseEvent();
  }

  private _handleKeyDown({ key }: KeyboardEvent): void {
    if (this.disabled) {
      return;
    }

    if (key === 'Enter') {
      this.emitCloseEvent();
    }
  }

  private _updateIconSize(): void {
    Array.from(this._iconSlotNodes)
      .filter((node): node is IconBaseElement => node instanceof IconBaseElement)
      .forEach((iconElement) => (iconElement.size = 's'));
  }

  protected firstUpdated(): void {
    this._updateIconSize();
  }

  protected render(): TemplateResult {
    return html`
      <zui-pill-content
        ?disabled="${this.disabled}"
        tabindex="${ifDefined(this.closable && !this.disabled ? 0 : undefined)}"
      >
        <slot></slot>
        <slot name="icon" @slotchange="${this._updateIconSize}" slot="icon"></slot>
        ${this.closable
          ? html`
              <zui-interactive-icon
                ?disabled="${this.disabled}"
                emphasis="subtle"
                slot="iconClose"
                tabindex="${ifDefined(this.closable && !this.disabled ? 0 : undefined)}"
                @click="${this._handleClick}"
                @keydown="${this._handleKeyDown}"
              >
                <zui-icon-close size="xs"></zui-icon-close>
              </zui-interactive-icon>
            `
          : nothing}
      </zui-pill-content>
    `;
  }
}
