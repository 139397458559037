import { css, CSSResult, customElement, html, property, TemplateResult, unsafeCSS } from 'lit-element';
import { BaseElement } from '../../base/BaseElement';

import styles from './state-label.component.scss';

type State = 'default' | 'error' | 'warning' | 'progress' | 'ready';

const stateLabelStyles = css`
  ${unsafeCSS(styles)}
`;

/**
 * The state label is a colored rectangle which has a background and text color based on its state.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=23531%3A787239)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=12425%3A0)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-state-label state="ready">
 *   <span>Ready</span>
 * </zui-state-label>
 * ```
 *
 * @slot - default slot for state label text
 *
 * @cssprop --zui-state-label-background-color - override background color
 * @cssprop --zui-state-label-text-color - override text color
 * @cssprop --zui-state-label-width - override default width of 80px
 */
@customElement('zui-state-label')
export class StateLabel extends BaseElement {
  static get styles(): CSSResult[] {
    return [stateLabelStyles];
  }

  /**
   * Sets the state of the StateLabel
   */
  @property({ reflect: true, type: String })
  state: State = 'default';

  protected render(): TemplateResult {
    return html`<div class="state-label-text"><slot></slot></div>`;
  }
}
