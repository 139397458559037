import { CSSResultArray, customElement, property, TemplateResult, svg, css, unsafeCSS } from 'lit-element';
import { BaseElement } from '../base/BaseElement';

import { hostStyles } from '../../host.styles';
import style from './progress-ring.component.scss';

const progressRingStyles = css`
  ${unsafeCSS(style)}
`;

type Size = 's' | 'm' | 'l';
type Mode = 'activity';
type EmphasisDeprecated = 'active' | 'active-primary';
type Emphasis = 'default' | 'highlight' | 'primary-highlight' | EmphasisDeprecated;

const mapSizeToInternalSizeAndStroke: Record<Size, { size: number; stroke: number }> = {
  s: { size: 16, stroke: 2 },
  m: { size: 32, stroke: 3 },
  l: { size: 72, stroke: 4 },
};

/**
 * The progress ring component is used as a progress indicator and loading spinner.
 * The colors of the animated ring depends on the {@link emphasis} setting.
 *
 *  ## Figma
 * - [Styleguide - Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/📖--Styleguide---Desktop?node-id=1%3A102376)
 *
 * @example
 * HTML:
 * ```html
 * <zui-progress-ring size="m" emphasis="highlight" mode="activity"></zui-progress-ring>
 * ```
 * @cssprop --zui-progress-ring-animation-duration - the duration of the progress ring animation
 * @cssprop --zui-progress-ring-animation-timing - the timing function of the progress ring animation
 * @cssprop --zui-progress-ring-progress-color - the color of the animated progress ring foreground, derived from the emphasis attribute
 * @cssprop --zui-progress-ring-rail-color - the color of the static progress ring background rail, derived from the emphasis attribute
 * @cssprop --zui-progress-ring-rail-opacity - the opacity of the static progress ring background rail
 * @cssprop --zui-progress-ring-size - the overall size of the progress ring, derived from the size attribute
 * @cssprop --zui-progress-ring-width - thickness of the ring, derived from the size attribute
 */
@customElement('zui-progress-ring')
export class ProgressRing extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, progressRingStyles];
  }

  /**
   * Defines the mode of the progress ring.
   */
  @property({ reflect: true })
  mode: Mode = 'activity';

  /**
   * Defines one of three possible emphasis states (default/highlight/primary-highlight)
   * The deprecated emphasis "active" and "active-primary" were renamed to "highlight" and "primary-highlight"
   */
  @property({ reflect: true })
  emphasis: Emphasis = 'default';

  /**
   * Defines the size of the component.
   */
  @property({ reflect: true })
  size: Size = 'm';

  private get _internalStroke(): number {
    return mapSizeToInternalSizeAndStroke[this.size].stroke;
  }

  private get _internalSize(): number {
    return mapSizeToInternalSizeAndStroke[this.size].size;
  }

  /**
   * Renders a single SVG circle
   *
   * @param {string} className - the css class to be used
   * @returns {(TemplateResult|void)} lit-html template
   */
  private _renderCircle(className: string): TemplateResult {
    return svg`
			<circle class="${className}"
							r="${(this._internalSize - this._internalStroke) / 2}"
							cx="${this._internalSize / 2}"
							cy="${this._internalSize / 2}"
							stroke-miterlimit="${this._internalStroke / 2}"
			/>
		`;
  }

  /**
   * Detects if one of the deprecated emphasis states was used
   *
   * @param {Map<string,unknown>} changedProperties properties which have been changed
   */
  protected update(changedProperties: Map<string, unknown>): void {
    if (changedProperties.has('emphasis')) {
      const emphasis = changedProperties.get('emphasis');
      if (emphasis === 'active' || emphasis === 'active-primary') {
        console.warn(`Deprecated emphasis: ${emphasis} was used on zui-progress-ring.`);
      }
    }
    super.update(changedProperties);
  }

  protected render(): TemplateResult {
    return svg`
			<svg preserveAspectRatio="xMidYMid meet"
					 viewBox="0 0 ${this._internalSize} ${this._internalSize}"
			>
				${this._renderCircle('rail')}
				${this._renderCircle('progress')}
			</svg>
		`;
  }
}
