import { CSSResultArray, TemplateResult, customElement, html, property } from 'lit-element';
import { nothing } from 'lit-html';
import { ifDefined } from 'lit-html/directives/if-defined.js';
import { event } from '../../../decorators/event.decorator';
import { DateTime } from 'luxon';
import { hostStyles } from '../../../host.styles';
import { BaseElement } from '../../base/BaseElement';
import {
  DatePickerWeekdayEnum,
  daysOfWeekConverter,
  jsDateToDateTime,
  getDefaultLocale,
  isoDateConverter,
} from '../utils/date-picker.utils';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DatePickerDayPicker } from '../date-picker-day-picker/date-picker-day-picker.component';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DatePickerMonthPicker } from '../date-picker-month-picker/date-picker-month-picker.component';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DatePickerYearPicker } from '../date-picker-year-picker/date-picker-year-picker.component';

enum CurrentPickerEnum {
  Day = 'day',
  Month = 'month',
  Year = 'year',
}

/**
 * The date picker component has three different types of integrated pickers (day, month, year).
 * The day picker is for selecting a date. The corresponding headers are for quick navigation and the selection of months and years.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-date-picker
 *  close-on-date-selected
 *  disabled-days-of-week="Mo,Tuesday"
 *  locale="en-US"
 *  max-date="2010-01-01T00:00:00.000+01:00"
 *  min-date="2020-12-31T23:59:59.999+01:00">
 * </zui-date-picker>
 * ```
 * @fires {CustomEvent} date-picker-date-selected - emits the selected day
 * @fires {CustomEvent} datePickerDateSelected - (Deprecated) emits the selected day
 * @fires {CustomEvent} date-picker-current-date-changed - emits the current selected picker and picker date
 */
@customElement('zui-date-picker')
export class DatePicker extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles];
  }

  /**
   * whether the picker should be closed after date selection or not
   */
  @property({ reflect: true, type: Boolean, attribute: 'close-on-date-selected' })
  closeOnDateSelected = false;

  /**
   * current date
   *
   * @private
   */
  @property({ reflect: true, type: String, attribute: 'current-date', converter: isoDateConverter })
  currentDate: Date;

  private get _currentDateDT(): DateTime {
    return DateTime.fromJSDate(this.currentDate)?.isValid ? DateTime.fromJSDate(this.currentDate) : DateTime.now();
  }

  /**
   * current picker
   *
   * @private
   */
  @property({ reflect: true, type: String, attribute: 'current-picker' })
  currentPicker: CurrentPickerEnum = CurrentPickerEnum.Day;

  /**
   * disabled dates
   */
  @property({ type: Array, attribute: false })
  disabledDates: Date[] = [];

  /**
   * disabled months
   */
  @property({ type: Array, attribute: false })
  disabledMonths: Date[] = [];

  /**
   * disabled years
   */
  @property({ type: Array, attribute: false })
  disabledYears: Date[] = [];

  /**
   * disabled days of week
   */
  @property({ reflect: true, type: String, attribute: 'disabled-days-of-week', converter: daysOfWeekConverter })
  disabledDaysOfWeek: DatePickerWeekdayEnum[] = [];

  /**
   * locale
   */
  @property({ reflect: true, type: String })
  locale = getDefaultLocale();

  /**
   * max date
   */
  @property({ reflect: true, type: String, attribute: 'max-date', converter: isoDateConverter })
  maxDate: Date;

  /**
   * min date
   */
  @property({ reflect: true, type: String, attribute: 'min-date', converter: isoDateConverter })
  minDate: Date;

  /**
   * selected date
   */
  @property({ reflect: true, type: String, attribute: 'selected-date', converter: isoDateConverter })
  selectedDate: Date;

  private get _selectedDateDT(): DateTime {
    return jsDateToDateTime(this.selectedDate);
  }

  /**
   * optional weekstart that overrides the locale
   */
  @property({ reflect: true, type: String, attribute: 'week-start' })
  weekStart: DatePickerWeekdayEnum;

  /**
   * Emits a custom date-picker-current-date-changed event when a date is selected
   *
   * @param detail object with value
   * @param detail.currentDate the current selected picker date
   * @param detail.currentPicker the current selected picker
   *
   * @private
   */
  @event({
    eventName: 'date-picker-current-date-changed',
    bubbles: true,
    composed: true,
  })
  emitDatePickerCurrentDateChangedEvent(detail: { currentDate: Date; currentPicker: CurrentPickerEnum }): void {
    this.dispatchEvent(
      new CustomEvent('date-picker-current-date-changed', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  /**
   * Emits a custom date-picker-date-selected event when a date is selected
   *
   * @param detail object with value
   * @param detail.value the selected date
   *
   * @private
   */
  @event({
    eventName: 'date-picker-date-selected',
    bubbles: true,
    composed: true,
  })
  emitDatePickerDateSelectedEvent(detail: { value: Date }): void {
    // TODO: remove in version 2.0
    this.dispatchEvent(
      new CustomEvent('datePickerDateSelected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );

    this.dispatchEvent(
      new CustomEvent('date-picker-date-selected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  private _handleDayPickerDaySelected({ detail }: CustomEvent<{ value: Date }>): void {
    this.selectedDate = detail.value;
    this.currentDate = detail.value;

    this.emitDatePickerDateSelectedEvent({ value: detail.value });
  }

  private _handleDayPickerMonthSelected({ detail }: CustomEvent<{ value: Date }>): void {
    this.currentDate = detail.value;
    this.currentPicker = CurrentPickerEnum.Month;

    this.emitDatePickerCurrentDateChangedEvent({ currentDate: detail.value, currentPicker: CurrentPickerEnum.Month });
  }

  private _handleDayPickerNextPreviousSelected({ detail }: CustomEvent<{ startOfMonth: Date }>): void {
    this.currentDate = detail.startOfMonth;
    this.currentPicker = CurrentPickerEnum.Day;

    this.emitDatePickerCurrentDateChangedEvent({
      currentDate: detail.startOfMonth,
      currentPicker: CurrentPickerEnum.Day,
    });
  }

  private _handleMonthPickerMonthSelected({ detail }: CustomEvent<{ value: Date }>): void {
    this.currentDate = detail.value;
    this.currentPicker = CurrentPickerEnum.Day;

    this.emitDatePickerCurrentDateChangedEvent({ currentDate: detail.value, currentPicker: CurrentPickerEnum.Day });
  }

  private _handleMonthPickerYearSelected({ detail }: CustomEvent<{ value: Date }>): void {
    this.currentDate = detail.value;
    this.currentPicker = CurrentPickerEnum.Year;

    this.emitDatePickerCurrentDateChangedEvent({ currentDate: detail.value, currentPicker: CurrentPickerEnum.Year });
  }

  private _handleYearPickerYearSelected({ detail }: CustomEvent<{ value: Date }>): void {
    this.currentDate = detail.value;
    this.currentPicker = CurrentPickerEnum.Month;

    this.emitDatePickerCurrentDateChangedEvent({ currentDate: detail.value, currentPicker: CurrentPickerEnum.Month });
  }

  private get _pickerTemplates(): TemplateResult | typeof nothing {
    switch (this.currentPicker) {
      case CurrentPickerEnum.Day:
        return html`
          <zui-date-picker-day-picker
            .disabledDates="${this.disabledDates}"
            .disabledMonths="${this.disabledMonths}"
            .disabledYears="${this.disabledYears}"
            current-date="${this._currentDateDT?.isValid ? this._currentDateDT.toISO() : undefined}"
            disabled-days-of-week="${this.disabledDaysOfWeek.length ? this.disabledDaysOfWeek.join(',') : ''}"
            locale="${this.locale}"
            max-date="${ifDefined(this.maxDate)}"
            min-date="${ifDefined(this.minDate)}"
            selected-date="${ifDefined(this._selectedDateDT?.isValid ? this._selectedDateDT.toISO() : undefined)}"
            week-start="${ifDefined(this.weekStart)}"
            @day-picker-day-selected="${this._handleDayPickerDaySelected}"
            @day-picker-month-selected="${this._handleDayPickerMonthSelected}"
            @day-picker-next-month-selected="${this._handleDayPickerNextPreviousSelected}"
            @day-picker-previous-month-selected="${this._handleDayPickerNextPreviousSelected}"
          >
          </zui-date-picker-day-picker>
        `;

      case CurrentPickerEnum.Month:
        return html`
          <zui-date-picker-month-picker
            .disabledMonths="${this.disabledMonths}"
            .disabledYears="${this.disabledYears}"
            current-year="${this._currentDateDT?.isValid ? this._currentDateDT.toISO() : undefined}"
            locale="${this.locale}"
            max-date="${ifDefined(this.maxDate)}"
            min-date="${ifDefined(this.minDate)}"
            selected-month="${ifDefined(this._selectedDateDT?.isValid ? this._selectedDateDT.toISO() : undefined)}"
            @month-picker-month-selected="${this._handleMonthPickerMonthSelected}"
            @month-picker-year-selected="${this._handleMonthPickerYearSelected}"
          >
          </zui-date-picker-month-picker>
        `;

      case CurrentPickerEnum.Year:
        return html`
          <zui-date-picker-year-picker
            .disabledYears="${this.disabledYears}"
            current-decade="${this._currentDateDT?.isValid
              ? this._currentDateDT
                  .minus({ years: DateTime.fromJSDate(this.currentDate).year % 10 })
                  .startOf('year')
                  .toISO()
              : undefined}"
            decade-select-disabled
            locale="${this.locale}"
            max-date="${ifDefined(this.maxDate)}"
            min-date="${ifDefined(this.minDate)}"
            selected-year="${ifDefined(this._selectedDateDT?.isValid ? this._selectedDateDT.toISO() : undefined)}"
            @year-picker-year-selected="${this._handleYearPickerYearSelected}"
          >
          </zui-date-picker-year-picker>
        `;

      default:
        return nothing;
    }
  }

  protected render(): TemplateResult {
    return html`${this._pickerTemplates}`;
  }
}
