import { FormElement } from '../base/FormElement';
import {
  css,
  CSSResultArray,
  customElement,
  html,
  internalProperty,
  property,
  query,
  TemplateResult,
  unsafeCSS,
} from 'lit-element';
import { hostStyles } from '../../host.styles';
import { classMap } from 'lit-html/directives/class-map';
import { isStringEmpty, isSlotEmpty } from '../../utils/component.utils';
import style from './inline-message.component.scss';

const inlineMessageStyles = css`
  ${unsafeCSS(style)}
`;

type Size = 's' | 'm' | 'l';

/**
 * The inline message is an information component which can hold an icon, a header and multiple lines of text.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=14341%3A182190)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=1%3A102402)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-inline-message header-text="Some Header Text">
 * Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
 * <zui-icon-holy-placeholder slot="icon"></zui-icon-holy-placeholder>
 * </zui-inline-message>
 *  ```
 *
 * @slot - This is the default slot for the text of the inline message.
 * @slot icon - Slot for the icon of the inline message.
 * @slot headerText - Here you can insert the header text, it will overwrite the headerText property.
 */
@customElement('zui-inline-message')
export class InlineMessage extends FormElement {
  static get styles(): CSSResultArray {
    return [hostStyles, inlineMessageStyles];
  }

  /**
   * Defines the header text of the inline message. It gets overwritten by the headerText slot
   */
  @property({ reflect: true, attribute: 'header-text' })
  get headerText(): string {
    return this._headerText;
  }

  set headerText(newValue: string) {
    const oldValue = this._headerText;
    this._headerText = newValue;
    // if not initialized, exit early
    if (!this._initialized) {
      return;
    }
    if (oldValue !== newValue) {
      this._updateHeaderSetState();
      // TODO: is this really necessary?
      this.requestUpdate('header-text', newValue);
    }
  }

  /**
   * Defines the size of the inline message
   */
  @property({ reflect: true })
  get size(): Size {
    return this._size;
  }

  set size(newValue: Size) {
    const oldValue = this._size;
    this._size = newValue;
    // if not initialized, exit early
    if (!this._initialized) {
      return;
    }
    // if size has actually changed, trigger iconSlot logic and headerText logic
    if (oldValue !== newValue) {
      this._setIconSize();
      this._checkSizeAndHeader();
    }
  }

  /**
   * Internal property that signals if there is a header text set or not.
   *
   * This is implemented as property instead of a dynamic getter to be able to trigger a re-rendering when the headerText slot has changed.
   * With a getter we would need to trigger a re-rendering in the headerText slot-change listener regardless if there is a new header or not.
   * With this property a re-rendering is only triggered if the flag was actually changed.
   */
  @internalProperty()
  private _isHeaderSet = false;

  @query('slot[name="headerText"]')
  private _headerTextSlot: HTMLSlotElement;

  @query('slot[name="icon"]')
  private _iconSlot: HTMLSlotElement;

  private _headerText: string;
  private _size: Size = 'm';
  private _initialized = false;

  /**
   * Prints a warning if size='s' and header is set
   */
  private _checkSizeAndHeader(): void {
    if (this.size === 's' && this._hasHeaderText()) {
      // eslint-disable-next-line no-console
      console.warn("header will not be visible when size='s'");
    }
  }

  /**
   * Determines if the headerText is set or not.
   *
   * @returns {boolean} true if the header is set either as attribute or via slot, otherwise false
   */
  private _hasHeaderText(): boolean {
    return !isStringEmpty(this.headerText) || !isSlotEmpty(this._headerTextSlot);
  }

  /**
   * When the headerText slot has changed, we re-calculate the {@link _isHeaderSet} property.
   * Only if this flag has changed a re-rendering will be triggered.
   */
  private _onHeaderTextSlotChanged(): void {
    this._updateHeaderSetState();
  }

  /**
   * updates the internal state, whether the header is set or not
   */
  private _updateHeaderSetState() {
    this._isHeaderSet = this._hasHeaderText();
    this._checkSizeAndHeader();
  }

  /**
   * When the icon is changing set size of icon in the icon slot.
   */
  private _onIconSlotChanged() {
    this._setIconSize();
  }

  /**
   * Set size of icon in the icon slot to one size larger than the size of the inline message.
   */
  private _setIconSize() {
    this._iconSlot.assignedNodes().forEach((node) => {
      if (node instanceof HTMLElement) {
        this._setSizeAttributeToIcon(node);
      }
    });
  }

  /**
   * Set size of icon to one size bigger than the size of the inline message.
   *
   * @param {HTMLElement} icon the icon on which the size attribute will be set.
   */
  private _setSizeAttributeToIcon(icon: HTMLElement) {
    // The requirements of the inline message demand that the size of the icon
    // is one size bigger than the size set to the inline message
    switch (this.size) {
      case 's':
        icon.setAttribute('size', 'm');
        break;
      case 'l':
        icon.setAttribute('size', 'xl');
        break;
      case 'm':
        icon.setAttribute('size', 'l');
        break;
      default:
        // exhaustiveness checking
        // eslint-disable-next-line no-case-declarations
        const neverHappens: never = this.size;
        break;
    }
  }

  protected firstUpdated(): void {
    // calculate the initial state. This can't be done in the constructor or connectedCallback because
    // at this point in time the attribute values aren't available.
    // -> https://lit-element.polymer-project.org/guide/lifecycle
    this._updateHeaderSetState();
    this._setIconSize();
    this._initialized = true;
  }

  protected render(): TemplateResult {
    return html`
      <div id="root" class=${classMap({ 'header-shown': this.size !== 's' && this._isHeaderSet })}>
        <div id="icon-container"><slot name="icon" @slotchange="${this._onIconSlotChanged}"></slot></div>
        <div id="message-container">
          <h1>
            <slot id="header-text-slot" name="headerText" @slotchange="${this._onHeaderTextSlotChanged}"
              >${this.headerText}</slot
            >
          </h1>
          <p><slot id="text-slot"></slot></p>
        </div>
      </div>
    `;
  }
}
